.papar-container {
    display: flex;
    width: 100% !important;
    border-radius: 16px !important;
    /* left: 120px; */
    /* top: 293px; */
    margin-top: 24px !important;
    /* margin-right: 20px !important; */
    margin-bottom: 40px !important;
}

.services-container {
    border: 1px solid #757575;
    border-radius: 8px;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
}

.employment-card {
    width: 100% !important;
    height: auto !important;
    border-radius: 16px !important;
    /* left: 120px; */
    /* top: 293px; */
    padding: 24px 0 56px 0 !important;
    margin-top: 24px !important;
    /* margin-right: 20px !important; */
    margin-bottom: 40px !important;
    margin-left: 0 !important;
}

.loader {
    position: absolute;
    background: #f2f2f282;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    z-index: 100;
    justify-content: center;
}

.input-container-team {
    display: flex;
    width: 100%;
    /* position: absolute; */
    margin-top: 15px;
    margin-left: 40px;
    margin-right: 40px;
    flex-direction: column;
}

.team-image {
    display: flex;
    margin-top: 30;
    justify-content: center;
    background: #F2F2F2;
    border-radius: 185px;
    height: 120px;
    width: 120px;
    margin: 52px auto 44px auto;
}

.input-container-left-team {
    display: flex;
    /* position: absolute; */
    margin-top: 20px !important;
    /* margin-right: 40px; */
    margin-bottom: 20px !important;
    flex-direction: column;
}

.item-time {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 5%;
}

.button-conatiner {
    display: flex;
    gap: 20px;
    margin-top: 40px;
}

.modal-button-conatiner {
    display: flex;
    gap: 20px;
    margin-bottom: 60px;
}

.cancel-button {
    border: 1px solid #FF453A !important;
    color: #FF453A !important;
    width: 492px !important;
    height: 56px !important;
    margin: 10px 20px;
    margin-left: 40px;
    border-radius: 25px !important;
    font-family: 'Poppins', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    text-align: center !important;
    text-transform: none !important;
}

.modal-save-button {
    width: 293px !important;
    height: 56px !important;
    background-color: #1e5fea !important;
    margin: 10px 20px;
    margin-left: 40px;
    color: #ffffff !important;
    border-radius: 25px !important;
    font-family: 'Poppins', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    text-align: center !important;
    text-transform: none !important;
}

.modal-cancel-button {
    border: 1px solid !important;
    width: 293px !important;
    height: 56px !important;
    margin: 10px 20px;
    margin-left: 40px;
    border-radius: 25px !important;
    font-family: 'Poppins', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    text-align: center !important;
    text-transform: none !important;
}

.cancel-button:hover {
    background-color: #FF453A !important;
    color: #FFFFFF !important;
}

.main-lable {
    font-family: "Poppins-Medium", sans-serif !important;
    display: flex;
    position: relative;
    margin-right: 40px;
    color: #2C2C2E;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
}

.hours-form-team {
    display: flex;
    margin-bottom: 20px;
    margin-top: 40px;
    /* margin-left: 60px; */
}

.hours-to-team {
    display: flex;
    margin-bottom: 20px;
    margin-top: 40px;
    margin-left: 20px;
    background-image: image();
}

.subcategories-container {
    padding: 0 14px;
    margin-bottom: 33px;
    display: flex;
    justify-content: space-between;
}

.profile-error-message {
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FF453A;
    margin-top: 12px;
    display: flex;
}

.profile-business-working-hours-card-team {
    width: 100%;
    height: 100% !important;
    /* background: #FFFFFF !important; */
    border-radius: 16px !important;
    margin-top: 15px !important;
    margin-bottom: 5px !important;
}

.team-working-hours-card {
    width: 586px;
    height: 100% !important;
    background: #FFFFFF !important;
    border-radius: 16px !important;
    margin-bottom: 5px !important;
}

.custom-select-team {
    position: relative;
    /* display: inline-block; */
    width: 100%;
    /* specify width for .custom-select */
    height: 54px;
    border: 1px solid #757575;
    border-radius: 8px;
    overflow: hidden;
    font-family: "Poppins-Medium", sans-serif !important;
}

.custom-select-team img {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-right: 5px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
}

.custom-select-team select {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: "Poppins-Medium", sans-serif !important;
    appearance: none;
    /* Hide default dropdown arrow icon */
}

@media only screen and (max-width: 600px) {
    .custom-select-team select {
        font-size: 14px;
    }

    .custom-select-team img {
        width: 18px;
        height: 18px;
    }
}

.input-style-team {
    font-family: "Poppins-Medium", sans-serif !important;
    display: flex;
    width: 100%;
    height: 54px;
    box-sizing: border-box;
    border: 1px solid #757575 !important;
    border-radius: 8px !important;
    margin-top: 10px;
    margin-right: 15px;
    outline: none;
    box-shadow: none !important;
}

.addmember-search-service-input-style {
    width: 100%;
    height: 54px !important;
    color: #757575 !important;
    border-radius: 30px !important;
    font-family: "Poppins-Medium", sans-serif !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.main-service-container-team {
    background-color: #1E5FEA;
    color: white;
    border-radius: 30px;
    align-items: center;
    padding: 15px;
    margin-bottom: 24px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.add-team-member-business-setup-content-container {
    max-width: 1440px;
    height: 100vh;
    margin: auto;
    width: 100%;
    margin-top: 4%;
}

.business-whole-main-div .contact-div .setting-div h2,
.business-whole-main-div .contact-div .setting-div .contact-email-div p,
.deactivate-account-main-div .main-deactivate-div h1,
.deactivate-account-main-div .main-deactivate-div .form-div p {
    font-family: Poppins, sans-serif !important;
}

.subscribe-modal-card .modal-booknow h3,
.modal-booknow .p-tag-debit {
    font-family: Poppins-Medium, sans-serif !important;
}

.offer-dicount-flex-container {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    flex-wrap: wrap;
    gap: 10px;
}

.offer-flex-left-sub-container,
.offer-flex-right-sub-container {
    flex: 1 1 45%;
    display: grid;
    min-width: 250px;
}

@media (max-width: 768px) {
    .offer-flex-left-sub-container,
    .offer-flex-right-sub-container {
        flex: 1 1 100%;
    }
}