.whole-main-div {
  display: flex;
  flex-wrap: wrap;
  margin-left: 85px;
  @media (max-width: 495px) {
    margin-left: 0px;
  }
  .contact-div {
    margin-top: -49px;

    @media screen and (max-width: 1240px) {
      margin-top: 15px;
    }

    .other-setting-div::-webkit-scrollbar {
      width: 6px;
      display: initial !important;
    }

    .other-setting-div::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #e5e5ea;
      border-radius: 39px !important;
    }

    .other-setting-div::-webkit-scrollbar-thumb {
      background-color: #757575;
      border-radius: 39px !important;
    }

    @media (max-width: 1027px) {
      margin-top: 20px;
    }

    .contact-titile {
      color: #2c2c2e;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      margin-left: 45px;
      font-family: "Poppins-Medium", sans-serif;
    }

    .setting-div {
      text-align: center;
      width: auto;
      height: 274px;
      border-radius: 16px;
      background: #ffffff;
      padding: 38px;
      margin-left: 45px;
      margin-top: 18px;

      @media (max-width: 943px) {
        width: 100%;
        margin-left: 0px;
      }

      .contact-email-div {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin: inherit;

        h1,
        p {
          color: #2c2c2e;
          font-size: 20px;
          font-weight: 400;
          line-height: 36px;
          font-family: "Poppins-Medium", sans-serif;

          @media (max-width: 467px) {
            width: 100%;
          }
        }

        .hiperlink {
          text-decoration: none;

          p {
            color: #1a0dab;
            font-family: "Poppins-Medium", sans-serif;
            padding-left: 3px;
          }
        }
      }

      h1 {
        color: #2c2c2e;
        font-size: 20px;
        font-weight: 400;
        line-height: 36px;
        font-family: "Poppins-Medium", sans-serif;

        @media (max-width: 467px) {
          width: 100%;
        }
      }

      h2 {
        color: #757575;
        font-size: 18px;
        font-weight: 500;
        font-family: "Poppins-Medium", sans-serif;
      }

      img {
        padding: 20px;

        @media (max-width: 467px) {
          width: 100%;
        }
      }
    }

    .other-setting-div {
      text-align: center;
      width: 698px;
      border-radius: 16px;
      background: #ffffff;
      padding: 38px;
      margin-left: 45px;
      margin-top: 18px;
      overflow: auto;

      @media (max-width: 1173px) {
        width: 500px;
      }

      @media (max-width: 1027px) {
        width: 500px;
      }

      @media (max-width: 600px) {
        width: 100%;
        margin-left: 0px;
        padding: 0px;
        padding-right: 5px;
        padding-left: 5px;
      }

      h1 {
        color: #2c2c2e;
        font-size: 20px;
        font-weight: 400;
        line-height: 36px;
        font-family: "Poppins-Medium", sans-serif;

        @media (max-width: 467px) {
          width: 100%;
        }
      }
    }
  }

  .main-language-div {
    width: 555px;
    border-radius: 16px;
    height: auto;
    padding: 50px;
    background: #ffffff;
    margin-left: 45px;
    margin-top: 18px;

    @media screen and (max-width: 500px) {
      margin-left: 0;
    }

    div {
      border-radius: 9px;
      // background: #1b5add;
      margin-bottom: 15px;
      background-blend-mode: normal;
      padding: 20px;
      cursor: pointer;

      h1 {
        font-size: 20px;
        font-weight: 400;
      }

      p {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .btn-pair-of-cancel-save {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;

      .Cancel-btn {
        width: 48% !important;
        height: 58px;
        border-radius: 32px !important;
        border: 1px solid !important;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        margin: inherit;
        margin-bottom: 0px;
      }

      .submit-btn {
        width: 48% !important;
        height: 58px;
        border-radius: 32px !important;
        background: #1e5fea;
        color: #ffff !important;
        background-blend-mode: normal;
        text-transform: capitalize;
        margin: inherit;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
      }
    }
  }

  .main-deactivate-div {
    margin: 10px;
    width: 670px;
    border-radius: 16px;
    background: #ffffff;
    margin-left: 45px;
    margin-top: 18px;
    padding: 50px;

    @media (max-width: 800px) {
      width: 100%;
      margin: 0px;
      margin-left: 0px;
      padding: 38px;
      margin-top: 18px;
    }

    h1 {
      color: #2c2c2e;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      font-family: "Poppins-Medium", sans-serif;
    }

    .form-div {
      .MuiFormHelperText-root{
        text-align: left !important;
      }
      p {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        margin: 0px !important;
        font-family: "Poppins-Medium", sans-serif;
      }

      .MuiOutlinedInput-multiline {
        width: 540px;
        border-radius: 8px;

        @media (max-width: 500px) {
          width: 300px;
        }
      }

      .MuiInputBase-root {
        width: 540px;
        border-radius: 8px;

        @media (max-width: 500px) {
          width: 300px;
        }
      }

      .cancel_deactivate-btn {
        margin-top: 20px;

        .cancel-btn {
          width: 46% !important;
          height: 50px;
          border-radius: 25px;
          text-transform: capitalize;
          color: #ff453a !important;
          border: 1px solid #ff453a !important;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;

          @media (max-width: 500px) {
            margin-bottom: 15px;
          }
        }

        .deactivate-btn {
          width: 46%;
          text-transform: capitalize;
          height: 50px;
          border-radius: 25px;
          background: #ff453a;
          color: #ffff !important;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
        }
      }
    }
  }
}

.arabic-whole-main-div {
  display: flex;
  flex-wrap: wrap;
  margin-right: 85px;
  @media (max-width: 495px) {
    margin-right: 0px;
  }
  .contact-div {
    margin-top: -49px;

    @media screen and (max-width: 1240px) {
      margin-top: 15px;
    }

    .other-setting-div::-webkit-scrollbar {
      width: 6px;
      display: initial !important;
    }

    .other-setting-div::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #e5e5ea;
      border-radius: 39px !important;
    }

    .other-setting-div::-webkit-scrollbar-thumb {
      background-color: #757575;
      border-radius: 39px !important;
    }

    @media (max-width: 1027px) {
      margin-top: 20px;
    }

    .contact-titile {
      color: #2c2c2e;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      margin-right: 45px;
      font-family: "Poppins-Medium", sans-serif;
    }

    .setting-div {
      text-align: center;
      width: auto;
      height: 274px;
      border-radius: 16px;
      background: #ffffff;
      padding: 38px;
      margin-right: 45px;
      margin-top: 18px;

      @media (max-width: 943px) {
        width: 100%;
        margin-right: 0px;
      }

      .contact-email-div {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin: inherit;

        h1,
        p {
          color: #2c2c2e;
          font-size: 20px;
          font-weight: 400;
          line-height: 36px;
          font-family: "Poppins-Medium", sans-serif;

          @media (max-width: 467px) {
            width: 100%;
          }
        }

        .hiperlink {
          text-decoration: none;

          p {
            color: #1a0dab;
            font-family: "Poppins-Medium", sans-serif;
            padding-left: 3px;
          }
        }
      }

      h1 {
        color: #2c2c2e;
        font-size: 20px;
        font-weight: 400;
        line-height: 36px;
        font-family: "Poppins-Medium", sans-serif;

        @media (max-width: 467px) {
          width: 100%;
        }
      }

      h2 {
        color: #757575;
        font-size: 18px;
        font-weight: 500;
        font-family: "Poppins-Medium", sans-serif;
      }

      img {
        padding: 20px;

        @media (max-width: 467px) {
          width: 100%;
        }
      }
    }

    .other-setting-div {
      text-align: center;
      width: 698px;
      border-radius: 16px;
      background: #ffffff;
      padding: 38px;
      margin-right: 45px;
      margin-top: 18px;
      overflow: auto;

      @media (max-width: 1173px) {
        width: 500px;
      }

      @media (max-width: 1027px) {
        width: 500px;
      }

      @media (max-width: 600px) {
        width: 100%;
        margin-right: 0px;
        padding: 0px;
        padding-right: 5px;
        padding-right: 5px;
      }

      h1 {
        color: #2c2c2e;
        font-size: 20px;
        font-weight: 400;
        line-height: 36px;
        font-family: "Poppins-Medium", sans-serif;

        @media (max-width: 467px) {
          width: 100%;
        }
      }
    }
  }

  .main-language-div {
    width: 555px;
    border-radius: 16px;
    height: auto;
    padding: 50px;
    background: #ffffff;
    margin-right: 45px;
    margin-top: 18px;

    @media screen and (max-width: 500px) {
      margin-right: 0;
    }

    div {
      border-radius: 9px;
      // background: #1b5add;
      margin-bottom: 15px;
      background-blend-mode: normal;
      padding: 20px;
      cursor: pointer;

      h1 {
        font-size: 20px;
        font-weight: 400;
      }

      p {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .btn-pair-of-cancel-save {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;

      .Cancel-btn {
        width: 48% !important;
        height: 58px;
        border-radius: 32px !important;
        border: 1px solid !important;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        margin: inherit;
        margin-bottom: 0px;
      }

      .submit-btn {
        width: 48% !important;
        height: 58px;
        border-radius: 32px !important;
        background: #1e5fea;
        color: #ffff !important;
        background-blend-mode: normal;
        text-transform: capitalize;
        margin: inherit;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
      }
    }
  }

  .main-deactivate-div {
    margin: 10px;
    width: 670px;
    border-radius: 16px;
    background: #ffffff;
    margin-right: 45px;
    margin-top: 18px;
    padding: 50px;

    @media (max-width: 800px) {
      width: 100%;
      margin: 0px;
      margin-right: 0px;
      padding: 38px;
      margin-top: 18px;
    }

    h1 {
      color: #2c2c2e;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      font-family: "Poppins-Medium", sans-serif;
    }

    .form-div {
      .MuiFormHelperText-root{
       text-align: right !important;
      }

      p {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        margin: 0px !important;
        font-family: "Poppins-Medium", sans-serif;
      }

      .MuiOutlinedInput-multiline {
        width: 540px;
        border-radius: 8px;

        @media (max-width: 500px) {
          width: 300px;
        }
      }

      .MuiInputBase-root {
        width: 540px;
        border-radius: 8px;

        @media (max-width: 500px) {
          width: 300px;
        }
      }

      .cancel_deactivate-btn {
        margin-top: 20px;

        .cancel-btn {
          width: 46% !important;
          height: 50px;
          border-radius: 25px;
          text-transform: capitalize;
          color: #ff453a !important;
          border: 1px solid #ff453a !important;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;

          @media (max-width: 500px) {
            margin-bottom: 15px;
          }
        }

        .deactivate-btn {
          width: 46%;
          text-transform: capitalize;
          height: 50px;
          border-radius: 25px;
          background: #ff453a;
          color: #ffff !important;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
        }
      }
    }
  }
}

.business-whole-main-div {
  display: flex;
  flex-wrap: wrap;

  .contact-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10%;

    .other-setting-div::-webkit-scrollbar {
      width: 6px;
      display: initial !important;
    }

    .other-setting-div::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #e5e5ea;
      border-radius: 39px !important;
    }

    .other-setting-div::-webkit-scrollbar-thumb {
      background-color: #757575;
      border-radius: 39px !important;
    }

    @media (max-width: 1027px) {
      margin-top: 15%;
    }

    .contact-titile {
      color: #2c2c2e;
      font-family: Poppins;
      font-size: 24px;
      line-height: 30px;
      font-family: "Poppins-Medium", sans-serif;
    }

    .setting-div {
      text-align: center;
      width: auto;
      height: 274px;
      border-radius: 16px;
      background: #ffffff;
      padding: 38px;
      margin-top: 18px;

      @media (max-width: 943px) {
        width: 100%;
      }

      .contact-email-div {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin: inherit;

        h1,
        p {
          color: #2c2c2e;
          font-size: 20px;
          font-weight: 400;
          line-height: 36px;
          font-family: "Poppins-Medium", sans-serif;

          @media (max-width: 467px) {
            width: 100%;
          }
        }

        .hiperlink {
          text-decoration: none;

          p {
            color: #1a0dab;
            font-family: "Poppins-Medium", sans-serif;
            padding-left: 3px;
          }
        }
      }

      h1 {
        color: #2c2c2e;
        font-size: 20px;
        font-weight: 400;
        line-height: 36px;
        font-family: "Poppins-Medium", sans-serif;

        @media (max-width: 467px) {
          width: 100%;
        }
      }

      h2 {
        color: #757575;
        font-size: 18px;
        font-weight: 500;
        font-family: "Poppins-Medium", sans-serif;
      }

      img {
        padding: 20px;

        @media (max-width: 467px) {
          width: 100%;
        }
      }
    }

    .other-setting-div {
      text-align: center;
      width: 698px;
      border-radius: 16px;
      background: #ffffff;
      padding: 38px;
      margin-top: 18px;
      overflow: auto;

      @media (max-width: 1173px) {
        width: 500px;
      }

      @media (max-width: 1027px) {
        width: 500px;
      }

      @media (max-width: 600px) {
        width: 100%;
        padding: 0px;
        padding-right: 5px;
        padding-left: 5px;
      }

      h1 {
        color: #2c2c2e;
        font-size: 20px;
        font-weight: 400;
        line-height: 36px;
        font-family: "Poppins-Medium", sans-serif;

        @media (max-width: 467px) {
          width: 100%;
        }
      }
    }
  }

  .main-language-div {
    width: 555px;
    border-radius: 16px;
    height: auto;
    padding: 50px;
    background: #ffffff;
    margin-top: 18px;

    div {
      border-radius: 9px;
      // background: #1b5add;
      margin-bottom: 15px;
      background-blend-mode: normal;
      padding: 20px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-size: 20px;
        font-weight: 400;
      }

      p {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .btn-pair-of-cancel-save {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;

      .Cancel-btn {
        width: 48% !important;
        height: 58px;
        border-radius: 32px !important;
        border: 1px solid !important;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        margin: inherit;
        margin-bottom: 0px;
      }

      .submit-btn {
        width: 48% !important;
        height: 58px;
        border-radius: 32px !important;
        background: #1e5fea;
        color: #ffff !important;
        background-blend-mode: normal;
        text-transform: capitalize;
        margin: inherit;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
      }
    }
  }

  .main-deactivate-div {
    margin: 10px;
    width: 670px;
    border-radius: 16px;
    background: #ffffff;
    margin-left: 45px;
    margin-top: 18px;
    padding: 50px;

    @media (max-width: 800px) {
      width: 100%;
      margin: 0px;
      margin-left: 0px;
      padding: 38px;
      margin-top: 18px;
    }

    h1 {
      color: #2c2c2e;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      font-family: "Poppins-Medium", sans-serif;
    }

    .form-div {
      p {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        margin: 0px !important;
        font-family: "Poppins-Medium", sans-serif;
      }

      .MuiOutlinedInput-multiline {
        width: 540px;
        border-radius: 8px;

        @media (max-width: 500px) {
          width: 300px;
        }
      }

      .MuiInputBase-root {
        width: 540px;
        border-radius: 8px;

        @media (max-width: 500px) {
          width: 300px;
        }
      }

      .cancel_deactivate-btn {
        margin-top: 20px;

        .cancel-btn {
          width: 46% !important;
          height: 50px;
          border-radius: 25px;
          text-transform: capitalize;
          color: #ff453a !important;
          border: 1px solid #ff453a !important;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;

          @media (max-width: 500px) {
            margin-bottom: 15px;
          }
        }

        .deactivate-btn {
          width: 46%;
          text-transform: capitalize;
          height: 50px;
          border-radius: 25px;
          background: #1e5fea;
          color: #ffff !important;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
        }
      }
    }
  }
}
.profile-header {
  width: 390px;
  display: flex;
  align-items: center;
  padding-left: 120px;
  padding-bottom: 15px;

  @media (max-width: 495px) {
    width: 100%;
    padding-left: 90px;
  }

  img {
    cursor: pointer;
  }

  img:hover {
    background-color: #9596c4;
    border-radius: 20px;
  }

  h1 {
    padding-left: 15px;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 45px;
    margin-right: 15px;
    height: 42px;
  }
}

.main-profile-div {
  width: 390px;
  border-radius: 16px;
  background: #ffffff;
  background-blend-mode: normal;
  margin-left: 40px;
  padding: 20px;

  @media (max-width: 500px) {
    margin: auto !important;
  }

  .profile-first-div {
    text-align: center;
    margin-bottom: 15px;
    cursor: pointer;
    padding-bottom: 10px;
    padding-top: 10px;

    img {
      width: 160px;
      height: 160px;
      border-radius: 90px;
    }

    div {
      padding-left: 82px;
      margin-top: 25px;
      text-align: initial;

      h1 {
        color: #2c2c2e;
        font-family: "Poppins-Medium", sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        overflow-wrap: break-word;
      }

      p {
        color: #2c2c2e;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        padding-top: 10px;
      }
    }
  }

  .profile-first-div:hover {
    background-color: rgba(27, 90, 221, 0.2);
    border-radius: 12px;
  }

  hr {
    height: 1px;
    border: 1px solid #63636673;
  }

  .my_profile_div {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;

    h1 {
      color: #2c2c2e;
      font-size: 18px;
      font-weight: 500;
      line-height: 16px;
      font-family: "Poppins-Medium", sans-serif;

      @media (max-width: 400px) {
        font-size: 17px;
      }
    }

    .img_1-icon {
      border-radius: 25px;
    }

    .img_2-icon {
      margin-left: auto;
    }
  }

  .my_profile_div:hover {
    background-color: rgba(27, 90, 221, 0.2);
    border-radius: 12px;
  }
}

.arabic-main-profile-div {
  width: 390px;
  border-radius: 16px;
  background: #ffffff;
  background-blend-mode: normal;
  margin-right: 40px;
  padding: 20px;

  @media (max-width: 500px) {
    margin-right: 0px;
  }

  .profile-first-div {
    text-align: center;
    margin-bottom: 15px;
    cursor: pointer;
    padding-bottom: 10px;
    padding-top: 10px;

    img {
      width: 160px;
      height: 160px;
      border-radius: 90px;
    }

    div {
      padding-left: 82px;
      margin-top: 25px;
      text-align: initial;

      h1 {
        color: #2c2c2e;
        font-family: "Poppins-Medium", sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        overflow-wrap: break-word;
      }

      p {
        color: #2c2c2e;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        padding-top: 10px;
      }
    }
  }

  .profile-first-div:hover {
    background-color: rgba(27, 90, 221, 0.2);
    border-radius: 12px;
  }

  hr {
    height: 1px;
    border: 1px solid #63636673;
  }

  .my_profile_div {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;

    h1 {
      color: #2c2c2e;
      font-size: 18px;
      font-weight: 500;
      line-height: 16px;
      font-family: "Poppins-Medium", sans-serif;

      @media (max-width: 400px) {
        font-size: 17px;
      }
    }

    .img_1-icon {
      border-radius: 25px;
    }

    .img_2-icon {
      margin-left: auto;
    }
  }

  .my_profile_div:hover {
    background-color: rgba(27, 90, 221, 0.2);
    border-radius: 12px;
  }
}

// }
