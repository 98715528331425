.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #2c2c2e !important;
}

.registration-main-container {
  max-width: 100%;
  height: 100vh;
  background: url("../right_side_image.png") no-repeat center center fixed;
  background-size: cover;
  overflow: hidden;
}

.registration-main-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.registration-form-main-container {
  max-width: 100%;
  height: 100%;
}

.registration-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 550px;
  height: auto;
  max-width: 100%;
  padding: 5% 0%;
  overflow: auto;
}

.business-registration-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 550px;
  height: auto;
  max-width: 100%;
  padding: 5% 0% 5% 0%;
  overflow: scroll;
}

.form-images-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 4%;
}

.left-arrow-image-container {
  width: 85px !important;
  cursor: pointer;
}

.logo-image-style {
  width: 237px;
  height: 48px;
  cursor: pointer;
}

.account-creation-text-style {
  width: 321px;
  height: 48px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2c2c2e;
  margin-top: 24px;
}

.registration-form-registration-input-container .registration-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 4%;
}

.registration-form-registration-input-container .registration-input-container:nth-child(1) {
  margin-top: 10%;
}

.registration-form-registration-input-container .registration-input-container:last-child {
  display: flex;
  flex-direction: column;
}

.registration-form-label {
  height: 16px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #757575;
  margin-bottom: 12px;
}

.registration-form-input-style {
  width: 418px !important;
  height: 54px !important;
  border-radius: 8px !important;
  max-width: 100% !important;
}

.t-and-c-policy-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2c2c2e;
  width: 418px;
  max-width: 100%;
}

.join-now-button-style {
  width: 418px !important;
  max-width: 100% !important;
  height: 50px !important;
  background-color: #1e5fea !important;
  margin: 32px 0px 48px 0px !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
}

.already-member-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #2c2c2e;
}

.login-now-text {
  font-weight: 500 !important;
  color: #636366 !important;
  cursor: pointer;
  text-decoration: underline;
}

.error-message-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #ff453a;
  margin-top: 12px;
}

.note-message-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #636366;
  margin-top: 12px;
}

.registration-verification-error-message-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #ff453a;
  max-width: 100%;
  width: 310px;
  margin: auto;
}

.t-and-c-error-container {
  width: 418px;
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f9f9f8;
  border-radius: 8px;
}

.password-note-container {
  width: 418px;
  height: max-content;
  display: flex;
  flex-direction: column;
  background: #f9f9f8;
  border-radius: 8px;
  max-width: 100%;
  margin: 1% 0%;
}

.password-note-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2c2c2e;
  margin: 1px 4px;
}

.error-image {
  margin-left: 12px;
  margin-right: 7px;
}

.t-and-c-error-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2c2c2e;
}

.Mui-error {
  color: #ff453a !important;
}

.Mui-checked {
  color: #1e5fea !important;
}

.MuiDialog-paperScrollPaper {
  border-radius: 24px !important;
}

.registration-form-background-image {
  width: 100%;
  height: 100%;
}

@media (min-width: 700px) and (max-width: 1000px) {
  .registration-form-main-container {
    width: 60.25%;
  }
}

@media (min-width: 100px) and (max-width: 700px) {
  .registration-form-main-container {
    width: 100%;
  }
}

@media (min-width: 100px) and (max-width: 565px) {
  .registration-form-fields-main-container {
    width: 100% !important;
  }
}

@media (min-height: 801px) and (max-height: 900px) {
  .account-creation-text-style {
    margin-top: 14px;
    height: 42px;
    font-size: 15;
  }

  .registration-form-registration-input-container .registration-input-container {
    margin-bottom: 18px;
  }

  .registration-form-registration-input-container .registration-input-container:nth-child(1) {
    margin-top: 28px;
  }

  .registration-form-label {
    margin-bottom: 10px;
  }

  .join-now-button-style {
    margin: 24px 0px 12px 0px !important;
    height: 50px !important;
  }

  .error-message-text {
    margin-top: 8px !important;
  }

  .registration-form-input-style {
    height: 45px !important;
  }
}

@media (min-height: 700px) and (max-height: 800px) {
  .account-creation-text-style {
    margin-top: 16px;
    height: 40px;
    font-size: 15;
  }

  .form-images-container {
    margin-top: 5%;
  }

  .registration-form-registration-input-container .registration-input-container {
    margin-bottom: 20px;
  }

  .registration-form-registration-input-container .registration-input-container:nth-child(1) {
    margin-top: 30px;
  }

  .registration-form-label {
    margin-bottom: 10px;
  }

  .join-now-button-style {
    margin: 24px 0px 12px 0px !important;
    height: 48px !important;
  }

  .error-message-text {
    margin-top: 5px !important;
  }

  .registration-form-input-style,
  .account-creation-text-style {
    height: 42px !important;
  }
}

@media (min-height: 550px) and (max-height: 600px) {
  .form-images-container {
    margin-top: 0%;
  }

  .account-creation-text-style {
    font-size: 14;
    height: 34px;
    margin-top: 10px;
  }

  .registration-form-registration-input-container .registration-input-container:nth-child(1) {
    margin-top: 20px;
  }

  .registration-form-registration-input-container .registration-input-container {
    margin-bottom: 10px;
  }

  .join-now-button-style {
    height: 45px !important;
    margin: 15px 0px 10px 0px !important;
  }

  .registration-form-label {
    margin-bottom: 4px;
  }

  .registration-form-input-style {
    height: 40px !important;
  }

  .error-message-text {
    margin-top: 2px !important;
  }
}

@media (min-height: 600px) and (max-height: 700px) {
  .account-creation-text-style {
    margin-top: 10px;
    height: 34px;
    font-size: 14;
  }

  .registration-form-registration-input-container .registration-input-container {
    margin-bottom: 10px;
  }

  .registration-form-registration-input-container .registration-input-container:nth-child(1) {
    margin-top: 20px;
  }

  .registration-form-label {
    margin-bottom: 4px;
  }

  .join-now-button-style {
    margin: 15px 0px 10px 0px !important;
    height: 45px !important;
  }

  .error-message-text {
    margin-top: 2px !important;
  }

  .registration-form-input-style {
    height: 40px !important;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  .registration-form-fields-main-container {
    width: 100% !important;
  }

  .MuiDialog-paperScrollPaper {
    max-height: 100% !important;
    height: 100% !important;
  }

  .registration-form-container,
  .business-registration-form-container {
    width: 430px;
  }

  .registration-form-registration-input-container,
  .registration-form-input-style,
  .join-now-button-style,
  .t-and-c-policy-text-container {
    width: 370px !important;
    padding: 0% !important;
  }
}

@media screen and (min-height: 901px) {
  .account-creation-text-style {
    margin-top: 12px;
  }

  .registration-form-registration-input-container .registration-input-container {
    margin-bottom: 16px;
  }

  .registration-form-registration-input-container .registration-input-container:nth-child(1) {
    margin-top: 24px;
  }

  .registration-form-label {
    margin-bottom: 7px;
  }

  .join-now-button-style {
    margin: 20px 0px 10px 0px !important;
  }

  .error-message-text {
    margin-top: 7px !important;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .registration-form-fields-main-container {
    width: 100% !important;
  }

  .MuiDialog-paperScrollPaper {
    max-height: 100% !important;
    height: 100% !important;
  }

  .registration-form-container,
  .business-registration-form-container {
    width: 375px;
  }

  .registration-form-registration-input-container,
  .registration-form-input-style,
  .join-now-button-style,
  .t-and-c-policy-text-container {
    width: 295px !important;
    padding: 0% !important;
  }

  .logo-image-style {
    width: 150px !important;
  }
}

.registration-form-registration-input-container {
  width: 100%;
  padding: 0% 12%;
}

@media screen and (max-width: 320px) {
  .registration-form-fields-main-container {
    width: 100% !important;
  }

  .registration-form-container,
  .business-registration-form-container {
    width: 280px;
  }

  .registration-form-input-style {
    width: 100% !important;
  }

  .t-and-c-policy-text-container {
    width: 100%;
  }

  .join-now-button-style {
    width: 100% !important;
  }
}

.registration-form-fields-main-container {
  width: 550px;
  height: auto;
  background: #fff;
  border-radius: 24px;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  max-height: 95vh;
  overflow: auto;
}