.service-landing-page-main-container {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
  overflow-y: auto;
}

@media only screen and (min-width: 768px) {
  .service-landing-page-main-container {
    height: 2422px !important;
  }
}

.services-root-page {
  position: absolute;
  /* top: 30%; */
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 767px) {
  .services-root-page {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    min-height: 100vh;
  }
}

.services-note-item-text {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #48484a;
  cursor: pointer;
  padding: 30px 10px;
}

@media only screen and (min-width: 768px) {
  .services-note-item-text {
    padding: 60px 10px;
    margin-top: 10px !important;
  }
}

.service-name {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2c2c2e;
  margin-left: 30px;
  text-align: initial;
}
.service-note {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #636366;
  margin-left: 30px;
  text-align: initial;
}
.services-header-item-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #48484a;
  cursor: pointer;
  margin-top: 20px !important;
  margin-left: 0 !important;
}

@media (max-width: 768px) {
  /* Styles for devices with a maximum width of 768px (including most tablets and smaller screens) */
  .services-header-item-text {
    font-size: 20px; /* Decrease the font size for smaller screens */
    line-height: 30px; /* Adjust the line height for better readability on smaller screens */
    margin-top: 10px; /* Reduce the top margin for smaller screens */
    text-indent: 0; /* Counteract the margin on the first line */
    padding-left: 10px; /* Add left padding to compensate for the margin */
  }
}

.service-header-label {
  margin-left: 30px;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  color: #ffffff;
}
/* .services-main-container-text{
    display: flex;
    position: absolute;
    flex-direction: column;
} 
@media only screen and (max-width: 768px) {
    .services-main-container-text {
        width: 100% !important;
        margin-right: 10px !important;
    }
} */
.services-main-container-text {
  display: flex;
  position: absolute;
  flex-direction: column;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .services-main-container-text {
    position: relative; /* Change the positioning to relative */
    margin-top: 20px; /* Adjust the margin as needed */
  }
}
.service-text-label {
  text-align: "center";
  justify-content: center !important;
  align-items: center !important;
}

@media (max-width: 767px) {
  .service-text-label {
    margin-left: 20px !important;
  }
}

.services-main-container-button {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 40px !important;
}

@media only screen and (max-width: 768px) {
  .services-main-container-button {
    flex-direction: column;
    margin-bottom: 20px !important;
    margin-left: 20px !important;
  }
}

.services-add-category-button {
  width: 328px !important;
  height: 50px !important;
  border-radius: 25px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center !important;
  text-transform: none !important;
  background-color: #ffffff !important;
}

@media only screen and (max-width: 767px) {
  .services-add-category-button {
    width: 100% !important;
    margin-top: 20px;
  }
}

.services-row-button {
  width: 468px !important;
  height: 56px !important;
  border-radius: 25px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center !important;
  text-transform: none !important;
  margin-left: 20px !important;
}

@media only screen and (max-width: 768px) {
  .services-row-button {
    width: 100% !important;
    height: 48px !important;
    min-width: 300px !important;
    border-radius: 25px;
    margin-bottom: 20px !important;
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
}

.services-next-Step-button {
  width: 328px !important;
  height: 50px !important;
  margin-left: 30px;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center !important;
  text-transform: none !important;
}
@media only screen and (max-width: 767px) {
  .services-next-Step-button {
    width: 96% !important;
    margin-top: 20px !important;
    margin-left: 0 !important;
    margin-right: 20px !important;
  }
}

.services-dialog-button {
  width: 450px;
  height: 56px !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center !important;
  text-transform: none !important;
}

@media only screen and (max-width: 768px) {
  .services-dialog-button {
    width: 60% !important;
    min-width: 300px !important;
    height: 48px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
}

.services-delete-button {
  width: 450px;
  height: 56px;
  border-radius: 25px !important;
  color: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center !important;
  text-transform: none !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

@media only screen and (max-width: 768px) {
  .services-delete-button {
    width: 60% !important;
    min-width: 300px !important;
    height: 48px;
    /* margin-left: 80px !important; */
  }
}

.services-cancel-dialog-button {
  width: 327px !important;
  height: 56px !important;
  margin-left: 30px;
  border-radius: 25px !important;
  color: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center !important;
  text-transform: none !important;
}
@media (max-width: 767px) {
  .services-cancel-dialog-button {
    width: 100%;
    max-width: 327px;
    margin-left: 40px !important;
    font-size: 14px !important;
  }
}
.services-cancel-button {
  width: 450px !important;
  height: 56px !important;
  border-radius: 25px !important;
  border-color: #e91212 !important;
  color: #e91212 !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center !important;
  text-transform: none !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

@media only screen and (max-width: 768px) {
  .services-cancel-button {
    width: 60% !important;
    min-width: 300px !important;
    height: 48px !important;
    margin-right: 0 !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}

.services-input-container {
  display: flex;
  font-family: "Poppins", sans-serif !important;
  /* position: absolute; */
  margin-left: 40px;
  margin-right: 30px;
  margin-bottom: 20px;
  flex-direction: column;
}
@media (max-width: 767px) {
  .services-input-container {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.services-button-container {
  display: flex;
  /* flex-direction: row; */
  /* margin-left: 40px !important; */
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .services-button-container {
    justify-content: flex-start;
  }

  .services-button-container button {
    width: auto;
    margin-right: 16px;
    margin-bottom: 0;
    margin-top: 30px !important;
  }
}

.services-add-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.services-dialog-container {
  display: flex;
  font-family: "Poppins-Medium", sans-serif !important;
  flex-direction: column;
  margin-top: 24px;
}

.services-dialog-label {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .services-dialog-label {
    font-size: 16px !important;
    margin-top: 10px !important;
    line-height: 19px !important;
  }
}

.service-dialog-input-style {
  font-family: "Poppins-Medium", sans-serif !important;
  color: #2c2c2e !important;
  font-size: 18px;
  border-radius: 8px !important;
}
@media (max-width: 767px) {
  .service-dialog-input-style {
    font-size: 14px;
    line-height: 18px;
  }
}

.service-error-message-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ff453a;
  margin-top: 12px;
}

@media (max-width: 767px) {
  .custom-table-container {
    max-width: 100%;
    margin-top: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .custom-table-container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.custom-table-container .MuiTableRow-root td,
.custom-table-container .MuiTableRow-root th {
  border-bottom: none !important;
}
.custom-table-container .MuiTableRow-root td:last-child,
.custom-table-container .MuiTableRow-root th:last-child {
  border-right: none !important;
}
.service-alert-dialog {
  width: 407px;
  height: 504px;
  border-radius: 14px;
  box-shadow: none;
}

@media (max-width: 767px) {
  .service-alert-dialog {
    width: 90%;
    height: 55% !important;
    border-radius: 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .service-alert-dialog {
    width: 90%;
    height: auto;
  }
}

.service-alert-dialog-button {
  flex-direction: column;
  margin-right: 40px;
  margin-left: 40px;
}

@media (max-width: 767px) {
  .service-alert-dialog-button {
    flex-direction: row;
    justify-content: space-between;
    margin-right: 20px;
    margin-left: 20px;
  }
}

.service-loader-dialog {
  width: 130px;
  height: 130px;
  border-radius: 14px;
  box-shadow: none;
}

.service-loader-dialog .MuiDialogContent-root{
  padding: 24px !important;
}

@media (max-width: 767px) {
  .service-loader-dialog {
    width: 30% !important;
    height: 15% !important;
  }
}
.service-success-dialog {
  width: 330px;
  height: 150px;
  border-radius: 15px;
  box-shadow: none;
}
@media (max-width: 767px) {
  .service-success-dialog {
    width: 70% !important;
    height: 20% !important;
    border-radius: 0;
  }
}

.services-category-dialog {
  width: 643px;
  height: 836px;
  border: 1px solid #757575;
  border-radius: 8px;
  box-shadow: none;
}

@media (max-width: 767px) {
  .services-category-dialog {
    width: 100%;
    height: auto;
    border-radius: 0 !important;
    border: none;
  }
}
.service-button-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.service-add-service-dialog {
  width: 643px;
  height: 836px;
  border-radius: 14px;
  box-shadow: none;
}

@media (max-width: 767px) {
  /* For screens smaller than 768px */
  .service-add-service-dialog {
    width: 100%;
    height: auto;
    border-radius: 0 !important;
    box-shadow: none;
  }
}
.service-add-service-field {
  flex-direction: column;
  display: flex;
}

@media (min-width: 768px) {
  .service-add-service-field {
    flex-direction: row;
    min-width: 100%;
  }
}
.service-add-service-textfield {
  width: 200px;
  border-radius: 8px !important;
  height: 54px;
}

@media only screen and (max-width: 768px) {
  .service-add-service-textfield {
    width: 100%;
    /* min-width: 600px !important; */
  }
}

.service-add-emp-dialog {
  width: 407px;
  height: 522px;
  border-radius: 14px;
  box-shadow: none;
}

@media only screen and (max-width: 600px) {
  .service-add-emp-dialog {
    width: 100% !important;
    height: 60% !important;
    padding: 20px;
  }
}
.service-add-emp-button {
  background-color: #1b5add !important;
  color: #ffffff !important;
  height: 64px;
  width: 327px;
  font-style: normal;
  font-size: 20px !important;
  font-weight: 500 !important;
  border-radius: 32px !important;
  margin-bottom: 40px !important;
  text-transform: none !important;
  line-height: 18px !important;
  font-family: "Poppins", sans-serif !important;
}

@media only screen and (max-width: 768px) {
  .service-add-emp-button {
    height: 50px;
    width: 100% !important;
    line-height: 18px !important;
    margin-bottom: 40px;
    margin-top: 20px !important;
  }
}
.service-add-emp-iconbutton {
  margin-top: 25px !important;
  width: 160px !important;
  height: 160px !important;
}

@media only screen and (max-width: 768px) {
  .service-add-emp-iconbutton {
    margin-top: 25px !important;
    width: 120px !important;
    height: 120px !important;
  }
}

.service-form-custom-select {
  width: 194px !important;
  position: relative;
  display: inline-block;
  font-family: "Poppins-Medium", sans-serif !important;
}

.service-form-custom-select select {
  width: 100%;
  height: 54px !important;
  background: #ffffff;
  border-radius: 8px !important;
  padding-right: 34px;
  font-weight: 500;
  font-size: 16px;
  /* margin-right: 8px; */
  appearance: none;
  font-family: "Poppins-Medium", sans-serif !important;
}
.service-form-custom-select option {
  font-weight: 500;
  font-size: 16px;
  font-family: "Poppins-Medium", sans-serif !important;
}

.service-form-custom-select img {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}
