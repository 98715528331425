.manage-booking-business-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 640px;
  top: 115px;
  left: 580px;
  height: 96%;
  overflow-y: scroll;
  scrollbar-width: none !important;
  max-width: 100%;
}

.arabic-manage-booking-business-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 640px;
  top: 115px;
  right: 580px;
  height: 96%;
  overflow-y: scroll;
  scrollbar-width: none !important;
  max-width: 100%;
}

.manage-booking-business-content-container::-webkit-scrollbar,
.arabic-manage-booking-business-content-container::-webkit-scrollbar {
  display: none !important;
}

@media (max-width: 767px) {
  .manage-booking-business-content-container {
    max-width: 80%;
    top: 805px;
    left: 60px;
  }

  .arabic-manage-booking-business-content-container {
    max-width: 80%;
    top: 805px;
    right: 60px;
  }
}

@media (min-width: 767px) and (max-width: 960px) {

  .manage-booking-business-content-container,
  .manage-catalouge-content-container {
    max-width: 80%;
    top: 805px;
    left: 100px;
  }

  .arabic-manage-booking-business-content-container,
  .arabic-manage-catalouge-content-container {
    max-width: 80%;
    top: 805px;
    right: 100px;
  }
}

.business-manage-booking-header-heading-and-search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}

.business-manage-booking-header-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 151px;
  background: #3d40c3;
  border-radius: 16px;
  padding: 2% 5%;
}

.business-manage-booking-header-bookings-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
}

.business-manage-booking-header-upcoming-past-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}

.business-manage-booking-header-upcoming-past-button-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 380px;
  height: 50px;
  background: #f9f9f8;
  border-radius: 25px;
  justify-content: space-around;
}

.business-manage-booking-header-upcoming-past-button-style {
  width: 47%;
  font-family: "Poppins-SemiBold", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #2c2c2e;
  text-align: center;
  cursor: pointer;
}

.business-manage-booking-header-upcoming-past-button-style-selected {
  width: 47%;
  font-family: "Poppins-SemiBold", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  background-color: #1b5add;
  border-radius: 25px;
  text-align: center;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.manage-booking-business-green-color {
  color: #30d158 !important;
}

.manage-booking-business-orange-color {
  color: #ff9f0a;
}

.manage-booking-business-purple-color {
  color: #8d35ff !important;
}

.manage-booking-business-grey-color {
  color: #777777 !important;
}

.manage-booking-business-red-color {
  color: #ff453a !important;
}

.business-manage-booking-appointment-details-container {
  background-color: #ffffff;
  border-radius: 16px !important;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 2% 5%;
  margin-top: 4%;
  width: 100%;
}

.manage-booking-business-booking-id-and-status-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.manage-booking-business-booking-id-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #757575;
}

.manage-booking-business-appointment-status-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.manage-booking-business-booking-date-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1%;
}

.manage-booking-business-booking-date-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2c2c2e;
}

.manage-booking-business-customer-image-name-appointmnet-type-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 3%;
}

.manage-booking-business-customer-image-name-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.manage-booking-business-customer-image-style {
  height: 44px;
  width: 44px;
  border-radius: 50%;
}

.booking-list-item h3, .arabic-booking-list-item h3{
  direction: ltr;
}

[dir="ltr"] .booking-list-item h3 {
  text-align: left;
}

[dir="rtl"] .arabic-booking-list-item h3 {
  text-align: right;
}

.manage-booking-business-customer-name-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2c2c2e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  direction: ltr;
  width: 100%;
}

[dir="ltr"] .manage-booking-business-customer-name-text {
  text-align: left;
}

[dir="rtl"] .manage-booking-business-customer-name-text {
  text-align: right;
}

.manage-booking-business-search-bar {
  width: 396px !important;
  height: 48px !important;
  background: #ffffff !important;
  border-radius: 24px !important;
  color: #16191b !important;
  font-family: "Poppins", sans-serif;
  padding: 10px !important;
  font-size: 16px !important;
}

.manage-booking-business-appointment-type-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #7073ff;
  border-radius: 10px;
  height: 22px;
  min-width: 53px;
  max-width: 65px;
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  padding: 1%;
}

.manage-booking-business-appointment-type-container img {
  margin-right: 2px;
}

.manage-booking-business-service-details-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #48484a;
  width: 50%;
  text-overflow: ellipsis;
}

.manage-booking-business-service-details-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1.5% 0%;
}

.manage-booking-business-employee-details-text {
  font-style: normal;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  color: #48484a;
  font-weight: 500;
  width: 50%;
  text-overflow: ellipsis;
  text-align: end;
}

.manage-booking-business-billing-details-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1.5% 0%;
}

.manage-booking-business-payment-method-details-text {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.manage-booking-business-bill-details-text {
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  font-family: "Poppins-Medium", sans-serif;
  color: #48484a;
}

.manage-booking-business-details-image-style {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.business-booking-user-info {
  width: 65%;
}

[dir="ltr"] .business-booking-user-info,
[dir="ltr"] .manage-booking-business-customer-name-text {
  padding-left: 1%;
}

[dir="rtl"] .business-booking-user-info,
[dir="rtl"] .manage-booking-business-customer-name-text {
  padding-right: 1%;
}

@media (max-width: 590px) {
  .manage-booking-business-details-image-style {
    height: 30px;
    width: 30px;
  }
}

.manage-booking-business-appointment-details-status-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid rgba(117, 117, 117, 0.0994045);
  border-radius: 16px;
  padding: 1% 2%;
}

.manage-booking-business-appointment-details-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.business-manage-booking-details-main-container {
  border-radius: 8px;
  margin-top: 5%;
  background-color: rgb(255, 255, 255);
  height: max-content;
  width: 100%;
  padding: 3% 2%;
}

.manage-bookings-business-service-tax-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #2c2c2e;
}

.manage-bookings-business-total-price-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #2c2c2e;
}

.manage-booking-business-element-conatiner {
  display: flex;
  font-family: "Poppins-Medium", sans-serif !important;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.manage-bookings-business-details-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5% 7%;
}

.manage-booking-start-button {
  width: 90% !important;
  height: 56px !important;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  text-transform: none !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  text-align: center !important;
  border-radius: 28px !important;
  margin: 0% 0% 2% 0% !important;
}

.manage-booking-start-button:disabled {
  background-color: #a5c4f7 !important;
  color: #ffffff !important;
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}

.manage-booking-schedule-button {
  width: 90% !important;
  height: 56px !important;
  background-color: #2c2c2e !important;
  color: #ffffff !important;
  text-transform: none !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  text-align: center !important;
  border-radius: 28px !important;
  margin: 0% 0% 2% 0% !important;
}

.manage-booking-schedule-button:disabled {
  background-color: #b3b3b3 !important;
  color: #ffffff !important;
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}

.manage-booking-cancel-stop-button {
  width: 90% !important;
  height: 56px !important;
  background-color: #ff453a !important;
  color: #ffffff !important;
  text-transform: none !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  text-align: center !important;
  border-radius: 28px !important;
}

.manage-booking-cancel-stop-button:disabled {
  background-color: #f0a6a0 !important;
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}

.manage-booking-review-button {
  width: 90% !important;
  height: 56px !important;
  color: #2c2c2e !important;
  background-color: #ffffff !important;
  text-transform: none !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  text-align: center !important;
  border-radius: 28px !important;
}

.manage-booking-button-disabled {
  opacity: 0.8 !important;
}

.manage-booking-cancellation-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  background-color: #f9f9f8;
  border-radius: 10px;
  padding: 2%;
}

.manage-booking-cancellation-container img {
  height: 12px;
  width: 12px;
  margin-right: 4px;
}

.manage-booking-cancellation-message {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2c2c2e;
}

.no-show-icon-image-web {
  height: 24;
  width: 48;
  cursor: pointer;
}

.no-show-icon-image-web-disabled {
  height: 24;
  width: 48;
  cursor: not-allowed;
}

.customer-review-manage-booking-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 427px;
  height: 500px;
  transform: translate(-50%, -50%);
  background: #ffffff !important;
  border-radius: 24px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customer-review-manage-booking-header {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 24;
  color: #2c2c2e;
}

.rating-star-image-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rating-star-image {
  height: 36;
  width: 36;
  margin: 0% 1%;
}

.customer-review-manage-booking-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 7%;
}

.customer-review-manage-booking-submit-button-style {
  height: 56px !important;
  width: 49% !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: none !important;
  background-color: #1e5fea !important;
  color: #fff !important;
  border-radius: 30px !important;
}

.customer-review-manage-booking-cancel-button-style {
  height: 56px !important;
  width: 49% !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: none !important;
  background-color: #ffffff !important;
  color: #2c2c2e !important;
  border: 1px solid #2c2c2e !important;
  border-radius: 30px !important;
}

.customer-review-manage-booking-input-style {
  width: 100% !important;
  height: 165px;
  border-radius: 8px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  margin-bottom: 6%;
}

.customer-review-manage-booking-default-text-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 165px;
  background-color: #f9f9f8;
  border-radius: 8px;
  padding: 2%;
}

.customer-review-manage-booking-default-text-div img {
  height: 15;
  width: 21;
  margin-bottom: 5%;
}

.customer-review-manage-booking-default-text-div span {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #2c2c2e;
}

.manage-booking-review-button img {
  height: 20px;
  width: 20px;
  margin-right: 1%;
}

.manage-booking-business-filter-icon,
.manage-booking-business-search-icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.business-manage-booking-filter-button-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: transparent;
  border-radius: 16px;
  padding: 2% 5%;
  align-items: flex-end;
}

.manage-business-error-message-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ff453a;
  margin-top: 8px !important;
  text-align: start;
  width: 100%;
  max-width: 100%;
}

@media (max-width: 768px) {
  .after-login-header-profile-container {
    justify-content: flex-end !important;
  }
}

.manage-block-my-time-date-container {
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 24;
  max-width: 71%;
  flex-wrap: nowrap;
}

.manage-block-my-time-time-container {
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 24;
  max-width: 100%;
  flex-wrap: nowrap;
}

.manage-block-my-time-buttons-contianer {
  max-width: 100%;
  align-items: center;
  display: flex;
  margin-bottom: 55px;
  margin-top: 60px;
  flex-wrap: nowrap;
}

@media screen and (max-width: 475px) {
  .manage-block-my-time-date-container {
    flex-wrap: wrap;
    max-width: 70%;
    justify-content: center;
  }

  .manage-block-my-time-time-container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .manage-block-my-time-time-container>div {
    width: 65% !important;
  }

  .manage-block-my-time-buttons-contianer {
    flex-wrap: wrap;
    margin: 10px !important;
  }

  .manage-block-my-time-buttons-contianer>div {
    margin-left: 0px !important;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .manage-block-my-time-buttons-contianer div button {
    margin-right: 0px !important;
  }
}

.modal-booknow-confirmed-review .img-debit-card-div-confirmed-review .img-debit-card-confirmed-review h1 {
  font-family: Poppins-Medium, sans-serif;
}