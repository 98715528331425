.menu-subcategory-detail-div {
    color: #757575;
    font-family: Poppins-Medium, sans-serif;
    font-weight: 500;
    margin-top: 0;
    overflow-y: auto !important;
    background: #F9F9F8;
    overflow-x: hidden !important;
    height: 100%;

    .fitness_detail-main-div {
        border-radius: 36px;
        background: #F9F9F8;
        background-blend-mode: normal;
        display: flex;
        align-self: center;
        flex-wrap: wrap;
        position: relative;
        cursor: pointer;
        padding: 35px 35px 90px 35px;
        max-width: 1440px;
        margin: auto;

        .business-profile-image-and-buttons-container-customer-flow {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .carousel-root {
                width: 60%;
                direction: initial;

                @media (max-width: 960px) {
                    width: 100%;
                }
            }

            .beast_detail-up-img {
                width: 100%;
                height: auto;
                aspect-ratio: 16/9;
                border-radius: 24px;
                object-fit: cover;
            }

            .business-profile-days-and-button-container-customer-flow {
                width: 38%;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media (max-width: 960px) {
                    width: 100%;
                }

                .select-timer {
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    padding: 5%;
                    background-color: transparent;
                    border-radius: 24px;
                    margin: 2%;

                    .business-profile-days-and-time-container {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        width: 100%;
                        margin: 1% 0%;

                        p {
                            color: #2c2c2e;
                            font-family: Poppins-Medium, sans-serif;
                            font-size: 16px;
                            font-weight: 400;
                            text-transform: capitalize;
                            width: 50%;
                        }

                        h6 {
                            color: #2c2c2e;
                            font-family: Poppins-Medium, sans-serif;
                            font-size: 15px;
                            font-weight: 500;
                            width: 50%;

                            @media screen and (max-width: 480px) {
                                font-size: 13px;
                            }

                            @media screen and (max-width: 380px) {
                                font-size: 10px;
                            }
                        }
                    }

                    h1 {
                        color: #48484a;
                        font-family: Poppins-Medium, sans-serif;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 24px;
                        margin-bottom: 5%;
                        width: 100%;

                        @media (max-width:483px) {
                            width: 100%;
                        }
                    }
                }
            }
        }

        @media (max-width:845px) {
            margin: 20px;
        }

        .fitness_detail_logo-div {
            position: absolute;
            color: #f5f5f5;
            padding: 10px;
            top: 40px;
            font-size: 8px;
            border-bottom-left-radius: 12px;
            border-top-right-radius: 12px;
            display: flex;
            flex-wrap: wrap;

            [dir="rtl"] & {
                right: 40px;
            }

            [dir="ltr"] & {
                left: 40px;
            }

            @media (max-width:845px) {
                top: 1.5%;
            }

            h1 {
                height: 18px;
                color: #f5f5f5;
                font-family: Poppins-Medium, sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                text-align: center;
            }
        }

        .beast-up-details-div {
            padding-top: 18px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            margin-left: auto;

            .book_call_btn {
                display: flex;
                flex-wrap: wrap;
                width: 34%;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 1385px) {
                    width: 38%;
                }

                @media screen and (max-width: 1285px) {
                    width: 41%;
                }

                @media screen and (max-width: 1240px) {
                    width: 42%;
                }

                @media screen and (max-width: 1192px) {
                    width: 100%;
                }

                [dir="ltr"] & {
                    margin-left: auto !important;
                }

                [dir="rtl"] & {
                    margin-right: auto !important;
                }

                .book-now-btn {
                    border-radius: 23px;
                    background: #1e5fea;
                    background-blend-mode: normal;
                    color: #ffffff;
                    font-family: Poppins-Medium, sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 16px;
                    width: 150px;
                    height: 46px;
                    text-transform: capitalize;
                    margin: 1.5%;
                }

                .call_us-btn {
                    display: inline-flex;
                    border-radius: 23px;
                    background: #1e5fea;
                    background-blend-mode: normal;
                    color: #ffffff;
                    font-family: Poppins-Medium, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 16px;
                    height: 46px;
                    width: 130px;
                    text-align: center;
                    align-items: center;
                    padding: 15px;
                    text-decoration: auto;
                    text-transform: capitalize;
                    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                    margin: 1.5%;
                }

                .Location-btn {
                    display: inline-flex;
                    border-radius: 23px;
                    background: #2c2c2e;
                    background-blend-mode: normal;
                    color: #ffffff;
                    font-family: Poppins-Medium, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 16px;
                    width: 140px;
                    height: 46px;
                    text-align: center;
                    align-items: center;
                    padding: 15px;
                    text-decoration: auto;
                    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                    text-transform: capitalize;
                    margin: 1.5%;
                }
            }

            h1 {
                color: #2c2c2e;
                font-family: Poppins-Medium, sans-serif;
                font-size: 40px;
                font-weight: 500;
                overflow: hidden;
                display: -webkit-box;
                width: 43%;

                @media screen and (max-width: 1385px) {
                    width: 38%;
                    font-size: 36px;
                }

                @media screen and (max-width: 1285px) {
                    width: 35%;
                    font-size: 32px;
                }

                @media screen and (max-width: 1240px) {
                    width: 32%;
                    font-size: 30px;
                }

                @media screen and (max-width: 1192px) {
                    width: 65%;
                    font-size: 30px;
                }
            }

            p {
                border-radius: 14px;
                background: #7073ff;
                background-blend-mode: normal;
                color: #f5f5f5;
                font-family: Poppins-Medium, sans-serif;
                font-size: 13px;
                font-weight: 500;
                padding-right: 10px;
                padding-left: 10px;
                line-height: 2;
            }


        }

        .location-beast-div {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            h1 {
                color: #2c2c2e;
                font-family: Poppins, sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 27px;
            }
        }

        .rating-details-div {
            display: flex;
            flex-wrap: wrap;
            padding: 18px;
            align-items: center;

            h1 {
                color: #2c2c2e;
                font-family: Poppins-Medium, sans-serif;
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                width: auto;
            }

            img {
                width: 30px;
                height: 30px;
            }

            h2 {
                color: #48484a;
                font-family: Poppins, sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }

        .details-div {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .details-description {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                @media (max-width:1142px) {
                    width: 100%;
                }

                p {
                    padding-top: 20px;
                    background: #F9F9F8;
                    color: #2c2c2e;
                    font-family: Poppins, sans-serif;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;

                    @media (max-width:1142px) {
                        text-align: justify;
                    }
                }
            }
        }

        .main-accordian {
            @media (max-width:1220px) {
                margin-left: initial !important;
            }
        }

        .title-head-select {
            display: flex;
        }

        .MuiAccordionSummary-root {
            background: #F9F9F8;
        }

        .accordian-title {
            background: #F9F9F8;
        }

        .MuiAccordionDetails-root {
            background: #F9F9F8;

            h1 {
                color: #2c2c2e;
                font-family: Poppins-Medium, sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                padding-left: 35px;
            }
        }

        .MuiAccordion-root {
            background: #F9F9F8;
        }
    }

    .contact-detail-section {
        width: 100%;

        h1 {
            color: #48484a;
            font-family: Poppins-Medium, sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            margin-top: 20px;
        }

        .div-contact {
            display: flex;
            width: 100%;
            align-items: self-end;

            h1 {
                color: #2c2c2e;
                font-family: Poppins, sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
            }
        }
    }

    hr {
        width: 100%;
        border: 1px solid #e5e5ea;
        height: 1px;
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .h1-our {
        color: #2c2c2e;
        font-family: Poppins-Medium, sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        width: 100%;
        display: flex;
        align-items: baseline;

        p {
            color: #48484a;
            font-family: Poppins, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .our-staff {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 70px;
        padding-left: 50px;
        width: 100%;

        @media (max-width:845px) {
            padding-left: 0px;
        }

        .slick-slider {
            width: 100%;
            padding-right: 30px;
            padding-left: 30px;

            .slick-arrow {
                width: 32px;
                height: 32px;
            }

            .slick-track {
                margin: inherit;
            }

        }

        .our-staff-cards {
            width: 400px;
            height: 240px;
            border-radius: 8px;
            background: #FFFFFF;
            background-blend-mode: normal;
            padding: 5% 10px 10px 10px;
            margin-top: 15px;
            margin-bottom: 60px;

            @media (max-width:1570px) {
                margin-left: 0px;
            }

            @media (max-width:470px) {
                width: 100%;
                height: auto;
                margin-left: 0px !important;
            }

            img {
                max-width: 60px;
                max-height: 60px;
                border-radius: 50%;
                margin: auto;
                width: 100%;
                height: 100%;
            }

            h3 {
                color: #2c2c2e;
                font-family: Poppins-Medium, sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 18px;
                margin: 8px;
            }

            h6 {
                color: #2c2c2e;
                font-family: Poppins, sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
            }

            p {
                color: #48484a;
                font-family: Poppins, sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                padding-top: 15px;
                height: 140px;
                overflow: auto;
                text-overflow: ellipsis;
            }
        }
    }

    .main-rating-details-part {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .Total-rating-div {
            width: 740px;

            .sub-total-rating-div {
                text-align: center;
                padding-top: 90px;
                padding-right: 100px;

                .MuiRating-root {
                    color: #ff9f0a !important;
                }

                .MuiRating-icon {
                    padding: 10px;
                }

                h1 {
                    color: #2c2c2e;
                    font-family: Poppins-Medium, sans-serif;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 48px;
                    padding: 10px;
                }

                h2 {
                    color: #48484a;
                    font-family: Poppins-Medium, sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                    padding: 10px;
                }
            }
        }

        .reviews-rating-div {
            h2 {
                color: #757575;
                font-family: Poppins-Medium, sans-serif;
                font-size: 18px;
                font-weight: 500;
            }

            .reviews-now-btn {
                border-radius: 27px;
                background: #1e5fea;
                background-blend-mode: normal;
                width: 480px;
                color: #ffffff;
                text-transform: capitalize;
                margin-top: 35px;
                height: 54px;
                margin-bottom: 10px;

                @media (max-width:546px) {
                    width: 100%;
                }
            }

            .reviews-scroll-div {
                width: 100%;
                margin-top: 35px;

                .sub-reviews-div {
                    width: 500px;

                    @media (max-width:546px) {
                        width: auto;
                    }

                    h6 {
                        color: #2c2c2e;
                        font-family: Poppins, sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        margin-top: 6px;
                        margin-bottom: 7px;
                    }

                    p {
                        color: #757575;
                        font-family: Poppins-Medium, sans-serif;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14px;

                        b {
                            color: #2c2c2e;
                            font-family: Poppins-Medium, sans-serif;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 14px;
                        }
                    }
                }

                .MuiRating-root {
                    color: #ff9f0a !important;
                }

                .MuiRating-icon {
                    padding: 4px 4px 4px 0px;
                }

                .profile-div {
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 26px;
                    }

                    div {
                        padding-left: 12px;

                        h1 {
                            color: #2c2c2e;
                            font-family: Poppins-Medium, sans-serif;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 16px;
                        }

                        p {
                            color: #2c2c2e;
                            font-family: Poppins-Medium, sans-serif;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 14px;
                            padding-top: 3px;
                        }
                    }
                }
            }
        }
    }

    div {

        // overflow: auto;
        h3 {
            color: #48484a;
            font-family: Poppins-Medium, sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
            margin-top: 63px;
            margin-bottom: 176px;

        }
    }
}




.breadcrum-div-class {
    max-width: 1440px;
    margin: auto;

    nav {
        border-radius: 16px;
        background-blend-mode: normal;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        cursor: pointer;
        margin: 135px;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 40px;
    }
}

[dir="ltr"] .slick-arrow {
    transform: scaleX(1) !important;
}

[dir="rtl"] .slick-arrow {
    transform: scaleX(-1) !important;
}