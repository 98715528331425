.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #2c2c2e !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.login-main-container {
  max-width: 100%;
  height: 100vh;
  overflow: hidden;
}

.login-form-main-container {
  max-width: 100%;
  height: 100%;
  background: url("../right_side_image.png") no-repeat center center fixed;
  background-size: cover;
}

.login-form-main-container:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 3% 0% 3% 0%;
}

.form-images-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.left-arrow-image-container {
  width: 111px;
  cursor: pointer;
}

.logo-image-style {
  width: 237px;
  height: 48px;
  cursor: pointer;
}

.account-creation-text-style {
  width: 321px;
  height: 48px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2c2c2e;
  margin-top: 16px;
}

.login-form-login-input-container .login-input-container:first-child {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 28px !important;
}

.login-form-login-input-container .login-input-container:nth-child(2) {
  display: flex;
  flex-direction: column;
}

.login-form-label {
  height: 16px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #757575;
  margin-bottom: 12px;
}

.country-code--text-style {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2e;
}

.password-label-style {
  height: 16px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #757575;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.login-form-input-style {
  width: 100% !important;
  height: 54px !important;
  border-radius: 8px !important;
}

.login-button-style {
  width: 100% !important;
  height: 50px !important;
  background-color: #1e5fea !important;
  margin: 40px 0px !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
}

.not-a-member-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #2c2c2e;
}

.register-now-text {
  font-weight: 500 !important;
  color: #636366 !important;
  cursor: pointer;
}

.forgot-password-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #2c2c2e;
  cursor: pointer;
}

.error-message-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #ff453a;
  margin-top: 12px;
}

.email-or-mobile-choose-container {
  display: flex;
  flex-direction: row;
  width: 216px;
  height: 44px;
  border-radius: 24px;
  background-color: #f9f9f8;
  margin-top: 18px;
}

.email-option,
.mobile-option {
  width: 50%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2c2c2e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selected-option {
  width: 50%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e5fea;
  border-radius: 22px;
  color: #ffffff;
  cursor: pointer;
}

.MuiOutlinedInput-adornedStart {
  padding-left: 0 !important;
}

.Mui-error {
  color: #ff453a !important;
}

.MuiDialog-paperScrollPaper {
  border-radius: 24px !important;
}

.login-form-background-image {
  width: 100%;
  height: 100%;
}

@media (min-width: 425px) and (max-width: 510px) {
  .login-form-fields-main-container {
    width: 100% !important;
  }
  .MuiDialog-paperScrollPaper {
    max-height: 100% !important;
    height: 100% !important;
  }
  .signup-login-modal-container {
    width: 405px !important;
  }
  .content-container-description,
  .content-container-heading {
    line-height: normal;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  .login-form-fields-main-container {
    width: 100% !important;
  }
  .MuiDialog-paperScrollPaper {
    max-height: 100% !important;
    height: 100% !important;
  }
  .login-form-container {
    width: 430px;
  }
  .login-form-login-input-container,
  .login-form-input-style,
  .login-button-style {
    width: 370px !important;
  }
  .login-form-login-input-container {
    padding: 0% !important;
  }
  .signup-login-modal-container {
    width: 360px !important;
  }
  .content-container-description,
  .content-container-heading {
    line-height: normal;
  }
}

@media screen and (max-width: 430px){
  .logo-image-style {
    width: 170px !important;
    height: 40px !important;
  }
}

@media screen and (max-width: 320px){
  .logo-image-style {
    width: 150px !important;
    height: 40px !important;
  }
}

@media screen and (max-width: 281px){
  .logo-image-style {
    width: 100px !important;
    height: 30px !important;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .login-form-fields-main-container {
    width: 100% !important;
  }
  .MuiDialog-paperScrollPaper {
    max-height: 100% !important;
    height: 100% !important;
  }
  .login-form-container {
    width: 375px;
  }
  .login-form-login-input-container,
  .login-form-input-style,
  .login-button-style {
    width: 295px !important;
  }
  .login-form-login-input-container {
    padding: 0% !important;
  }
  .signup-login-modal-container {
    width: 300px !important;
  }
  .content-container-description,
  .content-container-heading {
    line-height: normal;
  }
}

.login-form-login-input-container {
  width: 100%;
  padding: 0% 12%;
}

@media screen and (max-width: 320px) {
  .login-form-fields-main-container {
    width: 100% !important;
  }

  .login-form-container {
    width: 280px;
  }

  .account-creation-text-style {
    font-size: 12px !important;
    width: 100% !important;
  }

  .left-arrow-image-container {
    width: 80px !important;
  }

  .signup-login-modal-container {
    width: 280px;
  }

  .content-container-heading {
    font-size: 20px;
    line-height: normal;
  }

  .content-container-description {
    font-size: 14px;
    line-height: normal;
  }
}

.login-form-fields-main-container {
  width: 550px;
  height: auto;
  background: #fff;
  border-radius: 24px;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  max-height: 95vh;
  overflow: auto;
}

@media (max-width: 1290px) {
  .offer-flex-left-sub-container,
  .offer-flex-right-sub-container {
    width: 100%;
  }
}