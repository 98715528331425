.manage-catalouge-main-container {
  overflow: auto;
  background: #bdb9b933;

  .after-login-header {
    @media (max-width: 584px) {
      padding: 15px 8px;
    }
  }

  .manage-business-list-heading-container {
    @media (max-width: 500px) {
      width: auto;
    }
  }

  .manage-business-list-main-container {
    @media (max-width: 500px) {
      width: 100%;
    }

    .active-manage-services-list-item {
      @media (max-width: 1290px) {
        height: 100%;
      }
    }

    .manage-services-list-item {
      @media (max-width: 500px) {
        height: 100%;
      }
    }

    .manage-business-list-heading-text {
      @media (max-width: 500px) {
        margin-bottom: 12px;
      }
    }
  }
}

.arabic-manage-catalouge-main-container {
  overflow: auto;
  background: #bdb9b933;

  .after-login-header {
    @media (max-width: 584px) {
      padding: 15px 8px;
    }
  }

  .manage-business-list-heading-container {
    @media (max-width: 500px) {
      width: auto;
    }
  }

  .manage-business-list-main-container {
    @media (max-width: 500px) {
      width: 100%;
    }

    .active-manage-services-list-item {
      @media (max-width: 1290px) {
        height: 100%;
      }
    }

    .manage-services-list-item {
      @media (max-width: 500px) {
        height: 100%;
      }
    }

    .manage-business-list-heading-text {
      @media (max-width: 500px) {
        margin-bottom: 12px;
      }
    }
  }
}

.main-manage-hours-detail-list {
  position: absolute;
  width: 700px;
  top: 115px;
  left: 522px;
  height: 83vh;
  overflow-y: scroll;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none !important;
  }

  @media (max-width: 1292px) {
    left: 483px;
  }

  @media (max-width: 1024px) {
    left: 460px;
    width: 100%;
  }

  @media (min-width: 767px) and (max-width: 960px) {
    left: 110px;
    width: 75%;
    top: 805px;
  }

  @media (max-width: 767px) {
    left: 75px;
    width: 70%;
    top: 805px;
  }

  h1 {
    color: #48484a;
    font-family: Poppins-Medium, sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-left: 55px !important;

    @media (max-width: 1200px) {
      margin-left: 0px !important;
      font-size: 22px;
    }

    @media (max-width: 500px) {
      margin-left: 0px !important;
      font-size: 18px;
    }
  }

  div {
    display: flex;
    border-radius: 16px;
    background: #ffffff;
    width: 582px;
    height: 80px;
    align-items: center;
    margin: auto;
    padding: 20px;
    cursor: pointer;
    max-width: 100%;

    @media (max-width: 1200px) {
      margin: inherit;
    }

    @media (max-width: 500px) {
      width: 100%;
      margin: inherit;
    }

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
    }

    .arrow {
      margin-left: inherit;
      width: 16px;
      height: 16px;
      margin-top: 5px;

      @media (max-width: 1200px) {
        margin-left: auto;
      }
    }
  }
}

.arabic-main-manage-hours-detail-list {
  position: absolute;
  width: 700px;
  top: 115px;
  right: 522px;
  height: 83vh;
  overflow-y: scroll;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none !important;
  }

  @media (max-width: 1292px) {
    right: 483px;
  }

  @media (max-width: 1024px) {
    right: 465px;
    width: 100%;
  }

  @media (min-width: 767px) and (max-width: 960px) {
    right: 110px;
    width: 75%;
    top: 805px;
  }

  @media (max-width: 767px) {
    right: 75px;
    width: 70%;
    top: 805px;
  }

  h1 {
    color: #48484a;
    font-family: Poppins-Medium, sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-right: 55px !important;

    @media (max-width: 1200px) {
      margin-right: 0px !important;
    }

    @media (max-width: 500px) {
      margin-right: 0px !important;
    }
  }

  div {
    display: flex;
    border-radius: 16px;
    background: #ffffff;
    width: 582px;
    height: 80px;
    align-items: center;
    margin: auto;
    padding: 20px;
    cursor: pointer;

    @media (max-width: 1200px) {
      width: 400px;
      margin: inherit;
    }

    @media (max-width: 500px) {
      width: 100%;
      margin: inherit;
    }

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
    }

    .arrow {
      margin-left: 0 !important;
      margin-right: auto;
      width: 16px;
      height: 16px;
      margin-top: 5px;

      @media (max-width: 1200px) {
        margin-left: auto;
      }
    }
  }
}

.main-time-hours-detail-list {
  position: absolute;
  width: 700px;
  top: 115px;
  left: 574px;
  height: 83vh;
  overflow-y: scroll;

  @media (max-width: 1292px) {
    left: 483px;
  }

  @media (max-width: 1024px) {
    left: 450px;
    width: 100%;
  }

  @media (min-width: 767px) and (max-width: 960px) {
    left: 110px;
    width: 75%;
    top: 805px;
  }

  @media (max-width: 767px) {
    left: 76px;
    width: 74%;
    top: 805px;
  }

  .input-container-button {
    width: 586;
    margin: auto 0px !important;
    max-width: 100%;

    .MuiToggleButtonGroup-root {
      background: #ffffff;
      border-radius: 25px;
    }
  }

  .input-container-left {
    .cancel_deactivate-btn {
      margin: auto;
      margin-left: 60px;
      margin-top: 30px;

      .cancel-btn-time {
        width: 220px;
        height: 50px;
        border-radius: 25px;
        border: 1px solid #2c2c2e;
        text-transform: capitalize;
        color: #2c2c2e;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
      }

      .deactivate-btn {
        width: 220px;
        text-transform: capitalize;
        height: 50px;
        border-radius: 25px;
        background: #1e5fea;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
      }
    }
  }

  .h1-work {
    color: #3a3a3c;
    font-family: Poppins-Medium, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    margin-left: 54px;
  }

  h1 {
    color: #48484a;
    font-family: Poppins-Medium, sans-serif;
    font-size: 24px;
    font-weight: 500;
    display: flex;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .main_content-div {
    width: 643px;
    height: 488px;
    border-radius: 16px;
    background: #ffffff;
    padding: 40px;

    @media (max-width: 1136px) {
      width: 500px;
    }

    @media (max-width: 928px) {
      width: auto;
    }

    @media (max-width: 500px) {
      padding: 20px;
    }

    .input-div {
      .MuiInputBase-root {
        width: 554px;
        height: 54px;
        border-radius: 25px;

        @media (max-width: 1136px) {
          width: 415px;
        }

        @media (max-width: 928px) {
          width: 327px;
        }

        @media (max-width: 546px) {
          width: auto;
        }
      }

      .input-icon {
        width: 16px;
        height: 16px;
        margin-left: 25px;
      }

      input::placeholder {
        color: #48484a;
        font-family: Poppins-Medium, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .items-div {
      overflow: auto;
      height: 373px;

      .profile-first-div {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        width: 554px;
        max-width: 100%;

        @media (max-width: 1136px) {
          width: auto;
        }

        .our-staff-img {
          width: 56px;
          height: 56px;
          border-radius: 50%;

          @media screen and (max-width: 500px) {
            height: 40px;
            width: 40px;
          }
        }

        div {
          padding-left: 12px;

          @media screen and (max-width: 500px) {
            padding-left: 6px;
          }

          h1 {
            color: #2c2c2e;
            font-family: Poppins-Medium, sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            overflow-wrap: break-word;

            @media screen and (max-width: 500px) {
              font-size: 14px;
            }
          }

          p {
            color: #757575;
            font-family: Poppins-Medium, sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 16px;
            padding-top: 3px;

            @media screen and (max-width: 500px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.arabic-main-time-hours-detail-list {
  position: absolute;
  width: 700px;
  top: 115px;
  right: 574px;
  height: 83vh;
  overflow-y: scroll;

  @media (max-width: 1292px) {
    right: 483px;
  }

  @media (max-width: 1024px) {
    right: 420px;
    width: 100%;
  }

  @media (min-width: 767px) and (max-width: 960px) {
    right: 110px;
    width: 75%;
    top: 805px;
  }

  @media (max-width: 767px) {
    right: 76px;
    width: 74%;
    top: 805px;
  }

  .input-container-button {
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .MuiToggleButtonGroup-root {
      background: #ffffff;
      border-radius: 25px;
      max-width: 100%;
    }
  }

  .input-container-left {
    .cancel_deactivate-btn {
      margin: auto;
      margin-left: 60px;
      margin-top: 30px;

      .cancel-btn-time {
        width: 220px;
        height: 50px;
        border-radius: 25px;
        border: 1px solid #2c2c2e;
        text-transform: capitalize;
        color: #2c2c2e;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
      }

      .deactivate-btn {
        width: 220px;
        text-transform: capitalize;
        height: 50px;
        border-radius: 25px;
        background: #1e5fea;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
      }
    }
  }

  .h1-work {
    color: #3a3a3c;
    font-family: Poppins-Medium, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    margin-left: 54px;
  }

  h1 {
    color: #48484a;
    font-family: Poppins-Medium, sans-serif;
    font-size: 24px;
    font-weight: 500;
    display: flex;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .main_content-div {
    width: 643px;
    height: 488px;
    border-radius: 16px;
    background: #ffffff;
    padding: 40px;

    @media (max-width: 1136px) {
      width: 500px;
    }

    @media (max-width: 928px) {
      width: auto;
    }

    @media (max-width: 500px) {
      padding: 20px;
    }

    .input-div {
      .MuiInputBase-root {
        width: 554px;
        height: 54px;
        border-radius: 25px;

        @media (max-width: 1136px) {
          width: 415px;
        }

        @media (max-width: 928px) {
          width: 327px;
        }

        @media (max-width: 546px) {
          width: auto;
        }
      }

      .input-icon {
        width: 16px;
        height: 16px;
        margin-left: 25px;
      }

      input::placeholder {
        color: #48484a;
        font-family: Poppins-Medium, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .items-div {
      overflow: auto;
      height: 373px;

      .profile-first-div {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        width: 554px;
        max-width: 100%;

        @media (max-width: 1136px) {
          width: auto;
        }

        .our-staff-img {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          margin: 0% 1%;

          @media screen and (max-width: 500px) {
            height: 40px;
            width: 40px;
          }
        }

        div {
          padding-left: 12px;

          @media screen and (max-width: 500px) {
            padding-left: 6px;
          }

          h1 {
            color: #2c2c2e;
            font-family: Poppins-Medium, sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            overflow-wrap: break-word;

            @media screen and (max-width: 500px) {
              font-size: 14px;
            }
          }

          p {
            color: #757575;
            font-family: Poppins-Medium, sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 16px;
            padding-top: 3px;

            @media screen and (max-width: 500px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.editBusinss-btn {
  width: 467px;
  text-transform: capitalize !important;
  height: 50px;
  border-radius: 25px !important;
  background: #1e5fea !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  text-align: center !important;
  margin-top: 20px !important;
  max-width: 100% !important;

  [dir="ltr"] & {
    margin-left: 60px !important;
  }

  @media (max-width: 767px) {
    [dir="ltr"] & {
      margin-left: 0px !important;
    }
  }

  img {
    filter: brightness(0) invert(1);
    padding: 5px;
  }
}

[dir="ltr"] .arrow img,
#team_working_back_img,
#team-working-days-back-image,
#load_api_img {
  transform: scaleX(1);
}

[dir="rtl"] .arrow img,
#team_working_back_img,
#team-working-days-back-image,
#load_api_img {
  transform: scaleX(-1);
}