.business-subcrption-container {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  align-items: center;
}

.business-subcrption-container .heading-text {
  display: flex;
  font-size: 24px;
  font-family: "Poppins-Medium", sans-serif;
  color: #2c2c2c;
}

.business-subcrption-container .cards-container {
  display: flex;
  width: 560px;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 40px;
  margin-top: 3%;
  border-radius: 16px;
  max-width: 100%;
}

.business-subcrption-container .subscription-details {
  padding: 40px;
  width: 100%;
  background-color: #3d40c3;
  text-align: center;
  border-radius: 16px;
  margin-bottom: 35px;
}

.business-subcrption-container .subscription-date {
  color: #fff;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 12px;
  direction: ltr;
}

.business-subcrption-container .free-trail {
  color: #eee;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 22px;
}

.business-subcrption-container .benefit-text {
  color: #eee;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.business-subcrption-container .card-subtext {
  color: #eee;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.business-subcrption-container .charge-text {
  color: #eaeaea;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 12px;
}

.business-subcrption-container .subcrption-btn-container {
  margin-top: 105px;
}

.business-subcrption-container .subcrption-renew-btn {
  border-radius: 27px;
  background: #1e5fea;
  color: #fff;
  text-align: center;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  font-style: normal;
  border: none;
  padding: 13px 100px;
  font-weight: 500;
  cursor: pointer;
}

.business-subcrption-container .subcrption-cancel-btn {
  border-radius: 27px;
  border: 1px solid #ff453a !important;
  background: #fff;
  color: #ff453a;
  text-align: center;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding: 12px 100px;
  margin-top: 16px;
  cursor: pointer;
}

.business-subcrption-container .subcrption-cancel-msg-container {
  border-radius: 8px;
  background: #f9f9f8;
  padding: 20px 10px;
  margin: 5px;
  width: 100%;
}

.business-subcrption-container .subcrption-cancel-msg-text {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: start;
}

.business-subcrption-container .subcrption-cancel-msg-image {
  height: 18px;
  width: 18px;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 2px;
}

.subcrption-checkingpaycard {
  padding: 20px 40px 40px 40px;
}

.subscribe-modal-card .MuiDialog-paperWidthSm {
  width: 539px;
}

.subcrption-checkingpaycard .MuiFormControl-root {
  width: 100% !important;
}

.subcription-payment-card {
  background: #f9f9f8;
  display: flex;
  align-items: center;
  height: 88px;
  padding: 0 16px;
  border-radius: 16px !important;
  margin-bottom: 16px;
  cursor: pointer;
}

.subcription-payment-card :hover {
  background-color: #7073ff;
}

.subcription-radio {
  margin-left: auto;
}

.subcription-payment-card-details {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.subcription-h2-name-card {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.subcription-h2-tag-number {
  color: #48484a;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  font-family: "Poppins-Medium", sans-serif;
}

.subcription-list-checkingpaycard {
  width: 100%;
  padding: 20px 70px 40px 70px;
}

.subcription-nocard-div {
  text-align: center;
}

.subcription-nocard-hading {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.subcription-nocard-text {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.subcrption-add-another-btn {
  border-radius: 30px;
  background: #1e5fea;
  color: #fff;
  text-align: center;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  font-style: normal;
  border: none;
  padding: 13px 0px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 30px;
  width: 100%;
}

.subcription-close-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: -30px;
}

.subcrption-add-another-btn-1 {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.subcrption-book-now-btn-modal-debit {
  width: 88%;
  height: 52px;
  background: #1e5fea;
  background-blend-mode: normal;
  border-radius: 24px;
  color: #ffffff;
  margin: 25px;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
}

.subcrption-pay-hading {
  color: #fff;
}
.subcrption-h3-tag-debit {
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.modal-booknow {
  height: 535px;
  border-radius: 24px;
  background: #ffffff;
  background-blend-mode: normal;
}
.subscribe-modal-card .modal-booknow h3 {
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: "Poppins-Medium", sans-serif;
}
.subscribe-modal-card .modal-booknow .img-debit-card-div .img-debit-card {
  width: 100%;
}
.subscribe-modal-card .img-debit-card-div {
  padding: 0px 30px;
}
.subcrption-modal-popup {
  width: 100%;
  height: 52px;
  background: #1e5fea;
  background-blend-mode: normal;
  border-radius: 24px;
  color: #ffffff;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
}
.expired-tag {
  font-weight: 600;
  background: red;
  border-radius: 30px;
  padding: 10px 30px;
  color: white;
}
.expired-container {
  display: flex;
  justify-content: space-between;
  width: 560px;
  max-width: 100%;
}
.expired-tag-1 {
  font-weight: 600;
  background: #30d158;
  border-radius: 30px;
  padding: 10px 30px;
  color: white;
}

@media only screen and (max-width: 480px) {

  .subscription-date,
  .card-subtext,
  .free-trail {
    font-size: 16px !important;
  }

  .benefit-text {
    font-size: 18px !important;
  }

  .subcrption-cancel-btn,
  .subcrption-renew-btn {
    font-size: 15px !important;
    padding: 12px 60px !important;
  }

  .subscription-details {
    margin-bottom: 5px !important;
  }

  .subcrption-btn-container {
    margin-top: 50px !important;
  }
}

@media only screen and (max-width: 360px) {

  .subscription-date,
  .card-subtext,
  .free-trail {
    font-size: 13px !important;
  }

  .benefit-text {
    font-size: 18px !important;
  }

  .subcrption-cancel-btn,
  .subcrption-renew-btn {
    font-size: 15px !important;
    padding: 12px 46px !important;
  }

  .subscription-details {
    margin-bottom: 5px !important;
  }

  .subcrption-btn-container {
    margin-top: 50px !important;
  }
}


.business-whole-main-div .contact-div,
.business-whole-main-div .main-language-div {
  max-width: 100%;
}

@media screen and (max-width: 1148px) {
  .business-subcrption-container .subscription-date {
    font-size: 18px !important;
  }

  .business-subcrption-container .free-trail {
    font-size: 16px !important;
  }

  .business-subcrption-container .benefit-text {
    font-size: 20px !important;
  }

  .business-subcrption-container .card-subtext {
    font-size: 16px !important;
  }

  .business-subcrption-container .subcrption-renew-btn,
  .business-subcrption-container .subcrption-cancel-btn {
    font-size: 12px !important;
  }
}