.main-div {
    .main-rating-div {
        margin: auto;
        width: 740px;
        border-radius: 24px;
        background: #ffffff;
        margin-top: 40px;
        margin-bottom: 40px;
        padding: 12px 35px;
        @media (max-width:740px) {
            width: 100%;
        }
        .reviews-rating-div {
            .reviews-now-btn {
                border-radius: 27px;
                background: #1e5fea;
                background-blend-mode: normal;
                width: 480px;
                color: #ffffff;
                text-transform: capitalize;
                margin-top: 35px;
                height: 54px;
                margin-bottom: 10px;

                @media (max-width:546px) {
                    width: 100%;
                }
            }

            .reviews-scroll-div {
                width: 100%;
                margin-top: 35px;

                .sub-reviews-div {
                    width: 500px;

                    @media (max-width:546px) {
                        width: auto;
                    }

                    h6 {
                        color: #2c2c2e;
                        font-family: Poppins, sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        margin-top: 6px;
                        margin-bottom: 7px;
                    }

                    p {
                        color: #757575;
                        font-family: Poppins-Medium, sans-serif;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14px;

                        b {
                            color: #2c2c2e;
                            font-family: Poppins-Medium, sans-serif;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 14px;
                        }
                    }
                }

                .MuiRating-root {
                    color: #ff9f0a !important;
                }

                .MuiRating-icon {
                    padding: 4px 4px 4px 0px;
                }

                .profile-div {
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 26px;
                    }

                    div {
                        padding-left: 12px;

                        h1 {
                            color: #2c2c2e;
                            font-family: Poppins-Medium, sans-serif;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 16px;
                        }

                        p {
                            color: #2c2c2e;
                            font-family: Poppins-Medium, sans-serif;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 14px;
                            padding-top: 3px;
                        }
                    }
                }
            }
        }
    }
}