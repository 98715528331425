.landing-page-header {
  max-width: 1440px;
  overflow: hidden;
  display: flex;
  max-height: 140px;
  flex-direction: row;
  padding: 60px 100px 32px 100px;
  background-color: #ffffff;
  margin: 0 auto;
  justify-content: space-between;
}

.landing-page-header-logo {
  width: 237px;
  height: 48px;
}

.header-logo-container {
  display: flex;
  align-items: center;
  height: max-content;
  width: max-content;
}

.header-logo-container:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header-logo-container {
    margin-left: 30px;
  }
}

.button-and-text-container {
  display: flex;
  width: 65%;
  height: max-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.request-demo-button {
  width: 166px !important;
  height: 52px !important;
  background-color: #7073ff !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  text-transform: none !important;
}

.mobile-web-request-demo-button {
  width: 150px !important;
  height: 52px !important;
  background-color: #7073ff !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  text-transform: none !important;
}

.request-demo-and-close-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 14px 0px 24px;
}

.landing-page-header-item-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #2c2c2e;
  cursor: pointer;
}

.landing-page-footer-main-container {
  position: relative;
  overflow: hidden;
  display: block;
  max-height: 401px;
  background-color: #000000;
  padding: 100px 100px 72px 100px;
}

.landing-page-footer {
  max-width: 1440px !important;
  margin: 0 auto;
  overflow: hidden;
  max-height: 401px;
  display: flex;
  flex-direction: row;
  background-color: #000000;
}

.footer-logo-container {
  display: flex;
  height: max-content;
  width: 34.5%;
}

.footer-heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.footer-items-main-container {
  display: flex;
  flex-direction: row;
  width: 65.5%;
  justify-content: space-between;
}

.footer-item-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 170px;
}

.footer-items-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #dbdbdb;
  margin-top: 22px;
  cursor: pointer;
}

.footer-item-container .footer-items-text:last-child {
  margin-top: 15px !important;
}

.footer-horizontal-line {
  width: 100%;
  background-color: #979797;
  height: 1px;
  margin-top: 105px;
}

.landing-page-main-container {
  width: 100%;
  height: 2312px;
  background-color: #f9f9f8;
  overflow: scroll;
}

.landing-page-main-content-container {
  height: 1778px;
  max-width: 1440px;
  margin: 0 auto;
}

.book-a-visit-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.text-and-input-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-right: 42px;
  max-width: 330px;
}

.book-a-visit-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  color: #2c2c2e;
}

.easy-and-fast-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  color: #2c2c2e;
}

.find-the-best-service-text {
  width: 344px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2c2c2e;
  margin-top: 47px;
}

.find-your-service-input-container {
  width: 259px;
  height: 57px;
  margin-top: 40px;
}

.find-your-service-input {
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff !important;
  padding-right: 0 !important;
  background-color: #1e5fea !important;
  border-radius: 28px !important;
}

.find-your-service-input .MuiOutlinedInput-inputAdornedEnd {
  padding-right: 14px !important;
}

.first-landing-page-image-container img {
  border-radius: 24px;
  height: 489px;
  width: 384px;
  object-fit: cover;
}

.landing-page-image-container img,
.second-landing-page-image-container img,
.third-landing-page-image-container img,
.fourth-landing-page-image-container img {
  border-radius: 24px;
  height: 489px;
  width: 138px;
  object-fit: cover;
}

.first-landing-page-image-container,
.second-landing-page-image-container,
.third-landing-page-image-container {
  margin-right: 16px;
}

.first-landing-page-image-container,
.second-landing-page-image-container,
.third-landing-page-image-container,
.fourth-landing-page-image-container {
  height: 489px;
  position: relative;
}

.text-image-style {
  position: absolute;
  height: 51px;
  background: #7073ff;
  left: 0%;
  top: 89.5%;
  border-radius: 0px 24px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
}

.salon-button {
  width: 107px;
}

.spa-button {
  width: 92px;
}

.fitness-button {
  width: 119px;
}

.barber-button {
  width: 138px;
}

.available-service-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 90px;
}

.available-service-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #2c2c2e;
}

.service-available-at-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #2c2c2e;
  margin-top: 8px;
}

.services-listing-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.service-container {
  background: #ffffff;
  border-radius: 8px;
  width: 226px;
  height: 154px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
}

.services-listing-container .service-container:nth-child(2) {
  margin: 30px 0px 30px 40px !important;
}

.services-listing-container .service-container:nth-child(3) {
  margin: 30px 40px 30px 40px !important;
}

.service-name-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #2c2c2e;
  margin-top: 21px;
}

.download-app-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.download-app-content-container {
  display: flex;
  flex-direction: column;
  width: 640px;
  margin-top: 141px;
  margin-left: 10px;
}

.download-our-app-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 90px;
  color: #2c2c2e;
}

.download-pargraph-text {
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #3a3a3c;
  margin-top: 34px;
  margin-bottom: 63px;
}

.play-and-app-store {
  direction: ltr;
}

.play-and-app-store img {
  cursor: pointer;
}

.play-and-app-store img:first-child {
  margin-right: 24px;
}

.request-demo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 494px;
  height: 468px;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  overflow: visible !important;
}

.request-demo-content-container {
  width: 418px;
  display: flex;
  flex-direction: column;
}

.request-demo-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.request-demo-heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #2c2c2e;
}

.dashboard-form-label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2c2c2e;
  margin-bottom: 12px;
}

.request-demo-form-input-style {
  width: 418px !important;
  height: 54px !important;
  border-radius: 8px !important;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-roo .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #2c2c2e !important;
}

.country-code-text-style {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2e;
}

.email-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}

.phone-number-input-container:first-child {
  margin-bottom: 24px !important;
}

.phone-number-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2%;
}

.request-demo-modal-button {
  width: 418px !important;
  height: 50px !important;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  margin: 40px 0px 0px 0px !important;
}

.signup-login-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 540px;
  height: 400px;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-login-modal-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-login-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  margin-bottom: 25px;
  margin-left: 10%;
}

.signup-login-modal-heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #2c2c2e;
  width: 100%;
}

.content-containers {
  height: 100px;
  width: 85%;
  margin: 10px auto 10px auto;
  padding: 15px 20px 15px 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.for-everyone-container {
  border: 2px solid #2c2c2e;
  background-color: #ffffff;
}

.for-business-container {
  background-color: #1e5fea;
}

.content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.content-container-heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
}

.content-container-description {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
}

.for-everyone-container .content-container .content-container-heading,
.for-everyone-container .content-container .content-container-description {
  color: #2c2c2e !important;
}

.for-business-container .content-container .content-container-heading,
.for-business-container .content-container .content-container-description {
  color: #ffffff !important;
}

.after-login-header {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  max-height: 128px;
  flex-direction: row;
  padding: 15px 100px;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .after-login-header {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .after-login-header {
    padding: 10px;
    max-height: auto;
  }
}

.after-login-header-image {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

/* Media query for screens smaller than 768px, such as mobile devices */
@media (max-width: 767px) {
  .after-login-header-image {
    width: 40px;
    height: 40px;
  }
}

/* Media query for screens between 768px and 1024px, such as iPads */
@media (min-width: 768px) and (max-width: 1024px) {
  .after-login-header-image {
    width: 48px;
    height: 48px;
  }
}

.after-login-header-profile-container {
  display: flex;
  width: 46%;
  height: max-content;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 768px) {
  .after-login-header-profile-container {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

.navigation-menu-image {
  display: none;
}

.landing-page-images-slideshow-container {
  display: none;
  direction: ltr;
  max-width: 100%;
}

.mobile-web-button-and-text-container {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 3;
}

.landing-page-bottom-page {
  position: absolute;
  right: 0%;
  top: 1%;
}

.landing-page-images-container {
  display: flex;
  flex-direction: row;
  direction: ltr;
}

.web-mobile-services-listing-container {
  display: none;
  width: 325px;
  justify-content: center;
  align-items: center;
  direction: ltr;
}

.service_icon_mobile_image {
  height: 32px !important;
  width: 32px !important;
}

.service-name-carousel .carousel .slide {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.service-name-carousel .control-dots .dot {
  background-color: #999999 !important;
  height: 4px !important;
  width: 4px !important;
}

.service-name-carousel .control-dots .selected {
  background-color: #48484a !important;
  height: 6px !important;
  width: 6px !important;
}

.landing-page-footer-logo-image {
  height: 48px;
  width: 237px;
}

@media screen and (width: 2560px) {
  .header-logo-container {
    width: 30%;
  }

  .button-and-text-container {
    width: 70%;
  }

  .footer-logo-container {
    width: 60%;
  }

  .footer-items-main-container {
    width: 40%;
  }
}

@media screen and (width: 1440px) {

  .footer-logo-container,
  .footer-items-main-container {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .landing-page-main-container {
    overflow-x: hidden;
  }

  .header-logo-container {
    width: 30%;
  }

  .button-and-text-container {
    width: 70%;
  }

  .footer-logo-container {
    width: 35%;
  }

  .footer-items-main-container {
    width: 65%;
  }

  .landing-page-header {
    padding: 60px 10px 32px 10px;
  }

  .first-landing-page-image-container img {
    width: 250px !important;
  }

  .landing-page-image-container img,
  .second-landing-page-image-container img,
  .third-landing-page-image-container img,
  .fourth-landing-page-image-container img {
    width: 100px;
  }

  .barber-button {
    width: 90px;
    font-size: 14px !important;
  }

  .fitness-button {
    width: 90px;
  }

  .landing-page-bottom-page {
    position: absolute;
    left: 42%;
    top: 5%;
  }

  .download-app-content-container {
    width: 450px;
  }

  .download-our-app-text {
    font-size: 40px;
  }

  .play-and-app-store img:first-child {
    margin-right: 10px;
  }

  .services-listing-container .service-container:nth-child(2) {
    margin: 30px 0px 30px 20px !important;
  }

  .services-listing-container .service-container:nth-child(3) {
    margin: 30px 20px 30px 20px !important;
  }
}

@media screen and (max-width: 820px) {
  .button-and-text-container {
    width: 100%;
  }

  .landing-page-header-item-text {
    font-size: 14px;
  }

  .request-demo-button {
    width: 120px !important;
    height: 40px !important;
    font-size: 12px !important;
  }

  .footer-logo-container {
    width: 27%;
  }

  .footer-items-main-container {
    width: 73%;
  }

  .landing-page-header {
    padding: 60px 0px 32px 0px;
  }

  .landing-page-footer-main-container {
    padding: 72px 20px;
  }

  .landing-page-footer-logo-image {
    width: 180px;
  }

  .landing-page-images-container {
    display: none;
  }

  .first-landing-page-image-container img {
    width: 350px !important;
  }

  .landing-page-images-slideshow-container {
    display: block;
    width: 350px;
  }

  .service-container {
    width: 180px;
  }

  .services-listing-container .service-container:nth-child(2) {
    margin: 30px 0px 30px 10px !important;
  }

  .services-listing-container .service-container:nth-child(3) {
    margin: 30px 10px 30px 10px !important;
  }

  .landing-page-bottom-page {
    position: absolute;
    left: 45%;
    top: 45%;
    width: 60%;
  }

  .download-app-content-container {
    width: 410px;
  }

  .download-pargraph-text {
    margin-bottom: 25px;
  }

  .play-and-app-store img {
    width: 40%;
  }

  .play-and-app-store img:first-child {
    margin-right: 5px !important;
  }
}

@media screen and (max-width: 430px) {
  .landing-page-main-container {
    height: 1351px;
  }

  .services-listing-container {
    display: none;
  }

  .web-mobile-services-listing-container {
    display: block;
  }

  .landing-page-main-content-container {
    height: 912px;
  }

  .navigation-menu-image {
    display: block;
    height: 100%;
    width: max-content;
  }

  .button-and-text-container {
    display: none;
  }

  .landing-page-header {
    padding: 39px 24px 23px 24px;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-web-button-and-text-container {
    display: flex;
  }

  .landing-page-header-item-text {
    color: #ffffff !important;
    font-size: 18px !important;
    margin-top: 30px !important;
  }

  .login-text {
    margin-top: 140px !important;
  }

  .landing-page-header-logo {
    width: 148px !important;
    height: 29px !important;
  }

  .first-landing-page-image-container,
  .second-landing-page-image-container,
  .third-landing-page-image-container,
  .fourth-landing-page-image-container {
    height: 177px !important;
  }

  .first-landing-page-image-container img,
  .second-landing-page-image-container img,
  .third-landing-page-image-container img,
  .fourth-landing-page-image-container img {
    width: 327px !important;
    height: 177px !important;
  }

  .text-image-style {
    top: 75% !important;
    left: 3.65% !important;
    height: 45px;
    font-size: 12px;
  }

  .book-a-visit-main-container {
    flex-direction: column-reverse;
    margin-top: 28px;
  }

  .first-landing-page-image-container,
  .second-landing-page-image-container,
  .third-landing-page-image-container {
    margin-right: 0px;
  }

  .book-a-visit-text {
    font-size: 15px;
  }

  .easy-and-fast-text {
    font-size: 35px;
  }

  .find-the-best-service-text {
    font-size: 14px;
    margin-top: 12px;
    width: 275px;
    line-height: normal;
  }

  .landing-page-bottom-page {
    position: absolute;
    left: 48%;
    width: 200px !important;
    top: 32px;
  }

  .download-app-content-container {
    width: 180px !important;
    margin-top: 18px !important;
  }

  .download-our-app-text {
    font-size: 16px !important;
    line-height: normal !important;
  }

  .download-pargraph-text {
    font-size: 12px !important;
    line-height: normal !important;
    margin-top: 7px;
    margin-bottom: 16px;
  }

  .landing-page-images-container {
    display: none;
  }

  .landing-page-images-slideshow-container {
    display: block;
  }

  .play-and-app-store img {
    width: 72px;
    height: 20px;
  }

  .play-and-app-store img:first-child {
    margin-right: 8px !important;
  }

  .text-and-input-container {
    margin-right: 0px;
    margin-top: 15px;
  }

  .available-service-text {
    font-size: 20px;
    line-height: 30px;
  }

  .service-available-at-text {
    font-size: 12px;
    line-height: 18px;
  }

  .available-service-main-container {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .find-your-service-input {
    width: 182px !important;
    font-size: 12px !important;
  }

  .find-your-service-input-container {
    margin-top: 12px !important;
  }

  .service-container {
    width: 116px;
    height: 80px;
  }

  .footer-items-main-container {
    display: grid;
    grid-template-columns: 2fr auto;
  }

  .landing-page-footer-main-container {
    padding: 12px 24px 32px 24px;
  }

  .landing-page-footer {
    flex-direction: column;
  }

  .service-name-text {
    font-size: 14px;
    line-height: 21px;
    margin-top: 8px;
  }

  .footer-item-container .footer-items-text:last-child,
  .footer-items-text {
    margin-top: 12px;
  }

  .footer-items-text {
    font-size: 12px;
    line-height: 18px;
  }

  .footer-heading {
    font-size: 14px;
    line-height: 21px;
    margin-top: 12px;
  }

  .footer-horizontal-line {
    margin-top: 30px;
  }

  .request-demo-container {
    width: 360px !important;
    height: 430px !important;
  }

  .request-demo-content-container {
    align-items: center;
  }

  .request-demo-modal-button {
    width: 320px !important;
  }

  .phone-number-input-container,
  .email-input-container {
    width: 100% !important;
    padding: 0% 8%;
  }

  .request-demo-form-input-style {
    width: 300px !important;
  }
}

@media screen and (max-width: 320px) {
  .request-demo-container {
    width: 280px !important;
    height: 430px !important;
    padding: 0px 10px !important;
  }

  .whole-main-div .contact-div .setting-div .contact-email-div .hiperlink p {
    font-size: 18px !important;
  }

  .request-demo-content-container,
  .request-demo-modal-button,
  .request-demo-mobile-input,
  .request-demo-email-input,
  .request-demo-content-container .phone-number-input-container,
  .request-demo-content-container .email-input-container {
    width: 100% !important;
  }
}

.web-langauge-change-without-login,
.web-mobile-langauge-change-without-login {
  outline: none;
  border: none;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}

.web-langauge-change-without-login option {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  direction: inherit;
}

.web-mobile-langauge-change-without-login option {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  direction: inherit;
  color: #48484a !important;
}

select.web-langauge-change-without-login {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../../dashboard/assets/image_ArrowDown.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 15px;
  height: 100%;
  padding: 0px 20px;
}

select.web-mobile-langauge-change-without-login {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../../dashboard/assets/image_ArrowDown.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 15px;
  filter: invert(1);
  height: 100%;
  padding: 0px 20px;
}

@media screen and (max-width: 500px) {
  .employeelist-main-container .list-item {
    max-width: 100%;
  }

  .employeelist-main-container .list-item .user-info h3 {
    font-size: 14px;
  }

  .employeelist-main-container .list-item .user-info p {
    font-size: 12px;
  }

  .employeelist-main-container .list-item .arrow img {
    height: 20px;
    width: 20px
  }

  [dir="ltr"] .employeelist-main-container .list-item .arrow {
    margin-right: 2%;
  }

  [dir="rtl"] .employeelist-main-container .list-item .arrow {
    margin-left: 2%;
  }

  .employeelist-main-container .list-item img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0% 2%;
  }
}