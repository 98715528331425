.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #2c2c2e !important;
}

.forgot-password-container {
  max-width: 100%;
  height: 100%;
}

.forgot-password-background-image {
  width: 100%;
  height: 100%;
  filter: brightness(0.5);
}

.mobile-number-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 474px;
  height: 520px;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.enable-location-main-container {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 585px;
  width: 538px;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiDialog-paper {
  overflow: visible !important;
}
.mobile-number-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.verify-number-heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #2c2c2e;
}

.mobile-number-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 5%;
}

.verified-otp-container {
  width: 100%;
  max-width: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.enable-location-container {
  width: 418px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-number-modal-description {
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 57px 0px 24px 0px;
  text-align: center;
  color: #16191b;
}

.mobile-number-otp-sent-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #2c2c2e;
  margin: 57px 0px 48px 0px;
}

.resend-otp-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #48484a;
  margin-top: 46px;
  cursor: not-allowed;
}

.resend-otp-text-enabled {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1e5fea;
  margin-top: 46px;
  cursor: pointer;
}

.verify-button {
  max-width: 418px !important;
  width: 100% !important;
  height: 50px !important;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  margin: 35px 0px 0px 0px !important;
}

.submit-button {
  width: 100% !important;
  max-width: 418px !important;
  height: 50px !important;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
}

.enable-location-button {
  width: 418px !important;
  height: 50px !important;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  margin: 40px 0px 23px 0px !important;
}

.phone-number-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.confirm-password-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.confirm-password-main-input-container {
  width: 100%;
  margin: 46px 0px 40px 0px;
}

.confirm-password-input-container:first-child {
  margin-bottom: 24px;
}

.forgot-password-form-label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2c2c2e;
  margin-bottom: 12px;
}

.forgot-password-form-input-style {
  width: 100% !important;
  max-width: 418px !important;
  height: 54px !important;
  border-radius: 8px !important;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root,
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 !important;
}

.country-code-text-style {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2e;
}

.otp-input-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.otp-input-style {
  height: 60px !important;
  width: 60px !important;
  border-radius: 8px;
  text-align: center;
}

.otp-input-style .MuiInputBase-input {
  text-align: center !important;
}

.error-message-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-top: 12px;
  color: #ff3b30;
}

.verified-image-style {
  height: 160px;
  width: 160px;
}

.verification-message-text {
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #2c2c2e;
  margin-top: 40px;
}

.resend-otp-message-text {
  width: 50%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #2c2c2e;
  margin-top: 40px;
}

.form-input-container .input-container:first-child {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-top: 78px;
}

.enable-location-first-description-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #2c2c2e;
  margin-top: 40px;
}

.enable-location-second-description-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2c2c2e;
  margin-top: 16px;
}

.maybe-later-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #2c2c2e;
  cursor: pointer;
}

.please-try-again-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #2c2c2e;
  margin-top: 16px;
}

.resend-otp-timer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Mui-error {
  color: #ff453a !important;
}

@media screen and (max-width: 767px) {
  .mobile-number-container {
    width: 350px;
  }
}

@media screen and (max-width: 325px) {
  .mobile-number-container {
    width: 270px;
  }
}
