.buisness-dialog-modal {
    .MuiDialog-paper {
        width: 43%;
        height: 470px;

        .first-title-div {
            display: flex;
            align-items: center;
            text-align: center;
            padding: 38px 38px 0px 38px;
            h1 {
                margin: auto;
                color: #2c2c2e;
                font-family: "Poppins", sans-serif;
                font-size: 22px;
                font-weight: 600;
                text-align: center;
                padding-left: 30px;
                text-transform: capitalize;

            }

            img {
                width: 54px;
                height: 54px;
                cursor: pointer;
            }
        }

        .items_of_business {
            padding: 20px;
            overflow: auto;
            display: flex;
            flex-wrap: wrap;
            div{
                margin: 10px;
                img {
                    width: 248px;
                    height: 120px;
                    cursor: pointer;
                    @media (max-width:610px) {
                        width: 100%;
                    }
                }
    
                h1 {
                    color: #16191b;
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: capitalize;
                    padding-bottom: 10px;
                }
            }
          
        }
        @media (max-width:1045px) {
            width: 50%;
        }
    
        @media (max-width:610px) {
            width: 100%;
        }
    }
}

.Puppet {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1440px;
    margin: auto;
    margin-bottom: 60px;
    @media (max-width:753px) {
        width: 100%;
    }
  }
  
  .boy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
    .service_provided_main_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      .service_provided_image_container {
        height: 133px;
        width: 133px;
        background-color: #fff;
        border-radius: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
  
        img {
          height: 50%;
          width: 50%;
        }
      }
    }
  
  .boy_girl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .reschedul-error-container{
    display: inline-flex;
    align-items: center;
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 12px;
    margin-bottom: 25px;
    width: fit-content;
  }
  .reschedul-error-text{
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    margin-left: 20px;
  }
  .reschedul-error-img{
    height: 25px;
    width: 25px;
  }
  .reschedul-error{
    background-color: #F9F9F8;
    margin-left: 30px;
  }
  .reschedul-text{
    font-size: 18px;
  }
  @media only screen and (max-width: 600px) {
    .reschedul-error-text {
     font-size: 15px;
     align-items: center;
    }
    .reschedul-error-img{
        height: 15px;
        width: 15px;
    }
  }