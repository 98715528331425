.manage-business-main-content-conainer {
  display: flex;
}

.business-home-screen-content-container {
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
}

.business-name-home-screen-container {
  font-size: 24;
  font-family: "Poppins-SemiBold", sans-serif;
  color: #2c2c2e;
}

.manage-business-main-conainer {
  background-color: #f2f2f2;
  overflow: scroll;
}

.business-details-and-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px 0px;
  height: auto;
}

.manage-business-service-main-content-container {
  margin: 32px 44px 0px 44px;
}

.business-home-screen-sidebar {
  width: 80px;
  min-height: 100vh;
  background-color: #3d40c3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .business-home-screen-sidebar {
    width: 35px !important;
  }
}

@media screen and (max-width: 960px) {

  .business-home-screen-sidebar,
  .employee-home-screen-sidebar {
    background-color: transparent !important;
    height: 100vh !important;
  }

  [dir="ltr"] .business-home-screen-sidebar-image,
  [dir="ltr"] .employee-home-screen-sidebar img {
    filter: invert(1) !important;
    margin-left: 10px !important;
  }

  [dir="rtl"] .business-home-screen-sidebar-image,
  [dir="rtl"] .employee-home-screen-sidebar img {
    filter: invert(1) !important;
    margin-right: 10px !important;
  }
}

.business-home-screen-sidebar-image {
  width: 28px;
  height: 28px;
  margin-top: 48px;
}

.upcoming-bookings-button-style-home-screen {
  width: 222px !important;
  height: 48px !important;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  margin: 0 !important;
  max-width: 100% !important;
}

.calendar-and-buttons-details-screen-sub-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.upcoming-bookings-button-style-home-screen img,
.walk-in-button-style-home-screen img,
.disable-walk-in-button-style-home-screen img {
  height: 15px;
  width: 15px;
}

.walk-in-button-style-home-screen {
  width: 124px !important;
  height: 48px !important;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  margin: 0 !important;
  max-width: 100% !important;
}

.disable-walk-in-button-style-home-screen {
  width: 124px !important;
  height: 48px !important;
  background-color: #1e5fea !important;
  color: #ffffff !important;
  border-radius: 25px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  text-align: center !important;
  text-transform: none !important;
  margin: 0 !important;
  opacity: 0.8 !important;
  max-width: 100% !important;
}

.calendar-and-buttons-home-screen-sub-container {
  display: flex;
  width: 65%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.business-name-and-bookings-home-screen-sub-container {
  display: flex;
  width: 35%;
  flex-direction: row;
  align-items: center;
  padding: 0% 1%;
}

.business-home-screen-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.205365);
  border-radius: 24px;
  width: 278px;
  height: 48px;
  justify-content: center;
  cursor: pointer;
  font-size: 15px;
  max-width: 100%;
}

.business-detail-screen-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 24px;
  width: 55%;
  height: 48px;
  justify-content: flex-start;
  margin-left: 5%;
  margin-top: 20px;
}

.business-detail-screen-date-container img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 12px;
}

.all-employees-list-and-bookings-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0% 1.5% 4% 1.5%;
  max-width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
}

.all-employees-list-and-bookings-container::-webkit-scrollbar {
  display: none;
}

.upcoming-button-and-walkin-detail-page-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 45%;
  margin-top: 20px;
}

.all-booking-business-home-screen-text-container-selected {
  display: flex;
  width: 108px;
  height: 128px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #3d40c3;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins-Medium", sans-serif;
  margin-left: 20px;
  border-radius: 8px;
  text-align: center;
}

.all-booking-business-home-screen-text-container {
  display: flex;
  width: 108px;
  height: 128px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #2c2c2e;
  font-size: 16px;
  font-family: "Poppins-Medium", sans-serif;
  margin-left: 20px;
  border-radius: 8px;
  text-align: center;
}

.employee-on-home-screen-for-booking-text-container {
  display: flex;
  width: 108px;
  height: 128px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  color: #2c2c2e;
  font-size: 16px;
  font-family: "Poppins-Medium", sans-serif;
  margin-left: 12px;
  border-radius: 8px;
  text-align: center;
}

.employee-on-home-screen-for-booking-text-container-selected {
  display: flex;
  width: 108px;
  height: 128px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #3d40c3;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins-Medium", sans-serif;
  margin-left: 12px;
  border-radius: 8px;
  text-align: center;
}

.employee-on-home-screen-for-booking-text-container img {
  height: 32px !important;
  width: 32px !important;
  border-radius: 50%;
}

.employee-on-home-screen-for-booking-text-container-selected img {
  height: 32px !important;
  width: 32px !important;
  border-radius: 50%;
}

.manage-business-list-main-container {
  background-color: #ffffff;
  width: 402px;
  max-height: 723px;
  border-radius: 16px;
  padding: 0% 0% 1% 0%;
}

.manage-business-list-heading-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 30px;
  justify-content: space-around;
}

.manage-business-list-heading-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #48484a;
}

.manage-services-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 67px;
  height: auto;
  width: 100%;
  padding: 0px 16px;
  cursor: pointer;
}

.active-manage-services-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 67px;
  height: auto;
  width: 100%;
  background-color: rgba(27, 90, 221, 0.2);
  border-radius: 12px;
  padding: 0px 16px;
  cursor: pointer;
}

.manage-services-list-details-container {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.business-list-heading-subscribed-user-logo {
  width: 16px;
  height: 16px;
}

[dir="ltr"] .business-list-heading-subscribed-user-logo {
  margin-right: 6px;
}

[dir="rtl"] .business-list-heading-subscribed-user-logo {
  margin-left: 6px;
}

.business-list-heading-subscribed-user-container {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2c2c2e;
  display: flex;
  align-items: center;
  /* margin: auto; */
}

.manage-services-list-item-heading {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2e;
}

.manage-services-list-item-details {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #636366;
}

.manage-services-list-item-arrow-image-container {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-services-list-item-arrow-image {
  width: 16px;
  height: 16px;
}

[dir="ltr"] .manage-services-list-item-arrow-image {
  transform: scaleX(1);
}

[dir="rtl"] .manage-services-list-item-arrow-image {
  transform: scaleX(-1);
}

.manage-services-list-item-container {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 32px;
}

.manage-reviews-listing-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  padding: 4%;
  max-width: 100%;
  border-radius: 16px;
  margin: 2% 0%;
}

.review-detail-giver-container {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
}

.manage-reviews-given-star-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
  flex-wrap: wrap;
}

.manage-review-image-name-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
}

.manage-review-name-date-container {
  display: flex;
  flex-direction: column;
}

.manage-reviews-description-text-style {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #2c2c2e;
  margin: 2% 0%;
}

.manage-reviews-name-text-style {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  color: #2c2c2e;
}

.manage-reviews-date-text-style {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #757575;
}

.manage-review-image-name-date-container img {
  height: 56px;
  width: 56px;
  margin-right: 5%;
  border-radius: 50%;
}

.manage-reviews-employee-name-text-style,
.manage-reviews-booking-code-text-style {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  color: #48484a;
  margin: 1% 0%;
}

.manage-reviews-given-star-container img {
  height: 32px;
  width: 32px;
  margin: 0% 1%;
}

@media (min-width: 1024px) and (max-width: 1290px) {
  .manage-business-service-main-content-container {
    margin: 28px 40px 0px 40px;
  }

  .manage-business-list-main-container {
    width: 325px;
  }

  .manage-business-list-heading-text {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .manage-business-service-main-content-container {
    margin: 28px 30px 0px 30px;
  }

  .manage-business-list-main-container {
    width: 100% !important;
  }

  .manage-business-list-heading-text {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .manage-business-list-main-container {
    width: 100% !important;
  }

  .manage-business-service-main-content-container {
    margin: 32px 35px 0px 27px;
  }

  .manage-business-list-heading-text {
    font-size: 14px;
  }

  .business-list-heading-subscribed-user-container {
    font-size: 12px;
  }
}

.DatePicker_dateDayItem__1tg8E {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  cursor: pointer !important;
  margin: 0 0 0 5px;
  width: 90px !important;
  height: 100px !important;
  flex-shrink: 0 !important;
  justify-content: center !important;
  border-radius: 40% !important;
}

.DatePicker_buttonWrapper__3HD0q {
  background-color: rgb(242, 242, 242) !important;
  align-items: center !important;
  margin: 3.5% 0% 0% 0% !important;
}

@media (min-width: 600px) and (max-width: 820px) {
  .business-home-screen-content-container {
    width: calc(100% - 40px);
  }

  .calendar-and-buttons-home-screen-sub-container {
    width: 65%;
    flex-direction: row;
    align-items: center;
  }

  .business-name-and-bookings-home-screen-sub-container {
    width: 35%;
    padding: 0% 2%;
  }

  .business-home-screen-date-container {
    width: 165px;
    height: 30px;
    font-size: 10px;
    margin-top: 2%;
  }

  .upcoming-bookings-button-style-home-screen {
    width: 165px !important;
    height: 30px !important;
    font-size: 10px !important;
    margin-top: 2% !important;
  }

  .walk-in-button-style-home-screen,
  .disable-walk-in-button-style-home-screen {
    height: 30px !important;
    font-size: 10px !important;
    margin-top: 2% !important;
  }
}

@media (max-width: 590px) {
  .business-home-screen-content-container {
    width: calc(100% - 40px);
  }

  .calendar-and-buttons-home-screen-sub-container {
    width: 50%;
    flex-direction: column-reverse;
    align-items: end;
  }

  .business-name-and-bookings-home-screen-sub-container {
    width: 50%;
    padding: 0% 3%;
  }

  .business-home-screen-date-container {
    width: 165px;
    height: 30px;
    font-size: 10px;
    margin-top: 2%;
  }

  .upcoming-bookings-button-style-home-screen {
    width: 165px !important;
    height: 30px !important;
    font-size: 12px !important;
    margin-top: 2% !important;
  }

  .walk-in-button-style-home-screen,
  .disable-walk-in-button-style-home-screen {
    height: 30px !important;
    font-size: 12px !important;
    margin-top: 2% !important;
  }

  .upcoming-button-and-walkin-detail-page-container {
    flex-direction: column-reverse;
    align-items: end;
  }
}

.arabic-manage-catalouge-content-container .manage-reviews-listing-details-container .review-detail-giver-container .manage-review-image-name-date-container img {
  height: 56px;
  width: 56px;
  margin-right: 0 !important;
  margin-left: 5%;
  border-radius: 50%;
}

.employee-name-in-calender-home-screen {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding: 5%;
  cursor: pointer;
}

@media screen and (max-width: 360px) {
  .header-logo-container img {
    height: 48px;
    width: 160px;
  }

  .manage-business-service-main-content-container {
    margin: 10px !important;
    max-width: 100%;
    width: 100%;
  }

  .manage-business-list-heading-container,
  .manage-services-list-item-container {
    margin-left: 10px !important;
  }

  .manage-services-list-item-heading {
    font-size: 14px !important;
  }

  .manage-services-list-item-details {
    font-size: 12px !important;
  }

  .business-manage-booking-header-upcoming-past-button-style-selected,
  .business-manage-booking-header-upcoming-past-button-style {
    font-size: 12px !important;
  }

  .manage-booking-business-content-container {
    left: 45px !important;
  }

  .manage-business-holiday-main-container .manage-business-holiday-content-container {
    left: 40px !important;
  }

  .arabic-manage-booking-business-content-container {
    right: 45px !important;
  }

  .manage-booking-business-customer-name-text {
    font-size: 14px !important;
    line-height: normal !important;
  }

  .manage-booking-business-booking-date-text,
  .manage-booking-business-appointment-status-text,
  .manage-booking-business-payment-method-details-text,
  .manage-booking-business-bill-details-text,
  .manage-booking-business-employee-details-text,
  .manage-booking-business-service-details-text,
  .manage-booking-business-appointment-details-status-text,
  .manage-employee-header-text {
    font-size: 12px !important;
  }

  .booking-id {
    font-size: 16px !important;
  }

  .booking-list-item,
  .arabic-booking-list-item {
    flex-wrap: wrap !important;
    justify-content: center !important;
    margin: 0px 0px 20px 5px !important;
  }

  .arabic-booking-arrow {
    margin: 0px auto !important;
  }

  .business-name-home-screen-container {
    font-size: 16px;
  }

  .business-home-screen-date-container img {
    height: 10px;
    width: 10px;
    margin: 5px;
  }

  .upcoming-bookings-button-style-home-screen,
  .walk-in-button-style-home-screen,
  .disable-walk-in-button-style-home-screen,
  .business-home-screen-date-container {
    font-size: 8px !important;
  }
}

.employee-add-block-my-time-label {
  width: 100%;
  max-width: 71%;
}

.manage-employee-main-container {
  background-color: #f2f2f2;
}