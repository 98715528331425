.employes-view-Profile-main-container {
  max-width: 100%;
  height: 1701px !important;
  width: 100% !important;
  position: relative;
  overflow-y: auto !important;
  /* Add !important to ensure precedence */
  background-color: #f9f9f8 !important;
}

.paper-box-image-div {
  margin-top: 52px;
  margin-left: 40%;
  margin-right: 40%;
  max-width: 100%;
  height: auto;
}

/* This CSS applies to all screen sizes */
.paper-box-image {
  width: 120px;
  height: 120px;
  border-radius: 50% !important;
  overflow: hidden;
}

.paper-box-image img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* This CSS applies to screens with a maximum width of 768 pixels, which includes iPads */
@media (max-width: 768px) {
  .paper-box-image {
    width: 100px;
    height: 100px;
  }
}

/* This CSS applies to screens with a maximum width of 600 pixels, which includes mobile devices */
@media (max-width: 600px) {
  .paper-box-image {
    width: 80px;
    height: 80px;
  }
}

.subheader-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #000000;
}

.subLabel {
  line-height: 22px !important;
  color: #3a3a3c !important;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .paper-box-image-div {
    margin-top: 22px !important;
  }
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .header-container {
    height: auto;
    padding: 16px;
  }

  .logo {
    width: auto;
    height: 36px;
    margin-left: 16px;
    margin-top: 16px;
    flex-shrink: 1;
    flex-grow: 1;
  }

  .header-right {
    justify-content: center;
    margin-top: 16px;
  }

  .right-image {
    margin-right: 0;
  }
}

.subheader-container {
  background-color: #f9f9f8;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 48px;
}

[dir="ltr"] .subheader-container {
  margin-left: 130px;
  margin-right: 0px;
}

[dir="rtl"] .subheader-container {
  margin-right: 1%;
  margin-left: 0px;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .subheader-container {
    align-items: flex-start;
    padding: 16px;
    margin-top: 24px;
  }

  [dir="ltr"] .subheader-container {
    margin-left: 30px;
    margin-right: 0px;
  }

  [dir="rtl"] .subheader-container {
    margin-right: 1%;
    margin-left: 0px;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .subheader-container {
    padding: 16px;
    margin-top: 24px;
    margin-left: 48px;
  }

  [dir="ltr"] .subheader-container {
    margin-left: 48px;
    margin-right: 0px;
  }

  [dir="rtl"] .subheader-container {
    margin-right: 1%;
    margin-left: 0px;
  }
}

/* Style for the paper-box-container div */
.paper-box-container {
  position: absolute;
  display: flex;
  /* or display: grid; */
  align-items: flex-start;
  /* Adjust as needed */
  justify-content: space-between;
  border-radius: 16px !important;
  background-color: #f9f9f8 !important;
  margin-top: 24px;
  margin-left: 100px;
  margin-right: 50px;
  flex-wrap: wrap;
  /* Add flex-wrap property to allow boxes to wrap on smaller screens */
}

.employee-icon {
  margin-right: 12px;
  flex-direction: row !important;
}

.right-subheader-container {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: 5px;
}

.right-header-container-nth-child {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: 32px;
}

.right-header-container-services {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px !important;
  margin-top: 32px;
}

.subHeader {
  white-space: nowrap;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c2e;
}

.HeaderNote {
  white-space: nowrap;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
  margin-top: 8px;
}

.paper-box {
  flex: 0 0 calc(50% - 32px);
  height: auto !important;
  border-radius: 16px !important;
  background-color: #ffffff;
  margin: 16px;
}

@media screen and (max-width: 950px) {
  .paper-box {
    width: 100%;
    height: auto;
    flex: 0 0 calc(100% - 12px);
    margin: 16px;
  }
}

@media screen and (max-width: 768px) {
  .paper-box {
    width: 100%;
    flex: 0 0 calc(100% - 12px);
    /* margin: 16px; */
  }
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .paper-box-container {
    flex-wrap: wrap;
    margin-top: 10px;
    margin-left: 16px !important;
  }
}

.card-field-container {
  margin-top: 24px !important;
  margin-left: 30px;
  margin-right: 32px;
}

@media (max-width: 768px) {
  .card-field-container {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.field-container {
  margin-top: 44px !important;
  margin-left: 25px;
  margin-right: 32px;
}

.field-container-nth-child {
  margin-left: 25px;
  margin-right: 32px;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .field-container {
    margin-top: 22px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .field-container-nth-child {
    margin-top: 15px !important;
    margin-left: 16px;
    margin-right: 16px;
  }
}

/* Media query for tablet screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .field-container {
    margin-top: 44px;
    margin-left: 32px;
    margin-right: 32px;
  }
}

.emp-form-label {
  display: flex;
  margin-left: 32px;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 22px;
  color: #2c2c2e !important;
}

.arabic-emp-form-label {
  display: flex;
  margin-right: 32px;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 22px;
  color: #2c2c2e !important;
}

.employee-form-input-style {
  box-sizing: border-box;
  width: calc(100% - 64px);
  height: 54px;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2e;
  font-family: "Poppins-Medium", sans-serif !important;
}

.aboutme {
  flex-direction: column;
  height: 150px !important;
}

.employee-card-form-input-style {
  box-sizing: border-box;
  width: calc(100% - 64px);
  height: 54px;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  font-family: "Poppins-Medium", sans-serif !important;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .employee-card-form-input-style {
    width: 80%;
    margin-left: 34px !important;
    margin-right: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .employee-card-form-input-style {
    width: calc(100% - 48px);
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 10px;
    margin-bottom: 18px;
  }
}

.country-code-text-style {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2e;
}

.Employment-card-container {
  width: calc(100% - 64px);
  height: 288px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-left: 5px !important;
  margin-top: 24px !important;
  margin-bottom: 30px !important;
  border-radius: 16px !important;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .Employment-card-container {
    width: calc(100% - 12px);
    margin-left: 0;
    margin-right: 0;
    margin-top: 12px !important;
    margin-bottom: 10px !important;
    border-radius: 0;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .Employment-card-container {
    width: calc(100% - 32px);
    margin-left: 16px !important;
    margin-right: 16px !important;
    margin-top: 18px !important;
    margin-bottom: 28px !important;
  }
}

.workingday-card-container {
  margin-top: 24px !important;
  margin-left: 5px !important;
  width: calc(100% - 64px);
  height: auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 16px !important;
  margin-bottom: 30px !important;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .workingday-card-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 12px !important;
    margin-bottom: 20px !important;
    border-radius: 0;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .workingday-card-container {
    width: calc(100% - 32px);
    margin-left: 16px !important;
    margin-right: 16px !important;
    margin-top: 18px !important;
    margin-bottom: 28px !important;
  }
}

/* Style for the row-container div */
.row-container {
  margin-top: 34px;
  display: flex;
  gap: 10px;
}

.blocktime {
  margin-top: 18px;
}

.daytime-card-field-container {
  flex: 1;
}

[dir="ltr"] .field-1 {
  margin-left: 32px;
  margin-right: 24px;
}

[dir="rtl"] .field-1 {
  margin-left: 0px;
  margin-right: 24px;
}

.card-form-sublabel {
  margin-bottom: 16px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c2e;
}

.card-form-label {
  margin-bottom: 16px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c2e;
}

.employment {
  margin-left: 20px;
}

@media (max-width: 768px) {
  .card-form-label {
    font-size: 16px;
    line-height: 20px;
  }

  .employment {
    margin-left: 40px;
  }
}

.daytime-form-input-style {
  display: block;
  width: 90%;
  min-width: 170px;
  height: 54px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 5px;
  font-family: "Poppins-Medium", sans-serif !important;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .daytime-form-input-style {
    width: 100%;
    min-width: auto;
    font-size: 16px;
    margin-top: 8px;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .daytime-form-input-style {
    width: 90%;
    min-width: auto;
    font-size: 15px;
    margin-top: 6px;
  }
}

.workingday-card-content {
  margin-top: 24;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myCustomButton {
  border: 0 !important;
  border-radius: 24px !important;
  width: 150px;
  height: 44px;
  font-size: 14px !important;
  font-weight: 500;
  text-transform: none !important;
  font-style: normal;
  line-height: 21px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}

.weekDays-map-container {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 32px;
}

.employess-weekdays-button {
  text-transform: none !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
}

/* Media query for screens with width less than 768px (mobile devices) */
@media (max-width: 767px) {
  .weekDays-map-container {
    margin-top: 8px;
    margin-left: 16px;
  }

  .employess-weekdays-button {
    font-size: 16px !important;
    min-width: 100px !important;
  }
}

/* Media query for screens with width between 768px and 1023px (iPad devices) */
@media (min-width: 768px) and (max-width: 1023px) {
  .weekDays-map-container {
    margin-top: 12px;
    margin-left: 24px;
  }

  .employess-weekdays-button {
    font-size: 17px !important;
    min-width: 110px !important;
  }
}

/* Default styles */
.allService-form-input-style {
  box-sizing: border-box;
  width: calc(100% - 60px);
  height: 54px;
  margin-top: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #ffffff !important;
  font-family: "Poppins-Medium", sans-serif !important;
}

[dir="ltr"] .allService-form-input-style {
  margin-right: 32px;
  margin-left: 0px;
}

[dir="rtl"] .allService-form-input-style {
  margin-left: 32px;
  margin-right: 0px;
}

/* Media query for screens with a maximum width of 768px, which includes tablets and small laptops */
@media (max-width: 768px) {
  .allService-form-input-style {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  [dir="ltr"] .allService-form-input-style {
    margin-right: 0px;
  }

  [dir="rtl"] .allService-form-input-style {
    margin-left: 0px;
  }
}

/* Media query for screens with a maximum width of 480px, which includes most mobile devices */
@media (max-width: 480px) {
  .allService-form-input-style {
    width: 100%;
    height: 48px;
    margin-bottom: 12px;
    font-size: 14px;
  }

  [dir="ltr"] .allService-form-input-style {
    margin-right: 0px;
  }

  [dir="rtl"] .allService-form-input-style {
    margin-left: 0px;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label-left {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  color: #2c2c2e;
}

.label-right {
  margin-left: auto;
}

.label-left {
  margin-right: auto;
}

.employess-search-service-input-style {
  margin-left: 48px !important;
  margin: auto !important;
  width: calc(100% - 64px);
  height: 54px !important;
  color: #757575 !important;
  border-radius: 24px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.employess-search-service-input {
  width: 100% !important;
  min-width: 450px !important;
  height: 54px !important;
  color: #757575 !important;
  border-radius: 24px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.servicelist-list-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll !important;
  height: 385px;
  width: 99%;
  scrollbar-width: thin;
  scrollbar-color: #ffffff #ffffff;
}

.servicelist-list-container::-webkit-scrollbar {
  width: 8px;
}

.servicelist-list-container::-webkit-scrollbar-track {
  background-color: #e5e5ea;
}

.servicelist-list-container::-webkit-scrollbar-thumb {
  background-color: #757575;
  border-radius: 4px;
}

.servicelist-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.servicelist-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 25px;
}

.employee-service-note {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #636366;
}

.employee-service-name-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #2c2c2e;
  width: 80%;
  margin-top: 11px;
  margin-bottom: 11px;
}

.employeelist-main-container {
  margin-left: 40px !important;
  display: flex;
  flex-direction: column !important;
  margin-right: 10px;
  margin-top: 30px;
  width: 100%;
}

.manage-booking-employee-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 640px;
  top: 115px;
  left: 580px;
  height: 96%;
  overflow-y: scroll;
  scrollbar-width: none !important;
  max-width: 100%;
}

.arabic-manage-booking-employee-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 640px;
  top: 115px;
  right: 580px;
  height: 96%;
  overflow-y: scroll;
  scrollbar-width: none !important;
  max-width: 100%;
}

.manage-booking-employee-content-container::-webkit-scrollbar,
.arabic-manage-booking-employee-content-container::-webkit-scrollbar {
  display: none !important;
}

@media (max-width: 767px) {
  .manage-booking-employee-content-container {
    max-width: 80%;
    top: 500px;
    left: 15%;
  }

  .arabic-manage-booking-employee-content-container {
    max-width: 80%;
    top: 500px;
    right: 15%;
  }
}

@media (min-width: 767px) and (max-width: 960px) {

  .manage-booking-employee-content-container {
    max-width: 80%;
    top: 500px;
    left: 15%;
  }

  .arabic-manage-booking-employee-content-container {
    max-width: 80%;
    top: 500px;
    right: 15%;
  }
}

.time-form-container {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  max-width: 71%;
  width: 100%;
}

.time-input-block,
.time-input-block-add {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 234px;
}

.employee-block-time-button-container {
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 55px;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.employee-block-button-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.employee-block-cancel-button-block {
  margin-left: 0px;
}

[dir="rtl"] .employee-block-cancel-button-block {
  margin-left: 44px;
}

.employee-block-save-button-block {
  margin-left: 24px;
}

[dir="rtl"] .employee-block-save-button-block {
  margin-left: 0px;
}

@media (max-width: 768px) {
  .employee-block-time-button-container {
    flex-direction: column;
    gap: 16px;
  }

  .employee-block-cancel-button-block,
  .employee-block-save-button-block {
    margin-left: 0px;
  }

  .time-form-container {
    flex-direction: column;
    gap: 16px;
  }

  .time-input-block,
  .time-input-block-add {
    width: 100%;
    min-width: 0;
  }
}

.employee-details-services-model {
  margin-top: 20px !important;
  display: flex !important;
  margin-left: 32px !important;
  margin-right: 40px !important;
  padding: 0px !important;
  max-width: 100% !important;
}

.employee-details-services-model h2{
  width: 100%;
}

[dir="ltr"] .MuiPickersCalendarHeader-iconButton {
  transform: scaleX(1);
}

[dir="rtl"] .MuiPickersCalendarHeader-iconButton {
  transform: scaleX(-1);
}