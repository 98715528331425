.fitness-main-div {
    background: #ffffff;
    background-blend-mode: normal;
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    border-radius: 16px;
    position: relative;
    width: 715px;
    cursor: pointer;
    margin-top: 40px;

    @media (max-width:845px) {
        width: 100%;
    }

    .beast-up-img {
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        width: 100%;
        height: auto;
        object-fit: cover;
        aspect-ratio: 16/9;

        @media (max-width:845px) {
            width: 100%;
        }
    }

    .fitness_logo-div {
        position: absolute;
        right: 0px;
        color: #f5f5f5;
        padding: 10px;
        font-size: 8px;
        background: #7073ff;
        border-bottom-left-radius: 12px;
        border-top-right-radius: 12px;
        display: flex;
        flex-wrap: wrap;

        h1 {
            height: 18px;
            color: #f5f5f5;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            text-align: center;
        }
    }

    .beast-up-details-div {
        padding: 2%;
        display: flex;
        width: 100%;

        h1 {
            font-family: Poppins-Medium, sans-serif;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            display: flex;
            flex-wrap: wrap;
            width: 82%;
        }
        p {
            border-radius: 14px;
            background: #7073ff;
            background-blend-mode: normal;
            color: #f5f5f5;
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            font-weight: 500;
            padding-right: 10px;
            padding-left: 10px;
            line-height: 2;
            max-width: 18%;
            width: max-content;
            height: max-content;
        }
    }

    .location-beast-div {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0% 2%;

        h1 {
            color: #757575;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
        }
    }

    .rating-details-div {
        display: flex;
        flex-wrap: wrap;
        width: 715px;
        padding: 18px;
        align-items: center;

        h1 {
            font-family: Poppins-Medium, sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
        }

        img {
            width: 18px;
            height: 18px;
        }

        p {
            color: #48484a;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
        }

        h4 {
            color: #757575;
            font-family: Poppins-Medium, sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            text-align: right;
            margin-left: auto;
        }
    }
}