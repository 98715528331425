.pl-8 {
  padding-left: 8px;
}

.card_title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 24px;
}

.main-header {
  max-width: 100%;
  max-height: 140px;
  flex-direction: row;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  background-color: #ffffff;
}

.header-logo-container {
  display: flex;
  align-items: center;
  height: max-content;
  width: max-content;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f8;
  height: 100vh !important;
}

.profile-container-web {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-Language-container {
  display: flex;
  background: #f2f2f2;
  padding: 2% 0px 0px 0px;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

#card-label {
  margin-bottom: 11px;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 36px;
  color: #48484a;
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  width: 512px;
  font-family: "Poppins-Medium", sans-serif !important;
}

/* Media query for screens smaller than 768px, such as mobile devices */
@media (max-width: 767px) {
  #card-label {
    font-size: 18px !important;
    width: auto;
  }
}

/* Media query for screens between 768px and 1024px, such as iPads */
@media (min-width: 768px) and (max-width: 1024px) {
  #card-label {
    font-size: 20px !important;
    width: 80%;
  }
}

.card-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-wrapper {
  border-radius: 16px;
  width: 559px;
  padding: 44px 64px;
  margin-bottom: 50px !important;
}

/* Media query for screens smaller than 768px, such as mobile devices */
@media (max-width: 767px) {
  .card-wrapper {
    width: 100%;
    padding: 24px;
  }
}

/* Media query for screens between 768px and 1024px, such as iPads */
@media (min-width: 768px) and (max-width: 1024px) {
  .card-wrapper {
    width: 100%;
    padding: 32px;
    margin-bottom: 40px !important;
  }
}

.hadding {
  margin: 48px 0 32px 0;
  display: flex;
  font-size: 24px;
}

.image-error {
  width: inherit;
  display: flex;
  color: red;
  font-size: 10px;
  font-weight: 500;
  margin-top: 16px;
}

.upload-lable {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}

.card-wrapper-web {
  border-radius: 16px;
  margin-bottom: 50px !important;
  margin-top: 48px;
}

#profile-icon {
  width: 160px;
  height: 160px;
  border-radius: 100%;
}

.modal-icon {
  width: 160px;
  height: 154.67px;
}

#profile-name {
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 24px;
  line-height: 36px !important;
  text-align: center;
  color: #48484a;
  margin-top: 24px;
  font-family: "Poppins-Medium", sans-serif !important;
}

/* Media query for screens smaller than 768px, such as mobile devices */
@media (max-width: 767px) {
  #profile-name {
    font-size: 20px;
    line-height: 30px;
  }
}

/* Media query for screens between 768px and 1024px, such as iPads */
@media (min-width: 768px) and (max-width: 1024px) {
  #profile-name {
    font-size: 22px;
    line-height: 33px;
  }
}

.active-btn {
  background-color: #1b5add;
  border-radius: 12px;
}

.upload-container {
  background-color: #f2f2f2;
  height: 180px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  height: 180px;
  width: inherit;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiFormControl-root {
  width: inherit !important;
  border: 1px solid #757575;
  border-radius: 8px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.labelupload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.labeluploaderror {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: red;
}

.view-profile-link {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-align: center !important;
  color: #48484a !important;
  margin-bottom: 42px;
  font-style: normal;
  font-family: "Poppins-Medium", sans-serif !important;
  text-decoration: none !important;
  cursor: pointer;
}

/* Media query for screens smaller than 768px, such as mobile devices */
@media (max-width: 767px) {
  .view-profile-link {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 24px;
  }
}

/* Media query for screens between 768px and 1024px, such as iPads */
@media (min-width: 768px) and (max-width: 1024px) {
  .view-profile-link {
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 36px;
  }
}

.btn-icon {
  margin-right: 12px;
  margin-left: 12px;
  background-color: #1e5fea !important;
  padding: 8px;
}

.profile-btn-icon {
  margin-right: 12px;
  background-color: #1e5fea !important;
  padding: 8px;
  height: 40px;
  width: 40px;
}

.btn-icon-web {
  margin: 0px 12px;
  background-color: #1b5add !important;
  padding: 8px;
}

.MuiAvatar-img {
  width: 20px !important;
  height: auto !important;
}

.btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 62px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 14px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .btn-wrapper {
    height: auto;
  }
}

.btn-wrapper-web {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 62px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
  padding: 0 15px;
  text-align: start;
  cursor: pointer;
}

.active-btn-web {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 62px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
  padding: 0 15px;
  cursor: pointer;
  background-color: #d0ddf8;
  border-radius: 12px;
  text-align: start;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
}

/* Media query for screens smaller than 768px, such as mobile devices */
@media (max-width: 767px) {
  .card {
    display: block;
    padding: 16px;
  }
}

.deactivate-icon {
  height: 35px;
  width: 24px;
}

.card-web {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding: 44px 40px;
  max-width: 350px;
  width: 100%;
}

.uploadImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
}

.uploadImage-wrapper-web {
  border-radius: 16px;
  width: 100%;
  padding: 56px 65px;
  margin-bottom: 50px !important;
}

.lable-text {
  display: flex;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 12px;
}

.submit-btn {
  background-color: #1e5fea !important;
  border-radius: 30px !important;
  padding: 15px !important;
  color: white !important;
  cursor: pointer !important;
  width: inherit !important;
  text-transform: none !important;
}

.cancel-btn {
  border: 1px solid black !important;
  border-radius: 30px !important;
  padding: 15px !important;
  cursor: pointer !important;
  width: inherit !important;
  text-transform: none !important;
}

@media (max-width: 768px) {
  .cancel-btn {
    margin-bottom: 10px !important;
  }
}

.logout {
  color: red !important;
}

.comment-container {
  width: inherit;
  margin-top: 32px;
  margin-bottom: 12px;
}

.btn-container {
  display: flex;
  justify-content: space-around;
  width: inherit;
  gap: 16px;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .btn-container {
    display: block;
  }
}

.uploadImage-container-web {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  max-width: 670px;
}

.card_language_left {
  border-radius: 16px;
  width: 390px;
  height: 499px;
  padding: 44px;
  margin-bottom: 5%;
}

.card_language_right {
  border-radius: 16px;
  width: 582px;
  padding: 44px 54px;
  max-width: 100%;
}

.btn {
  margin-bottom: 24px;
  padding: 11px 0 11px 20px;
}

.logout_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 427px;
  height: 500px;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 24px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logout_confirmation_text {
  margin-top: 24px;
  margin-bottom: 44px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.btn_modal {
  width: 100%;
  border-radius: 32px !important;
  margin-bottom: 16px !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 18px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
}

.btn-language-option {
  height: 110px;
  padding: 24px;
  border-radius: 9px;
  background: #f9f9f8;
  margin-bottom: 23px;
  cursor: pointer;
}

.btn-language-wrapper {
  width: 100%;
}

.active-btn {
  background: #1b5add;
  color: #ffffff;
}

.btn-language {
  width: 230px;
  height: 50px;
  border-radius: 25px !important;
}

.cancel {
  margin-right: 14px !important;
}

.profile-font {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px;
  color: #2c2c2e;
}

.profile-font-color {
  color: #ff453a !important;
}

profile-font-web {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-size: 16px !important;
  line-height: 24px;
  color: red !important;
}

.profilepage-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  /* margin-bottom: 20px !important; */
}

.profilepage-arrow {
  margin-left: auto;
  cursor: pointer;
}

.profilepage-arrow img {
  height: 20px;
  width: 20px;
  margin-left: 5px;
  vertical-align: middle;
}

.profile-container-option {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 768px) {
  .profile-container-option {
    /* flex-direction: column; */
    align-items: flex-start;
  }
}

.profile-container-label-right {
  margin-left: 270px !important;
}

@media screen and (max-width: 768px) {
  .profile-container-label-right {
    margin-left: 200px !important;
  }
}

@media screen and (max-width: 576px) {
  .profile-container-label-right {
    margin-left: 0 !important;
  }
}

.circle-image {
  width: 160px !important;
  height: 160px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
  object-position: center !important;
}

.notification_ask_heading_text {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  color: #2c2c2e;
}

.notification_ask_body_text {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  color: #2c2c2e;
}

.Toastify__toast {
  direction: inherit !important;
}

.arabic-main-profile-div .my_profile_div h1,
.profile-header h1,
.arabic-whole-main-div .contact-div .contact-titile,
.arabic-whole-main-div .contact-div .setting-div h2,
.arabic-whole-main-div .contact-div .setting-div .contact-email-div h1,
.arabic-whole-main-div .contact-div .setting-div .contact-email-div p {
  font-family: "Poppins-Medium", sans-serif !important;
}

.hiperlink {
  cursor: pointer !important;
}

@media (max-width: 767px) {
  .subheader-container {
    align-items: center !important;
  }
}

@media screen and (max-width: 430px) {
  .autosuggest-container input {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1148px) {
  .btn-icon-web {
    width: 35px !important;
    height: 35px !important;
  }

  .active-btn-web,
  .btn-wrapper-web {
    padding: 0px !important;
    font-size: 14px !important;
  }
}