/* Default styles */
.walkin-dialog-container {
    width: 569px;
    height: 456px;
    background: #FFFFFF;
    border-radius: 16px;
  }

  .otp-dialog-container {
    width: 569px;
    height: 500pxpx;
    background: #FFFFFF;
    border-radius: 16px;
  }

  .walkin-dialog-Client-Details {
    width: 582px !important;
    height: 1064px !important;
    background: #FFFFFF;
    border-radius: 16px;
  }
  
  .walkin-dialog-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .walkin-form-title {
    font-family: "Poppins-Medium", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #2C2C2E;
    margin-top: 10px; 
    margin-left: 25px !important;
  }
  
  .walkin-dialog-title-text {
    font-weight: bold;
    margin-right: 24px;
  }
  
  .walkin-dialog-close-icon {
    cursor: pointer;
    margin-left: auto;
    margin-top: 20px;
    margin-right: 32px;
  }
  
  .walkin-dialog-content {
    display: flex;
    justify-content: center !important;
  }
  
  .walkin-dialog-textfield {
    margin-bottom: 16px;
  }
  
  .walkin-dialog-actions {
    display: flex;
    justify-content: center !important;

}
.button-name{
  font-size: 18px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-weight: 500 !important; 
}
  
  .walkin-dialog-button {
    width: 380px !important;
    height: 56px !important;
    font-weight: 500 !important; 
    font-family: "Poppins-Medium", sans-serif !important;
    font-size: 18px !important;
    border-radius: 24px !important;
    margin-bottom: 25px !important;
    text-transform: none !important;
    color: #FFFFFF;
    background-color: #1e5fea !important;
  }
  .walkin-dialog-buttons {
    width: 380px !important;
    height: 56px !important;
    font-weight: 500 !important; 
    font-family: "Poppins-Medium", sans-serif !important;
    font-size: 18px !important;
    border-radius: 24px !important;
    margin-bottom: 25px !important;
    text-transform: none !important;
    color: #FFFFFF;
    background-color: #1e5fea !important;
  }
  .home-button{
    background-color: #FFFFFF !important;
    color: #2C2C2E !important;
    font-weight: 500 !important;
    border: 1px solid #757575 !important;
    font-family: "Poppins-Medium", sans-serif !important;
  }

  .walkin-dialog-button-assign {
    width: 380px !important;
    height: 56px !important;
    font-weight: 500 !important; 
    font-family: "Poppins-Medium", sans-serif !important;
    font-size: 18px !important;
    border-radius: 24px !important;
    text-transform: none !important;
    color: #FFFFFF;
    background-color: #1e5fea !important;
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    /* Styles for screens with a maximum width of 600px */
    .walkin-dialog-container {
      width: 100%;
      height: 100%;
    }
  }
  .walkin-parent-container {
    display: flex;
    /* align-items: center; */
    justify-content: center !important;
  }
  .walkin-form {
    display: flex;
    justify-content: center !important;
    font-family: "Poppins-Medium", sans-serif !important;
    font-weight: 500;
    color: #2C2C2E;
  }
  .title{
    font-size: 24px;
    margin-bottom: 34px !important;
  } 
  .subtitle {
    justify-content: left !important;
    font-size: 18px;
    margin-bottom: 10px !important;
  }
  
  .arabic_subtitle {
    justify-content: right !important;
    font-size: 18px;
    margin-bottom: 10px !important;
  }
  .walkin-form-input-style {
    width: 433px;
    height: 47px;
    border-radius: 8px !important;
    font-family: "Poppins-Medium", sans-serif !important;
  }
  .walkin-country-code-text-style {
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2c2c2e !important;
  }
  .walkin-input-container{
    display: flex;
    margin-top: 15px;
    margin-left: 40px;
    margin-right: 40px;
    flex-direction: column;
  }
  .walkin-dialog-label {
    font-family: "Poppins-Medium", sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .walkin-dialog-note {
    color: #999999;
    font-family: "Poppins-Medium", sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .walkin-dialog-input-style{
    width: 476px;
    height: 54px;
    border-radius: 8px !important;
    font-family: "Poppins-Medium", sans-serif !important;
  }

  .unclickable {
    pointer-events: none;
  }


  .walkin-client-details-dialog-button {
    width: 400px;
    height: 56px;
    border-radius: 27px !important;
    text-transform: none !important;
    margin-bottom: 30px !important;
    font-size: 18px !important;
    font-family: "Poppins-Medium", sans-serif !important;
  }
  
  .edit-button {
    background-color: #FFFFFF !important;
    color: #2C2C2E !important;
    border: 1px solid #757575 !important;
    /* Add any other styles for the Edit button */
  }
  
  .continue-button {
    /* Styles specific to the Continue button */
    background-color:#1e5fea !important;
    color: #FFFFFF !important;
    /* Add any other styles for the Continue button */
  }

  .walkin-otp-input-container {
    width: 100%;
    max-width: 100%;
    display: flex;
    /* margin-left: 60px !important; */
    flex-direction: row;
    justify-content: left;
  }

  .walkin-suceess-message-text {
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #34C759;
    margin-top: 8px;
  }

  .walkin-error-message-text{
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #FF453A;
    margin-top: 12px;
    margin-left: 40px !important;
    margin-top: 8px !important;
  }

  .arabic-walkin-error-message-text{
    font-family: "Poppins-Medium", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #FF453A;
    margin-top: 12px;
    margin-right: 40px !important;
    margin-top: 8px !important;
  }

@media only screen and (min-width:2000px)and (max-width :3000px) {
  .constainer01 .header .search_bar_div input::placeholder {
    font-size: 16px !important;
    padding-left: 10px;
    padding-top: 3px;
  }
}

[dir="ltr"] .subcription-payment-card {
  padding: 0px 0px 0px 16px !important;
}

[dir="rtl"] .subcription-payment-card {
  padding: 0px 16px 0px 0px !important;
}

.subcription-payment-card-details {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 70% !important;
}

.subcription-payment-card label {
  margin: 0 !important;
}


.walkin-dialog-client-details-employee {
  width: 582px !important;
  height: 650px !important;
  background: #ffffff;
  border-radius: 16px;
}

.walkin-input-container-client-details-employee {
  display: flex;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}

.walkin-error-message-text-client-details-employee{
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #FF453A;
  margin-top: 12px;
}

.arabic-walkin-error-message-text-client-details-employee{
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #FF453A;
  margin-top: 12px;
}