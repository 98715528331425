/* Default styles */
.walkin-dialog-container {
  width: 569px;
  height: 456px;
  background: #ffffff;
  border-radius: 16px;
}

.manage-employee-service-main-content-container {
  margin: 32px 44px 0px 44px;
}

.booking-papar-timer-container {
  height: max-content !important;
  border-radius: 16px;
}

.booking-Paper-right-notes-containers {
  display: flex;
  flex-direction: column;
  margin: 32px;
}

.business-walkin-booking-list-item {
  display: flex;
  /* padding: 10px; */
  border-radius: 5px;
  margin-top: 34px;
  margin-bottom: 20px !important;
}

@media screen and (max-width: 767px) {
  .business-walkin-booking-list-item {
    flex-wrap: wrap;
  }
  .booking-arrow {
    margin-top: 10px;
  }
}

.business-walkin-booking-list-item .booking-user-info {
  display: flex;
  flex-direction: column;
}

.business-walkin-booking-list-item h3 {
  margin: 0;
  color: #2c2c2e;
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
}

.business-walkin-booking-list-item p {
  margin: 0;
  color: #757575;
  font-size: 16px;
}

@media (min-width: 1024px) and (max-width: 1290px) {
  .manage-employee-service-main-content-container {
    margin: 28px 40px 0px 40px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .manage-employee-service-main-content-container {
    margin: 28px 30px 0px 30px;
  }
}

.otp-dialog-container {
  width: 569px;
  height: 500px;
  background: #ffffff;
  border-radius: 16px;
}

.walkin-dialog-Client-Details {
  width: 582px !important;
  height: 1064px !important;
  background: #ffffff;
  border-radius: 16px;
}

.walkin-dialog-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.walkin-form-title {
  font-family: "Poppins-Medium", sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #2c2c2e;
  margin-top: 10px;
  margin-left: 25px !important;
}

.walkin-dialog-title-text {
  font-weight: bold;
  margin-right: 24px;
}

.walkin-dialog-close-icon {
  cursor: pointer;
  margin-left: auto;
  margin-top: 20px;
  margin-right: 32px;
}

.walkin-dialog-content {
  display: flex;
  justify-content: center !important;
}

.walkin-dialog-textfield {
  margin-bottom: 16px;
}

.walkin-dialog-actions {
  display: flex;
  justify-content: center !important;
}

.walkin-dialog-button {
  width: 380px !important;
  height: 56px !important;
  font-weight: 500;
  font-family: "Poppins-Medium", sans-serif !important;
  font-size: 18px !important;
  border-radius: 24px !important;
  margin-bottom: 25px !important;
  text-transform: none !important;
  background-color: #1e5fea !important;
}

.booking-confirmed-modal-button {
  width: 380px !important;
  height: 56px !important;
  font-weight: 500 !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-size: 18px !important;
  border-radius: 28px !important;
  margin-top: 10px !important;
  text-transform: none !important;
  color: #ffffff !important;
  background-color: #1e5fea !important;
}

.booking-confirmed-back-to-home-button {
  width: 380px !important;
  height: 56px !important;
  font-weight: 500 !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-size: 18px !important;
  border-radius: 28px !important;
  margin-top: 10px !important;
  text-transform: none !important;
  color: #2c2c2e !important;
  background-color: #ffffff !important;
  border: 1px solid #2c2c2e !important;
}

.booking-business-booking-confirmed-modal {
  width: 480px !important;
  height: 480px !important;
  background: #ffffff;
  border-radius: 16px;
}

.assign-container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
}
/* Responsive styles */
@media (max-width: 600px) {
  /* Styles for screens with a maximum width of 600px */
  .walkin-dialog-container {
    width: 100%;
    height: 100%;
  }
  .business-walkin-booking-list-item p {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.walkin-parent-container {
  display: flex;
  /* align-items: center; */
  justify-content: center !important;
}
.walkin-form {
  display: flex;
  justify-content: center !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-weight: 500;
  color: #2c2c2e;
}
.title {
  font-size: 24px;
  margin-bottom: 34px !important;
}
.subtitle {
  justify-content: start !important;
  font-size: 18px;
  margin-bottom: 10px !important;
}

.arabic_subtitle {
  justify-content: right !important;
  font-size: 18px;
  margin-bottom: 10px !important;
}

@media screen and (max-width: 767px) {
  .MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    height: auto !important;
  }
}

.walkin-form-input-style {
  width: 100%;
  height: 47px;
  border-radius: 8px !important;
}
.walkin-country-code-text-style {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2e !important;
}
.walkin-input-container {
  display: flex;
  margin-top: 15px;
  margin-left: 40px;
  margin-right: 40px;
  flex-direction: column;
}
.walkin-dialog-label {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}
.walkin-dialog-note {
  color: #999999;
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
}

.walkin-dialog-input-style {
  width: 476px;
  height: 54px;
  border-radius: 8px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}

@media screen and (max-width: 767px) {
  .walkin-dialog-input-style {
    width: 100%;
  }
  .walkin-dialog-close-icon {
    margin-right: 5px;
  }
}

.walkin-client-details-dialog-button {
  width: 231px;
  height: 56px;
  border-radius: 27px !important;
  text-transform: none !important;
  margin-bottom: 30px !important;
  font-size: 18px !important;
  font-family: "Poppins-Medium", sans-serif !important;
}

.edit-button {
  background-color: #ffffff !important;
  color: #2c2c2e !important;
  border: 1px solid #757575 !important;
  /* Add any other styles for the Edit button */
}

.continue-button {
  /* Styles specific to the Continue button */
  background-color: #1e5fea !important;
  color: #ffffff !important;
  /* Add any other styles for the Continue button */
}

.walkin-otp-input-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  /* margin-left: 60px !important; */
  flex-direction: row;
  justify-content: left;
}

.otp-input-container .react-code-input {
  display: flex !important;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.react-code-input {
  direction: ltr !important;
}

.walkin-suceess-message-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #34c759;
  margin-top: 12px;
}

.business-walkin-error-message-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ff453a;
  /* margin-left: 40px !important; */
  margin-top: 8px !important;
}

@media (max-width: 1023px) {
  .booking-main-container {
    flex-direction: column;
    width: 100%;
  }
}

.review-success-modal-manage-business {
  width: 414px !important;
  height: 444px !important;
  background: #ffffff;
  border-radius: 16px;
}

.request-demo-success-modal-manage-business {
  width: 300px !important;
  height: 300px !important;
  background: #ffffff;
  border-radius: 16px;
}

.review-success-modal-success-lable {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 24px;
  color: #2c2c2e;
}

.review-success-modal-already-submitted-lable {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  color: #2c2c2e;
}

.review-success-modal-sublable {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  color: #2c2c2e;
}

.review-success-modal-already-sbumitted-button {
  width: 380px !important;
  height: 56px !important;
  font-weight: 500 !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-size: 18px !important;
  border-radius: 28px !important;
  margin-top: 90px !important;
  text-transform: none !important;
  color: #ffffff !important;
  background-color: #1e5fea !important;
}

.review-success-modal-button {
  width: 380px !important;
  height: 56px !important;
  font-weight: 500 !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-size: 18px !important;
  border-radius: 28px !important;
  margin-top: 40px !important;
  text-transform: none !important;
  color: #ffffff !important;
  background-color: #1e5fea !important;
}

.walkin-otp-input-container .profile-error-message-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #ff453a;
  max-width: 100%;
  width: 310px;
  margin: auto;
}

[dir="ltr"] .walkin-booking-input-arrow-icon,
[dir="ltr"] .request-demo-modal-header span img,
[dir="ltr"] .left-arrow-image-container img,
[dir="ltr"] .content-containers img,
[dir="ltr"] .mobile-number-modal-header span img,
[dir="ltr"] #back,
[dir="ltr"] #team_working_back_img,
[dir="ltr"] #team-working-days-back-image,
[dir="ltr"] #load_api_img,
[dir="ltr"] .arrow img,
[dir="ltr"] .profilepage-arrow img,
[dir="ltr"] #removeImage,
[dir="ltr"] #add-edit-back-button,
[dir="ltr"] #detail-back-button,
[dir="ltr"] .business-holiday-image-container img,
[dir="ltr"] .DatePicker_buttonWrapper__3HD0q,
[dir="ltr"] #reschedule_modal_business_calendar_icon {
  transform: scaleX(1) !important;
}

[dir="rtl"] .walkin-booking-input-arrow-icon,
[dir="rtl"] .request-demo-modal-header span img,
[dir="rtl"] .left-arrow-image-container img,
[dir="rtl"] .content-containers img,
[dir="rtl"] .mobile-number-modal-header span img,
[dir="rtl"] #back,
[dir="rtl"] #team_working_back_img,
[dir="rtl"] #team-working-days-back-image,
[dir="rtl"] #load_api_img,
[dir="rtl"] .arrow img,
[dir="rtl"] .profilepage-arrow img,
[dir="rtl"] #removeImage,
[dir="rtl"] #add-edit-back-button,
[dir="rtl"] #detail-back-button,
[dir="rtl"] .business-holiday-image-container img,
[dir="rtl"] .DatePicker_buttonWrapper__3HD0q,
[dir="rtl"] #reschedule_modal_business_calendar_icon {
  transform: scaleX(-1) !important;
}

[dir="ltr"] .continue-button-disable,
[dir="ltr"] .continue-button-enable {
  margin-left: 27px;
}

[dir="rtl"] .continue-button-disable,
[dir="rtl"] .continue-button-enable {
  margin-right: 27px !important;
  margin-left: 0px !important;
}

[dir="rtl"] .manage-booking-cancellation-container img{
  margin-left: 10px;
}


[dir="ltr"] .manage-booking-cancellation-container img{
  margin-right: 10px;
}

.walkin-dialog-client-details-business {
  width: 582px !important;
  height: 650px !important;
  background: #ffffff;
  border-radius: 16px;
}

.walkin-input-container-client-details-business {
  display: flex;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}

.business-whole-main-div .contact-div .setting-div .contact-email-div {
  justify-content: center;
}