.manage-employee-main-container {
  position: relative;
}

@media screen and (max-width: 1024px) {
  .manage-employee-main-container {
    /* padding: 16px; */
    overflow-y: scroll;
  }
}

@media screen and (max-width: 767px) {
  .manage-employee-main-container {
    /* padding: 12px; */
    overflow-y: scroll;
  }
}

@media screen and (max-width: 480px) {
  .manage-employee-main-container {
    /* padding: 8px; */
    overflow-y: scroll;
  }
}

.manage-employee-content-container {
  position: absolute;
  width: 100%;
  max-width: 700px;
  top: 115px;
  left: 580px;
  height: 83vh;
  overflow-y: scroll;
}

.manage-employee-content-container-employee-flow {
  position: absolute;
  width: 100%;
  max-width: 700px;
  top: 115px;
  left: 580px;
  height: 83vh;
  overflow-y: scroll;
}

.arabic-manage-employee-content-container {
  position: absolute;
  width: 100%;
  max-width: 700px;
  top: 115px;
  right: 580px;
  height: 83vh;
  overflow-y: scroll;
}

.arabic-manage-employee-content-container-employee-flow {
  position: absolute;
  width: 100%;
  max-width: 700px;
  top: 115px;
  right: 580px;
  height: 83vh;
  overflow-y: scroll;
}

@media screen and (max-width: 1024px) {
  .manage-employee-content-container {
    width: 70%;
    left: 20%;
    height: 100%;
    overflow: hidden;
    top: 805px !important;
    bottom: 0;
  }

  .manage-employee-content-container-employee-flow {
    width: 70%;
    left: 15%;
    height: 100%;
    overflow: hidden;
    top: 500px !important;
    bottom: 0;
  }

  .arabic-manage-employee-content-container {
    width: 70%;
    right: 20%;
    height: 100%;
    overflow: hidden;
    top: 805px !important;
    bottom: 0;
  }

  .arabic-manage-employee-content-container-employee-flow {
    width: 70%;
    right: 15%;
    height: 100%;
    overflow: hidden;
    top: 500px !important;
    bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .manage-employee-content-container {
    width: 80%;
    left: 15%;
    overflow: hidden;
    top: 805px !important;
    height: 100%;
    bottom: 0;
    margin-top: 0 !important;
  }

  .arabic-manage-employee-content-container {
    width: 80%;
    right: 15%;
    overflow: hidden;
    top: 805px !important;
    height: 100%;
    bottom: 0;
    margin-top: 0 !important;
  }

  .manage-employee-content-container-employee-flow {
    width: 80%;
    left: 15%;
    overflow: hidden;
    top: 500px !important;
    height: 100%;
    bottom: 0;
    margin-top: 0 !important;
  }

  .arabic-manage-employee-content-container-employee-flow {
    width: 80%;
    right: 15%;
    overflow: hidden;
    top: 500px !important;
    height: 100%;
    bottom: 0;
    margin-top: 0 !important;
  }
}

.manage-employee-heading-container {
  /* background: #F9F9F8 !important; */
  display: flex;
  margin-top: 4px !important;
}

@media screen and (min-width: 768px) {
  .manage-employee-heading-container {
    margin-top: 16px !important;
  }
}

.employee-back-icon {
  padding-right: 22px !important;
  flex-direction: row !important;
}

@media screen and (max-width: 767px) {
  .employee-back-icon {
    padding-right: 0 !important;
    margin-bottom: 16px !important;
  }
}

.manage-employee-header-text {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px;
  line-height: 30px !important;
  color: #2c2c2e !important;
  margin-left: 12px !important;
}

@media screen and (max-width: 767px) {
  .manage-employee-header-text {
    font-size: 20px;
    line-height: 26px !important;
    margin-left: 8px !important;
  }
}

@media screen and (max-width: 480px) {
  .manage-employee-header-text {
    font-size: 16px;
    line-height: 20px !important;
    margin-left: 4px !important;
  }
}

.manage-employee-list-main-container {
  background-color: #ffffff;
  width: 402px;
  max-height: 723px;
  border-radius: 16px;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .manage-employee-list-main-container {
    width: 100%;
    max-height: none;
  }
}

.manage-employee-list-heading-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 30px 24px 0px 24px;
}

@media screen and (max-width: 767px) {
  .manage-employee-list-heading-container {
    padding-left: 16px !important;
    padding-top: 16px;
    padding-right: 16px !important;
  }
}

.manage-employee-list-heading-text {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #48484a;
}

.manage-employee-services-list-item-container {
  display: flex;
  width: 90%;
  padding-top: 25px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.manage-employee-services-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 67px;
  width: 100%;
  margin-bottom: 14px;
  padding: 0px 16px;
  cursor: pointer;
}
.active-manage-employee-services-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 67px;
  width: 100%;
  margin-bottom: 14px;
  padding: 0px 16px;
  cursor: pointer;
  background: #1b5add !important;
}

.manage-employee-services-list-details-container {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.manage-employee-services-list-item-heading {
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2e;
}

.manage-employee-services-list-item-details {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #636366;
}

.manage-employee-services-list-item-arrow-image-container {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-employee-services-list-item-arrow-image {
  width: 16px;
  height: 16px;
}

[dir="ltr"] .manage-employee-services-list-item-arrow-image , .profilepage-arrow img{
  transform: scaleX(1);
}

[dir="rtl"] .manage-employee-services-list-item-arrow-image , .profilepage-arrow img{
  transform: scaleX(-1);
}



.employee-card-container {
  display: flex;
  margin-top: 24px !important;
  flex-direction: column;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .employee-card-container {
    margin-top: 12px !important;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 480px) {
  .employee-card-container {
    margin-top: 8px !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.employee-card {
  width: 100%;
  height: auto !important;
  background: #ffffff;
  margin-bottom: 40px !important;
  border-radius: 16px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .employee-card {
    width: 100%;
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 480px) {
  .employee-card {
    margin-bottom: 16px !important;
  }
}

.block-days-time-container {
  display: flex;
  margin: 32px 24px 20px 24px;
  flex-direction: column;
  width: 80%;
}

@media screen and (max-width: 767px) {
  .block-days-time-container {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media screen and (max-width: 480px) {
  .block-days-time-container {
    margin-left: 8px;
    margin-right: 8px;
  }
}

/* styles for screens larger than 768px */
.card-box-class {
  width: 100%;
  min-height: 134px;
  background-color: #f9f9f8 !important;
  border: none;
  box-shadow: none;
  margin-bottom: 16px;
  height: auto;
}

.business-holiday-card-box-class {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3%;
  height: max-content;
  background-color: #f9f9f8 !important;
  border: none;
  box-shadow: none;
  margin-bottom: 16px;
  cursor: pointer;
}

/* styles for screens smaller than 768px */
@media (max-width: 768px) {
  .card-box-class {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.block-days-time-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 55px !important;
}

.block-days-time-Add-button {
  top: 80% !important;
  width: 492px;
  max-width: 80% !important;
  height: 54px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  text-transform: none !important;
  background-color: #1e5fea !important;
  border-radius: 25px !important;
  font-family: "Poppins-Medium", sans-serif;
}

@media only screen and (max-width: 768px) {
  .block-days-time-Add-button {
    top: 80% !important;
    width: 100%;
    max-width: 80% !important;
    height: 54px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    text-transform: none !important;
    background-color: #1e5fea !important;
    border-radius: 25px !important;
    font-family: "Poppins-Medium", sans-serif;
  }
}

.employee-service-name {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  display: flex;
  align-items: center;
  color: #2c2c2e !important;
}

.employee-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .employee-container {
    align-items: flex-start;
  }
}

.employee-label-left {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #757575;
}

@media (max-width: 768px) {
  .employee-label-left {
    font-size: 12px;
    line-height: 16px;
  }
}

.employee-label-right {
  margin-left: auto;
}

.employee-add-block-my-time-label {
  margin-top: 32px;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c2e;
}

/* Mobile devices */
@media only screen and (max-width: 767px) {
  .employee-add-block-my-time-label {
    font-size: 16px;
    line-height: 20px;
  }
}

/* iPad devices */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .employee-add-block-my-time-label {
    font-size: 18px;
    line-height: 22px;
  }
}

/* Desktop devices */
@media only screen and (min-width: 1024px) {
  .employee-add-block-my-time-label {
    font-size: 18px;
    line-height: 22px;
  }
}

.employee-add-block-my-time-sublabel {
  margin-bottom: 12px;
  font-family: "Poppins-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #636366;
}

/* Apply different styles for mobile screens */
@media only screen and (max-width: 767px) {
  .employee-add-block-my-time-sublabel {
    font-size: 14px;
    line-height: 16px;
  }
}

/* Apply different styles for iPad screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .employee-add-block-my-time-sublabel {
    font-size: 15px;
    line-height: 17px;
  }
}

/* Apply different styles for desktop screens */
@media only screen and (min-width: 1024px) {
  .employee-add-block-my-time-sublabel {
    font-size: 16px;
    line-height: 18px;
  }
}
.employe-description-lable {
  display: flex;
  flex-direction: column;
  margin-left: 44px;
}

/* Apply different styles for mobile screens */
@media only screen and (max-width: 767px) {
  .employe-description-lable {
    margin-left: 0;
  }
}

/* Apply different styles for tablet and desktop screens */
@media only screen and (min-width: 768px) {
  .employe-description-lable {
    margin-left: 44px;
  }
}

.employee-add-block-my-time-button {
  height: 56px !important;
  border-radius: 28px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 18px;
  text-align: center;
  text-transform: none !important;
}
.delete {
  width: 239px !important;
  color: #ffffff !important;
  background: #ff453a !important;
}
.edit {
  width: 239px !important;
  color: #ffffff !important;
  background-color: #1e5fea !important;
}
.save {
  width: 239px !important;
  color: #ffffff !important;
  background-color: #1e5fea !important;
}

.cancel {
  width: 239px !important;
  color: #2c2c2e !important;
  background-color: #ffffff !important;
  border: 1px solid #2c2c2e !important;
}
.noButton {
  width: 347px !important;
  height: 52px !important;
  margin: "0 20px";
  color: #ffffff !important;
  background-color: #1e5fea !important;
  border-radius: 32px !important;
}
.yesButton {
  width: 347px !important;
  height: 52px !important;
  margin: "0 20px";
  background: #ffffff !important;
  border: 1px solid #ff453a !important;
  color: #ff453a !important;
  border-radius: 32px !important;
}

.employee-alert-dialog {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.employee-alert-dialog-icon {
  margin-top: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.employee-alert-dialog-icon img {
  display: block !important; /* add this to remove extra space under the image */
}

.font-family {
  font-family: "Poppins-Medium", sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #2c2c2e;
}

.employee-alert-dialog-text-value {
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #2c2c2e !important;
}

.employee-alert-dialog-text {
  margin-top: 24px !important;
  display: flex;
  font-family: "Poppins-Medium", sans-serif !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #2c2c2e !important;
}
.employee-alert-dialog-buttons {
  justify-content: "space-between" !important;
  gap: 16px;
  margin-right: 40px !important;
  margin-left: 40px !important;
  margin-top: 44px !important;
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

option {
  color: inherit !important;
}

.manage-employee-error-message-text {
  position: relative !important;
  font-family: "Poppins-Medium", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ff453a;
  margin-top: 12px;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  margin-bottom: 20px !important;
}

.list-item img {
  width: 56px;
  height: 56px;
  margin-right: 30px;
  margin-left: 30px;
  border-radius: 50%;
}

.list-item .user-info {
  display: flex;
  flex-direction: column;
}

.list-item .user-info .available_in {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #ff9f0a;
}

.list-item h3 {
  margin: 0;
  color: #2c2c2e;
  font-size: 20px;
}

.list-item p {
  margin: 0;
  color: #757575;
  font-size: 16px;
}

.arrow {
  cursor: pointer;
}

.arrow img {
  height: 20px;
  width: 20px;
  margin-left: 5px;
  vertical-align: middle;
}

.employee-search-textfield {
  margin-top: 40px !important;
  margin-left: 20px !important;
  display: flex;
  align-items: center;
  width: 100%;
}

.my-autocomplete .MuiAutocomplete-input {
  font-family: "Poppins-Medium", sans-serif;
  direction: inherit;
}

.my-autocomplete .MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.my-autocomplete .MuiAutocomplete-clearIndicator {
  display: none;
}

.my-autocomplete .MuiAutocomplete-option {
  font-family: "Poppins-Medium", sans-serif;
}
.employee-home-screen-sidebar {
  width: 80px;
  height: 100vh;
  background-color: #3d40c3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.employee-home-screen-sidebar-image {
  width: 28px !important;
  height: 28px !important;
  margin-top: 48px;
}

@media (max-width: 768px) {
  .employee-home-screen-sidebar {
    width: 60px;
    height: 200vh;
  }

  .employee-home-screen-sidebar-image {
    width: 24px;
    height: 24px;
    margin-top: 24px;
  }
}

@media (max-width: 480px) {
  .employee-home-screen-sidebar {
    width: 40px;
  }

  .employee-home-screen-sidebar-image {
    width: 20px;
    height: 20px;
    margin-top: 16px;
  }
}

.manage-employee-service-main-content-container {
  margin: 32px 44px 0px 44px;
}

@media (min-width: 1024px) and (max-width: 1290px) {
  .manage-employee-service-main-content-container {
    margin: 28px 40px 0px 40px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .manage-employee-service-main-content-container {
    margin: 28px 30px 0px 30px;
  }
}
.flex-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .flex-container {
    gap: 2rem;
    margin-top: 2rem;
  }
}

.flex-left-sub-container {
  display: flex;
  flex-direction: column;
  margin-left: 44px;
}

@media only screen and (max-width: 600px) {
  .flex-left-sub-container {
    margin: 0;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .flex-left-sub-container {
    margin-left: 24px;
  }
}

.flex-right-sub-container {
  display: flex !important;
  flex-direction: column;
  margin-right: 44px;
}

@media only screen and (max-width: 768px) {
  .flex-left-sub-container,
  .flex-right-sub-container {
    margin: 0;
  }

  .flex-left-sub-container > *,
  .flex-right-sub-container > * {
    margin-top: 16px;
  }

  .flex-left-sub-container > *:first-child,
  .flex-right-sub-container > *:first-child {
    margin-top: 0;
  }
}

.employe-card-description {
  width: 492px;
  height: 54px;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  border-radius: 8px !important;
}

@media only screen and (max-width: 768px) {
  .employe-card-description {
    width: 100%;
  }
}

.business-holiday-arrow {
  height: 24px;
  width: 24px;
}

.business-holiday-details-container {
  width: 90%;
  height: 100%;
}

.business-holiday-image-container {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.business-holiday-description-text {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  color: #2c2c2e;
  font-style: normal;
  font-weight: 500;
  margin: 2% 0%;
}

.business-holiday-date-text {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  color: #757575;
  font-style: normal;
  font-weight: 500;
  margin: 2% 0%;
  width: max-content;
  direction: ltr;
}

.delete-holiday {
  background-color: #ff453a !important;
  border: none !important;
  color: #ffffff !important;
}
