.manage-business-holiday-main-container {
  position: relative;
  overflow: auto;
  background-color: #f2f2f2;

  .manage-business-holiday-content-container {
    position: absolute;
    width: 700px;
    top: 115px;
    left: 580px;
    height: 83vh;
    overflow-y: scroll;
    max-width: 100%;
    scrollbar-width: none !important;

    @media (min-width: 960px) and (max-width: 1290px) {
      width: 520px;
      left: 480px;
    }

    @media (min-width: 768px) and (max-width: 960px) {
      left: 105px;
      top: 805px;
    }

    @media screen and (max-width: 767px) {
      left: 50px;
      top: 750px;
      width: 85%;
      margin-top: 60px;
    }

    .add-holiday-card-container {
      background-color: #ffffff;
      border-radius: 16px;
      max-width: 580px;
      padding: 3%;
      height: auto;
      margin-top: 2%;

      .react-calendar {
        width: 550px;
        border-radius: 24px;
        background: #ffffff;
        background-blend-mode: normal;
        border: none !important;

        .react-calendar__navigation:hover {
          background: #ffffff;
        }

        .react-calendar__navigation {
          width: 260px !important;
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 2% auto;

          .react-calendar__navigation__label {
            height: 46px;
            color: #2c2c2e;
            font-family: "Poppins-Medium", sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 40px;
            flex-grow: 0 !important;
          }

          button:disabled {
            background-color: #ffffff !important;
          }

          .react-calendar__navigation__prev2-button {
            display: none;
          }

          .react-calendar__navigation__next2-button {
            display: none;
          }
        }

        .react-calendar__viewContainer {
          .react-calendar__month-view {
            padding-left: 20px;
            padding-right: 20px;

            .react-calendar__month-view__weekdays {
              abbr {
                text-decoration: none !important;
                color: #48484a;
                font-size: 16px;
                font-weight: 400;
                line-height: 14px;
                text-align: center;
                font-family: "Poppins", sans-serif;
                text-transform: uppercase;

                @media (max-width: 590px) {
                  font-size: 12px;
                }
              }
            }

            .react-calendar__month-view__days {
              .current-date-highlight {
                abbr {
                  text-decoration: underline;
                  text-underline-position: under;
                  font-size: 18px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;

                  @media (max-width: 590px) {
                    font-size: 14px;
                  }
                }
              }

              .disable-date-highlight {
                pointer-events: none;

                abbr {
                  background: #d8d8d8 !important;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;
                  color: #48484a !important;
                  font-weight: 600;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 5px 7px;
                    font-size: 14px;
                  }
                }
              }

              .react-calendar__tile:disabled {
                background-color: #ffffff !important;

                abbr:hover {
                  background: #ffffff !important;
                  font-size: 18px;
                  padding: 25px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;
                  color: #75757599 !important;
                  font-weight: 500;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 9px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    font-size: 14px;
                  }
                }

                abbr {
                  background: #ffffff !important;
                  font-size: 18px;
                  padding: 25px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;
                  color: #75757599 !important;
                  font-weight: 500;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 9px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    font-size: 14px;
                  }
                }
              }

              abbr {
                height: 58px;
                color: #48484a;
                font-size: 18px;
                font-weight: 400;
                line-height: 40px;
                text-align: center;

                @media (max-width: 590px) {
                  font-size: 14px;
                }
              }

              .react-calendar__tile {
                border-radius: 50%;
                max-width: 86px;
                background: #ffffff;
              }

              .react-calendar__tile:hover {
                border-radius: 50%;
                max-width: 86px;
                background: #ffffff;
              }

              .react-calendar__tile {
                abbr:hover {
                  color: #ffffff;
                  background: #1b5add;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;

                  @media (max-width: 590px) {
                    padding: 4px 6px;
                    font-size: 14px;
                  }
                }
              }

              .react-calendar__tile--active {
                background: #ffffff;
                border-radius: 50%;
                max-width: 86px;

                // margin-left: 12px;
                abbr {
                  color: #ffffff;
                  background: #1b5add;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;
                  @media (max-width: 590px) {
                    padding: 5px 7px;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }

      .business-holiday-description-input {
        width: 100%;
        border-radius: 8px;
        font-family: "Poppins-Medium", sans-serif;
        margin: 2% 0% 1% 0%;
      }

      .manage-business-holiday-buttons-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 3%;

        .manage-business-holiday-cancel-button {
          width: 48%;
          height: 50px;
          border-radius: 25px;
          border: 1px solid #2c2c2e;
          text-transform: capitalize;
          color: #2c2c2e;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
        }

        .manage-business-holiday-save-button {
          width: 48%;
          text-transform: capitalize;
          height: 50px;
          border-radius: 25px;
          background: #1e5fea;
          color: #ffffff;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
        }
      }
    }

    .edit-view-holiday-card-container {
      background-color: #ffffff;
      border-radius: 16px;
      max-width: 580px;
      padding: 3%;
      height: auto;
      margin-top: 2%;

      .react-calendar {
        width: 550px;
        border-radius: 24px;
        background: #ffffff;
        background-blend-mode: normal;
        border: none !important;

        .react-calendar__navigation:hover {
          background: #ffffff;
        }

        .react-calendar__navigation {
          width: 260px !important;
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 2% auto;

          .react-calendar__navigation__label {
            height: 46px;
            color: #2c2c2e;
            font-family: "Poppins-Medium", sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 40px;
            flex-grow: 0 !important;
          }

          button:disabled {
            background-color: #ffffff !important;
          }

          .react-calendar__navigation__prev2-button {
            display: none;
          }

          .react-calendar__navigation__next2-button {
            display: none;
          }
        }

        .react-calendar__viewContainer {
          .react-calendar__month-view {
            padding-left: 20px;
            padding-right: 20px;

            .react-calendar__month-view__weekdays {
              abbr {
                text-decoration: none !important;
                color: #48484a;
                font-size: 16px;
                font-weight: 400;
                line-height: 14px;
                text-align: center;
                font-family: "Poppins", sans-serif;
                text-transform: uppercase;

                @media (max-width: 590px) {
                  font-size: 12px;
                }
              }
            }

            .react-calendar__month-view__days {
              .current-date-highlight {
                abbr {
                  text-decoration: underline;
                  text-underline-position: under;
                  font-size: 18px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;

                  @media (max-width: 590px) {
                    font-size: 14px;
                  }
                }
              }

              .disable-date-highlight {
                pointer-events: none;

                abbr {
                  background: #d8d8d8 !important;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;
                  color: #48484a !important;
                  font-weight: 600;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 5px 7px;
                    font-size: 14px;
                  }
                }
              }

              .react-calendar__tile:disabled {
                background-color: #ffffff !important;

                abbr:hover {
                  background: #ffffff !important;
                  font-size: 18px;
                  padding: 25px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;
                  color: #75757599 !important;
                  font-weight: 500;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 9px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    font-size: 14px;
                  }
                }

                abbr {
                  background: #ffffff !important;
                  font-size: 18px;
                  padding: 25px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;
                  color: #75757599 !important;
                  font-weight: 500;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 9px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    font-size: 14px;
                  }
                }
              }

              abbr {
                height: 58px;
                color: #48484a;
                font-size: 18px;
                font-weight: 400;
                line-height: 40px;
                text-align: center;

                @media (max-width: 590px) {
                  font-size: 14px;
                }
              }

              .react-calendar__tile {
                border-radius: 50%;
                max-width: 86px;
                background: #ffffff;
                pointer-events: none;
              }

              .react-calendar__tile:hover {
                border-radius: 50%;
                max-width: 86px;
                background: #ffffff;
              }

              .react-calendar__tile {
                abbr:hover {
                  color: #48484a;
                  background: transparent;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;

                  @media (max-width: 590px) {
                    padding: 4px 6px;
                    font-size: 14px;
                  }
                }
              }

              .react-calendar__tile--active {
                background: #ffffff;
                border-radius: 50%;
                max-width: 86px;

                // margin-left: 12px;
                abbr {
                  color: #ffffff;
                  background: #1b5add;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;
                  @media (max-width: 590px) {
                    padding: 5px 7px;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }

      .business-holiday-description-input {
        width: 100%;
        border-radius: 8px;
        font-family: "Poppins-Medium", sans-serif;
        margin: 2% 0% 1% 0%;
      }

      .manage-business-holiday-buttons-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 3%;

        .manage-business-holiday-cancel-button {
          width: 48%;
          height: 50px;
          border-radius: 25px;
          border: 1px solid #2c2c2e;
          text-transform: capitalize;
          color: #2c2c2e;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
        }

        .manage-business-holiday-save-button {
          width: 48%;
          text-transform: capitalize;
          height: 50px;
          border-radius: 25px;
          background: #1e5fea;
          color: #ffffff;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
        }
      }
    }
  }

  .manage-business-holiday-content-container::-webkit-scrollbar,
  .arabic-manage-business-holiday-content-container::-webkit-scrollbar {
    display: none !important;
  }

  .arabic-manage-business-holiday-content-container {
    position: absolute;
    width: 700px;
    top: 115px;
    right: 580px;
    height: 83vh;
    overflow-y: scroll;
    max-width: 100%;
    scrollbar-width: none !important;

    @media (min-width: 1024px) and (max-width: 1290px) {
      width: 520px;
      right: 480px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      width: 340px;
      right: 410px;
    }

    @media screen and (max-width: 767px) {
      right: 50px;
      top: 750px;
      width: 85%;
      margin-top: 60px;
    }

    .add-holiday-card-container {
      background-color: #ffffff;
      border-radius: 16px;
      max-width: 580px;
      padding: 3%;
      height: auto;
      margin-top: 2%;

      .react-calendar {
        width: 550px;
        border-radius: 24px;
        background: #ffffff;
        background-blend-mode: normal;
        border: none !important;

        .react-calendar__navigation:hover {
          background: #ffffff;
        }

        .react-calendar__navigation {
          width: 260px !important;
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 2% auto;

          .react-calendar__navigation__label {
            height: 46px;
            color: #2c2c2e;
            font-family: "Poppins-Medium", sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 40px;
            flex-grow: 0 !important;
          }

          button:disabled {
            background-color: #ffffff !important;
          }

          .react-calendar__navigation__prev2-button {
            display: none;
          }

          .react-calendar__navigation__next2-button {
            display: none;
          }
        }

        .react-calendar__viewContainer {
          .react-calendar__month-view {
            padding-left: 20px;
            padding-right: 20px;

            .react-calendar__month-view__weekdays {
              abbr {
                text-decoration: none !important;
                color: #48484a;
                font-size: 16px;
                font-weight: 400;
                line-height: 14px;
                text-align: center;
                font-family: "Poppins", sans-serif;
                text-transform: uppercase;

                @media (max-width: 590px) {
                  font-size: 12px;
                }
              }
            }

            .react-calendar__month-view__days {
              .current-date-highlight {
                abbr {
                  text-decoration: underline;
                  text-underline-position: under;
                  font-size: 18px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;

                  @media (max-width: 590px) {
                    font-size: 14px;
                  }
                }
              }

              .disable-date-highlight {
                pointer-events: none;

                abbr {
                  background: #d8d8d8 !important;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;
                  color: #48484a !important;
                  font-weight: 600;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 5px 7px;
                    font-size: 14px;
                  }
                }
              }

              .react-calendar__tile:disabled {
                background-color: #ffffff !important;

                abbr:hover {
                  background: #ffffff !important;
                  font-size: 18px;
                  padding: 25px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;
                  color: #75757599 !important;
                  font-weight: 500;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 9px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    font-size: 14px;
                  }
                }

                abbr {
                  background: #ffffff !important;
                  font-size: 18px;
                  padding: 25px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;
                  color: #75757599 !important;
                  font-weight: 500;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 9px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    font-size: 14px;
                  }
                }
              }

              abbr {
                height: 58px;
                color: #48484a;
                font-size: 18px;
                font-weight: 400;
                line-height: 40px;
                text-align: center;

                @media (max-width: 590px) {
                  font-size: 14px;
                }
              }

              .react-calendar__tile {
                border-radius: 50%;
                max-width: 86px;
                background: #ffffff;
              }

              .react-calendar__tile:hover {
                border-radius: 50%;
                max-width: 86px;
                background: #ffffff;
              }

              .react-calendar__tile {
                abbr:hover {
                  color: #ffffff;
                  background: #1b5add;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;

                  @media (max-width: 590px) {
                    padding: 4px 6px;
                    font-size: 14px;
                  }
                }
              }

              .react-calendar__tile--active {
                background: #ffffff;
                border-radius: 50%;
                max-width: 86px;

                // margin-left: 12px;
                abbr {
                  color: #ffffff;
                  background: #1b5add;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;
                  @media (max-width: 590px) {
                    padding: 5px 7px;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }

      .business-holiday-description-input {
        width: 100%;
        border-radius: 8px;
        font-family: "Poppins-Medium", sans-serif;
        margin: 2% 0% 1% 0%;
      }

      .manage-business-holiday-buttons-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 3%;

        .manage-business-holiday-cancel-button {
          width: 48%;
          height: 50px;
          border-radius: 25px;
          border: 1px solid #2c2c2e;
          text-transform: capitalize;
          color: #2c2c2e;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
        }

        .manage-business-holiday-save-button {
          width: 48%;
          text-transform: capitalize;
          height: 50px;
          border-radius: 25px;
          background: #1e5fea;
          color: #ffffff;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
        }
      }
    }

    .edit-view-holiday-card-container {
      background-color: #ffffff;
      border-radius: 16px;
      max-width: 580px;
      padding: 3%;
      height: auto;
      margin-top: 2%;

      .react-calendar {
        width: 550px;
        border-radius: 24px;
        background: #ffffff;
        background-blend-mode: normal;
        border: none !important;

        .react-calendar__navigation:hover {
          background: #ffffff;
        }

        .react-calendar__navigation {
          width: 260px !important;
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 2% auto;

          .react-calendar__navigation__label {
            height: 46px;
            color: #2c2c2e;
            font-family: "Poppins-Medium", sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 40px;
            flex-grow: 0 !important;
          }

          button:disabled {
            background-color: #ffffff !important;
          }

          .react-calendar__navigation__prev2-button {
            display: none;
          }

          .react-calendar__navigation__next2-button {
            display: none;
          }
        }

        .react-calendar__viewContainer {
          .react-calendar__month-view {
            padding-left: 20px;
            padding-right: 20px;

            .react-calendar__month-view__weekdays {
              abbr {
                text-decoration: none !important;
                color: #48484a;
                font-size: 16px;
                font-weight: 400;
                line-height: 14px;
                text-align: center;
                font-family: "Poppins", sans-serif;
                text-transform: uppercase;

                @media (max-width: 590px) {
                  font-size: 12px;
                }
              }
            }

            .react-calendar__month-view__days {
              .current-date-highlight {
                abbr {
                  text-decoration: underline;
                  text-underline-position: under;
                  font-size: 18px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;

                  @media (max-width: 590px) {
                    font-size: 14px;
                  }
                }
              }

              .disable-date-highlight {
                pointer-events: none;

                abbr {
                  background: #d8d8d8 !important;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;
                  color: #48484a !important;
                  font-weight: 600;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 5px 7px;
                    font-size: 14px;
                  }
                }
              }

              .react-calendar__tile:disabled {
                background-color: #ffffff !important;

                abbr:hover {
                  background: #ffffff !important;
                  font-size: 18px;
                  padding: 25px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;
                  color: #75757599 !important;
                  font-weight: 500;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 9px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    font-size: 14px;
                  }
                }

                abbr {
                  background: #ffffff !important;
                  font-size: 18px;
                  padding: 25px;
                  padding-top: 25px;
                  border-radius: 50%;
                  padding-bottom: 20px;
                  color: #75757599 !important;
                  font-weight: 500;
                  font-family: "Poppins-Medium", sans-serif;

                  @media (max-width: 590px) {
                    padding: 9px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    font-size: 14px;
                  }
                }
              }

              abbr {
                height: 58px;
                color: #48484a;
                font-size: 18px;
                font-weight: 400;
                line-height: 40px;
                text-align: center;

                @media (max-width: 590px) {
                  font-size: 14px;
                }
              }

              .react-calendar__tile {
                border-radius: 50%;
                max-width: 86px;
                background: #ffffff;
                pointer-events: none;
              }

              .react-calendar__tile:hover {
                border-radius: 50%;
                max-width: 86px;
                background: #ffffff;
              }

              .react-calendar__tile {
                abbr:hover {
                  color: #48484a;
                  background: transparent;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;

                  @media (max-width: 590px) {
                    padding: 4px 6px;
                    font-size: 14px;
                  }
                }
              }

              .react-calendar__tile--active {
                background: #ffffff;
                border-radius: 50%;
                max-width: 86px;

                // margin-left: 12px;
                abbr {
                  color: #ffffff;
                  background: #1b5add;
                  font-size: 18px;
                  padding: 10px 15px;
                  border-radius: 50%;
                  @media (max-width: 590px) {
                    padding: 5px 7px;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }

      .business-holiday-description-input {
        width: 100%;
        border-radius: 8px;
        font-family: "Poppins-Medium", sans-serif;
        margin: 2% 0% 1% 0%;
      }

      .manage-business-holiday-buttons-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 3%;

        .manage-business-holiday-cancel-button {
          width: 48%;
          height: 50px;
          border-radius: 25px;
          border: 1px solid #2c2c2e;
          text-transform: capitalize;
          color: #2c2c2e;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
        }

        .manage-business-holiday-save-button {
          width: 48%;
          text-transform: capitalize;
          height: 50px;
          border-radius: 25px;
          background: #1e5fea;
          color: #ffffff;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
        }
      }
    }
  }
}

[dir="ltr"] #removeImage,
#back,
#add-edit-back-button,
#detail-back-button,
.business-holiday-image-container img,
.DatePicker_buttonWrapper__3HD0q {
  transform: scaleX(1);
}

[dir="rtl"] #removeImage,
#back,
#add-edit-back-button,
#detail-back-button,
.business-holiday-image-container img,
.DatePicker_buttonWrapper__3HD0q {
  transform: scaleX(-1);
}