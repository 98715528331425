.customer-parent-header-container {
  max-width: 100%;

  .main-header-container {
    padding: 29px 40px;
    max-width: 1440px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      height: auto;

      img {
        width: 15%;
        height: 30%;

        @media (max-width: 753px) {
          width: 45%;
          height: 30%;
        }
      }

      .searchBar_location_navigation {
        display: flex;
        flex-direction: column;
        width: 65%;
        align-items: center;
        justify-content: center;

        @media (max-width: 753px) {
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .navigation-image-main-wrapper {
          max-width: 54px !important;
          max-height: 54px !important;
          height: 100%;
          width: 100%;
          cursor: pointer !important;
          background-color: #ffffff !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          border-radius: 50%;

          .header-navigation-image {
            width: 40% !important;
            height: 40% !important;
          }

          [dir="ltr"] & {
            margin-left: 2.5% !important;
          }

          [dir="rtl"] & {
            margin-right: 2.5% !important;
          }
        }

        .searchBar_with_location {
          width: 75%;
          height: 54px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          @media (max-width: 753px) {
            width: 100%;
          }

          .search_bar_div {
            width: 92%;
            border-radius: 50px;
            height: 100%;
            border: 1.5px solid black;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 753px) {
              height: 44px;
            }

            .search_bar {
              background: transparent;
              width: 100%;
              border: 0;
              height: 100%;
              padding-left: 25px;
              border-radius: 20%;
              outline: none;
              overflow: hidden;
            }

            .arabic-search_bar {
              width: 100%;
              height: 100%;
              background: transparent;
              border: 0;
              outline: none;
              padding-right: 25px;
              overflow: hidden;
              border-radius: 20%;
            }

            button {
              cursor: pointer;
              background: none !important;
              border-radius: 50%;
              border: 0;
            }
          }
        }
      }

      .subheader {
        display: flex;
        justify-content: space-between;
        width: 12% !important;
        height: auto;
        align-items: center;

        @media (max-width: 1310px) {
          width: 13% !important;
        }

        @media (max-width: 1150px) {
          width: 14% !important;
        }

        @media (max-width: 1040px) {
          width: 15% !important;
        }

        @media (max-width: 980px) {
          width: 16% !important;
        }

        @media (max-width: 920px) {
          width: 17% !important;
        }

        @media (max-width: 870px) {
          width: 18% !important;
        }

        @media (max-width: 830px) {
          width: 19% !important;
        }

        @media (max-width: 790px) {
          width: 20% !important;
        }

        @media (max-width: 753px) {
          display: none;
        }

        img {
          float: right;
        }

        .paste_image {
          margin-top: 3%;
          margin-right: 10%;
          width: 32px;
          height: 32px;
          cursor: pointer;
        }

        .groupimage {
          cursor: pointer;
          margin-left: 4%;
          max-width: 54px;
          max-height: 54px;
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        .arabic-groupimage {
          margin-right: 4%;
          cursor: pointer;
          max-width: 54px;
          max-height: 54px;
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .subheaderdup {
        justify-content: space-between;
        width: -moz-max-content;
        width: max-content;
        height: auto;
        align-items: center;
        display: none;

        @media (max-width: 753px) {
          display: flex;
          width: 21% !important;
        }

        @media (max-width: 510px) {
          width: 22% !important;
        }

        @media (max-width: 480px) {
          width: 23% !important;
        }

        @media (max-width: 450px) {
          width: 24% !important;
        }

        @media (max-width: 450px) {
          width: 30% !important;
        }

        @media (max-width: 380px) {
          width: 35% !important;
        }

        @media (max-width: 320px) {
          width: 40% !important;
        }

        img {
          float: right;
        }

        .paste_image {
          margin-top: 3%;
          margin-right: 10%;
          width: 25px;
          height: 25px;
        }

        .groupimage {
          cursor: pointer;
          margin-left: 4%;
          max-width: 54px;
          max-height: 54px;
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        .arabic-groupimage {
          margin-right: 4%;
          cursor: pointer;
          max-width: 54px;
          max-height: 54px;
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .location_with_text {
      width: 41%;
      display: flex;
      flex-direction: row;
      margin: auto;
      margin-top: 12px;
      align-items: center !important;

      @media (max-width: 753px) {
        width: 100%;
      }
    }
  }
}

.search-icon-img {
  width: 54px !important;
  height: 54px !important;

  @media (max-width: 753px) {
    width: 50px !important;
    height: 50px !important;
  }

  @media (max-width: 470px) {
    width: 45px !important;
    height: 44px !important;
  }
}

.content-modal-enable {
  width: 427px;
  height: 450px;
  border-radius: 24px;
  background: #ffffff;
  background-blend-mode: normal;
  text-align: center;
  padding: 32px;
  overflow: auto;
  max-width: 100%;

  // @media (max-width: 1045px) {
  //   width: 50%;
  // }

  @media (max-width: 465px) {
    width: 100%;
  }

  .enable_location_btn {
    cursor: pointer;
    border-radius: 23px;
    background: #1e5fea;
    background-blend-mode: normal;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    height: 46px;
    width: 100%;
    margin: 10;
    text-transform: capitalize;
  }

  h4 {
    margin-top: 20px;
    color: #2c2c2e;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  p {
    color: #2c2c2e;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-top: 10px;
  }

  h5 {
    color: #2c2c2e;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
  }
}

.autosuggest-container {
  width: 100%;
  overflow: visible;
  background: transparent;
}

.autosuggest-container input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
}

[dir="ltr"] .autosuggest-container input {
  padding-left: 2%;
}

[dir="rtl"] .autosuggest-container input {
  padding-right: 2%;
}

.autosuggest-container input:focus,
.autosuggest-container input:active {
  box-shadow: none;
  border: none;
  outline: none;
}

.suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.suggestion-item {
  padding: 4px 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.suggestion-item .search_icon_image {
  height: 25px !important;
  width: 25px !important;
  margin: 0px 5px !important;
  border-radius: 5px !important;
}

.suggestion-item span {
  font-family: "Poppins", sans-serif !important;
  font-size: medium !important;
}

.suggestion-heading {
  padding: 4px 8px;
  display: block;
}

[dir="ltr"] .suggestion-item,
[dir="ltr"] .suggestion-heading {
  text-align: left !important;
}

[dir="rtl"] .suggestion-item,
[dir="rtl"] .suggestion-heading {
  text-align: right !important;
}

.suggestion-item:hover,
.suggestion-highlighted {
  background-color: #f0f0f0;
}

.suggestions-container {
  position: absolute;
  width: 40%;
  z-index: 9999;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto;
  max-width: 600px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: none;
}

.suggestions-container-open {
  display: block;
}

@media (max-width: 1040) and (min-width: 819px) {
  .suggestions-container {
    width: 35%;
  }
}

@media (max-width: 820px) and (min-width: 768px) {
  .suggestions-container {
    width: 33.5%;
  }
}

@media (max-width: 767px) and (min-width: 410px) {
  .suggestions-container {
    width: 65%;
  }
}

@media screen and (max-width: 430px) {
  .landing-page-bottom-page {
    left: 54% !important;
  }
}